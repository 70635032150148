<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessageBox, ElMessage } from "element-plus";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const loadingRefresh = ref(false);
let dataTable = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const changePurposeCodeStatus = (id, status) => {
    loading.value = true;
    http.put(`account/purpose-code/${id}`, { active: status })
        .then(({ data }) => {
            getPurposeList();
            ElMessage({
                message: t("notifications.purpose_code_changed_to_msg", {
                    msg: t(`purpose_code_status.${status ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const refreshCurrencyData = () => {
    loadingRefresh.value = true;
    http.get("account/purpose-code/refresh")
        .then(() => {
            getPurposeList();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const onCLickDelete = (id) => {
    ElMessageBox.confirm(t("notifications.disable_card_view"), t("notifications.attention"), {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.no"),
        type: "warning",
    }).then(() => {
        http.delete(`account/purpose-code/${id}`)
            .then(() => {
                ElMessage({
                    message: t("notifications.deleted_successfully"),
                    type: "success",
                });
                getPurposeList();
            })
            .catch(({ response: { data } }) => {
                ElMessage({
                    message: data.message || t("notifications.error_occurred_while_deleting_data"),
                    type: "error",
                });
            });
    });
};

const getPurposeList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/purpose-code/admin", {
        params: {
            page: page.value,
            size: size.value,
        },
    })
        .then(({ data }) => {
            dataTable.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
            size.value = data.size;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getPurposeList();
    }
);

onMounted(() => {
    getPurposeList();
});
</script>

<template>
    <div class="flex items-center justify-between mb-4">
        <h2 class="text-xl">{{ t("menu.PurposeCode") }}</h2>

        <div>
            <el-tooltip :content="t('buttons.refresh')" placement="top">
                <el-button @click="refreshCurrencyData">
                    <template v-if="loadingRefresh">
                        <el-icon>
                            <IconLoading02 />
                        </el-icon>
                    </template>
                    <template v-else>
                        <el-icon>
                            <IconRefreshCW01 />
                        </el-icon>
                    </template>
                </el-button>
            </el-tooltip>

            <el-tooltip :content="t('buttons.add')" placement="top">
                <el-button @click="$router.push({ name: 'PurposeCodeAdd' })">
                    <el-icon>
                        <IconPlus />
                    </el-icon>
                </el-button>
            </el-tooltip>
        </div>
    </div>

    <div v-loading="loading">
        <el-table
            :data="dataTable"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
            border
        >
            <el-table-column type="index" label="&#8470;" :index="indexMethod" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" align="center">
                <template v-slot="scope">
                    {{ scope.row.name.ru }}
                </template>
            </el-table-column>

            <el-table-column prop="code" :label="t('labels.code')" align="center" />

            <el-table-column prop="id" label="ID" align="center" />

            <el-table-column prop="id" :label="t('labels.status')" align="center">
                <template #default="scope">
                    <el-space>
                        <el-switch
                            v-model="scope.row.active"
                            @change="changePurposeCodeStatus(scope.row.id, scope.row.active)"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        />
                    </el-space>
                </template>
            </el-table-column>

            <el-table-column align="center" :width="220">
                <template v-slot="scope">
                    <el-tooltip :content="t('buttons.edit')" placement="left-start">
                        <el-button
                            type="warning"
                            plain
                            @click="
                                router.push({
                                    name: 'PurposeCodeEdit',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEdit05 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>

                    <el-tooltip
                        :content="t('buttons.delete')"
                        placement="right-start"
                        effect="dark"
                    >
                        <el-button type="danger" plain @click="onCLickDelete(scope.row.id)">
                            <el-icon>
                                <IconTrash01 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-2">
            <span v-if="totalElements" class="font-semibold">
                {{ t("labels.total") }}: {{ totalElements }}
            </span>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            >
            </el-pagination>
        </div>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-title {
    border: none !important;
    margin: 0;
    padding-bottom: 0;
}

.main-data {
    background-color: #ffffff;
    padding-top: 20px;
    border-radius: 10px;
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 0 0;
    /*height: 40px;*/
}

.pagination-wrapper > span {
    color: #0a194e;
    font-family: "Montserrat-Bold", sans-serif;
}

.actions {
    display: flex;
    gap: 10px;
}
</style>
