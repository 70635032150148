<script setup>
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import http from "@/http";
import {ElMessage} from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import FileUploader from "@/FileUploader.vue";

const {t} = useI18n();
const route = useRoute();
const router = useRouter();


const loading = ref(false);
const keyList = ref();
const fileInfo = ref();
const form = ref({
  name: null,
  hashIdUzLat: null,  // type is FILE, this field is required
  hashIdUzCyr: null,// type is FILE, this field is required
  hashIdEn: null,// type is FILE, this field is required
  hashIdRu: null, // type is FILE, this field is required
  key: null,
  type: "FILE", // [FILE, TEXT, LINK]
  text: ""   // type is not FILE, this field is required, TEXT or LINK
});

const types = ['FILE', 'TEXT', 'LINK']
const formRef = ref(null);
const rules = ref({
  name: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  hashIdUzLat: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  hashIdUzCyr: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  hashIdEn: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  hashIdRu: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  key: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  type: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
  text: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
});
const getInstructionKeys = () => {
  loading.value = true;
  http.get(`bank/instruction/keys`)
      .then(({data}) => {
        keyList.value = data;
      })
      .finally(() => {
        loading.value = false;
      });
};
const getInstruction = (id) => {
  loading.value = true;
  http.get(`bank/instruction/${id}`)
      .then(({data}) => {
        if (data?.file){
          form.value.hashIdUzLat = data?.file?.en.hashId;
          form.value.hashIdUzCyr = data?.file?.ru.hashId;
          form.value.hashIdEn = data?.file?.uzCyr.hashId;
          form.value.hashIdRu = data?.file?.uzLat.hashId;
          fileInfo.value = data?.file;
        }
        form.value.key = data?.key;
        form.value.name = data?.name;
      })
      .finally(() => {
        loading.value = false;
      });
};

const onSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      loading.value = true;
      let https;

      if (route.params.id) {
        https = http.put(`bank/instruction/${route.params.id}`, form.value);
      } else {
        https = http.post("bank/instruction", form.value);
      }
      https
          .then(() => {
            ElMessage({
              message: t("notifications.saved_successfully"),
              type: "success",
            });
            router.go(-1);
          })
          .finally(() => {
            loading.value = false;
          });
    }
  });
};

onMounted(() => {
  getInstructionKeys();
  if (route.params.id) {
    getInstruction(route.params.id);
  }
});
</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">
        {{
          !route.params.id
              ? t("menu.InstructionCreateFormPage")
              : t("menu.InstructionEditFormPage")
        }}
      </h2>
    </div>

    <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="top"
        v-loading="loading"
    >
      <div class="grid grid-cols-2 gap-4">
        <el-form-item :label="t('labels.instruction_name')" prop="name">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <el-input
              size="large"
              :placeholder="t('labels.instruction_name')"
              v-model="form.name"
              type="text"
          />
        </el-form-item>

        <el-form-item :label="t('placeholders.select.selectKey')" prop="key">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <el-select
              style="width: 100%; margin-right: 10px"
              v-model="form.key"
              size="large"
              :placeholder="t('placeholders.select.selectKey')"
          >
            <el-option
                v-for="(instruction, index) in keyList"
                :key="index"
                :value="instruction"
                :label="instruction"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('labels.instructionType')" prop="type">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <el-select
              style="width: 100%; margin-right: 10px"
              v-model="form.type"
              size="large"
              :placeholder="t('labels.type')"
          >
            <el-option
                v-for="(type, index) in types"
                :key="index"
                :value="type"
                :label="type"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type === 'FILE'" :label="t('placeholders.input.fileUpload') + ' (UzLat)'" prop="hashIdUzLat">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <FileUploader v-model="form.hashIdUzLat" :info="fileInfo?.uzLat"/>
        </el-form-item>
        <el-form-item v-if="form.type === 'FILE'" :label="t('placeholders.input.fileUpload') + ' (UzCyr)'" prop="hashIdUzCyr">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <FileUploader v-model="form.hashIdUzCyr" :info="fileInfo?.uzCyr"/>
        </el-form-item>
        <el-form-item v-if="form.type === 'FILE'" :label="t('placeholders.input.fileUpload') + ' (Ru)'" prop="hashIdRu">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <FileUploader v-model="form.hashIdRu" :info="fileInfo?.ru"/>
        </el-form-item>
        <el-form-item v-if="form.type === 'FILE'" :label="t('placeholders.input.fileUpload') + ' (En)'" prop="hashIdEn">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <FileUploader v-model="form.hashIdEn" :info="fileInfo?.en"/>
        </el-form-item>
        <el-form-item v-else :label="t('labels.content')" prop="text">
          <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
          </template>
          <el-input
              size="large"
              :placeholder="t('labels.content')"
              v-model="form.text"
              type="textarea"
          />
        </el-form-item>
      </div>

      <div></div>

      <div class="flex items-center justify-end">
        <el-space>
          <el-button @click="() => router.go(-1)">
            <el-icon>
              <IconArrowLeft/>
            </el-icon>
            <span> {{ t("buttons.back") }}</span>
          </el-button>
          <el-button type="primary" @click="onSubmit">
            {{ t("buttons.save") }}
          </el-button>
        </el-space>
      </div>
    </el-form>
  </div>
</template>
