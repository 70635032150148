<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const employeeList = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const getOrganizationEmployeeList = () => {
    loading.value = true;
    http.get(`user/organization-employee-admin/${route.params.id}`)
        .then(({ data }) => {
            employeeList.value = data.content;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getUsers();
    }
);

onMounted(() => {
    getOrganizationEmployeeList();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("labels.employees_to_org") }}</h2>

            <el-button @click="() => router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <div v-loading="loading">
            <el-table
                :data="employeeList"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column prop="ownerFullName" :label="t('labels.s_n_m')" align="center">
                    <template #default="scope">
                        {{ scope.row.name }}
                        {{ scope.row.surname }}
                        {{ scope.row.middleName }}
                    </template>
                </el-table-column>

                <el-table-column prop="role" :label="t('labels.role')" align="center">
                    <template v-slot="scope">
                        <el-space>
                            <el-tag round>{{ scope.row.role.name }}</el-tag>
                        </el-space>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="permissionGroup"
                    :label="t('labels.permission_group')"
                    align="center"
                >
                    <template v-slot="scope">
                        <el-space>
                            <el-tag round>{{ scope.row.permissionGroup.name }}</el-tag>
                        </el-space>
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-4">
                <template v-if="totalElements">
                    <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
                </template>

                <el-pagination
                    :current-page="currentPage"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="totalElements"
                    :page-size="size"
                    :page-count="totalPages"
                    :hide-on-single-page="true"
                    :background="true"
                />
            </div>
        </div>
    </div>
</template>
