<script setup>
import http from "@/http";
import { onMounted, reactive, ref } from "vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import { formatTime } from "@/composables/dateFormat";
import { useI18n } from "vue-i18n";
import { checkPermission } from "@/composables";

const { t } = useI18n();
const dialogVisible = ref(false);
const loading = ref(false);
const formRef = ref();
const form = reactive({
    bankPaymentsTime: 0,
    branchPaymentsTime: 0,
});
const selectedTime = reactive({
    bankPaymentsTime: null,
    branchPaymentsTime: null,
});
const rules = reactive([
    {
        required: true,
        message: t("validations.required_field"),
        trigger: "blur",
    },
]);

function handleChangeTime() {
    formRef.value.validate((valid) => {
        if (valid) {
            dialogVisible.value = false;
            loading.value = true;
            http.put("account/bank-open-day", {
                bankPaymentsTime:
                    selectedTime.bankPaymentsTime?.split(":")[0] * 60 * 60 * 1000 +
                    selectedTime.bankPaymentsTime?.split(":")[1] * 60 * 1000,
                branchPaymentsTime:
                    selectedTime.branchPaymentsTime?.split(":")[0] * 60 * 60 * 1000 +
                    selectedTime.branchPaymentsTime?.split(":")[1] * 60 * 1000,
            })
                .then(({ data }) => {
                    form.bankPaymentsTime = data?.interBankPaymentsTime;
                    form.branchPaymentsTime = data?.interBranchPaymentsTime;
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

function getBankOpenDays() {
    http.get("/account/bank-open-day").then(({ data }) => {
        form.bankPaymentsTime = data?.interBankPaymentsTime;
        form.branchPaymentsTime = data?.interBranchPaymentsTime;
    });
}

onMounted(() => {
    getBankOpenDays();
});
</script>

<template>
    <div v-loading="loading">
        <el-card class="bank-open-days rounded-md" shadow="never">
            <div class="flex items-center gap-x-2">
                <div class="flex flex-col justify-center">
                    <span class="leading-3 text-[12px] text-cyan-700">
                        {{ $t("labels.interbank_payments") }}
                    </span>
                    <p class="leading-4 text-[14px] font-bold">
                        {{ $t("labels.termination") }}:
                        <span class="text-cyan-700">
                            {{
                                form.bankPaymentsTime ? formatTime(form.bankPaymentsTime) : "00:00"
                            }}
                        </span>
                    </p>
                </div>
                <div class="block bg-gray-400 w-[1px] h-[24px]"></div>
                <div class="flex flex-col justify-center">
                    <span class="leading-3 text-[12px] text-cyan-700">
                        {{ $t("labels.inter_branch_payments") }}
                    </span>
                    <p class="leading-4 text-[14px] font-bold">
                        {{ $t("labels.termination") }}:
                        <span class="text-cyan-700">
                            {{
                                form.branchPaymentsTime
                                    ? formatTime(form.branchPaymentsTime)
                                    : "00:00"
                            }}
                        </span>
                    </p>
                </div>
                <template
                    v-if="checkPermission('BANK_OPEN_DAYS') || $store.state.user.role.key === 'DEV'"
                >
                  <el-button @click="dialogVisible = true" size="default" type="warning" plain>
                    <template #icon>
                      <IconEdit05 />
                    </template>
                  </el-button>
                </template>

            </div>
        </el-card>
        <el-dialog
            :title="$t('labels.change_bank_closing_time')"
            width="550px"
            v-model="dialogVisible"
        >
            <el-form label-position="top" ref="formRef" :model="selectedTime">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item
                            :label="$t('labels.interbank_payments')"
                            prop="bankPaymentsTime"
                            :rules="rules"
                        >
                            <el-time-select
                                v-model="selectedTime.bankPaymentsTime"
                                style="width: 240px"
                                start="09:00"
                                step="00:01"
                                end="18:30"
                                :placeholder="$t('labels.select')"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            :label="$t('labels.inter_branch_payments')"
                            prop="branchPaymentsTime"
                            :rules="rules"
                        >
                            <el-time-select
                                v-model="selectedTime.branchPaymentsTime"
                                style="width: 240px"
                                start="09:00"
                                step="00:01"
                                end="18:30"
                                :placeholder="$t('labels.select')"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <div class="flex justify-end">
                            <el-space>
                                <el-button @click="dialogVisible = false">
                                    {{ $t("buttons.cancellation") }}
                                </el-button>
                                <el-button @click="handleChangeTime" type="primary">
                                    {{ $t("buttons.save") }}
                                </el-button>
                            </el-space>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<style scoped>
.bank-open-days {
    &:deep(.el-card__body) {
        padding: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>
