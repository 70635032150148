<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
        <path
            fill="currentColor"
            d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32m0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32m448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32m-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32M195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0m-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"
        />
    </svg>
<!--    <svg-->
<!--        width="1em"-->
<!--        height="1em"-->
<!--        viewBox="0 0 24 24"-->
<!--        fill="none"-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--        stroke="currentColor"-->
<!--    >-->
<!--        <path-->
<!--            d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837"-->
<!--            stroke-width="2"-->
<!--            stroke-linecap="round"-->
<!--            stroke-linejoin="round"-->
<!--        />-->
<!--    </svg>-->
</template>

<style scoped>
svg {
    animation: loading-rotate 2s linear infinite;
}

svg path {
    animation: loading-dash 1.5s ease-in-out infinite;
}
</style>
