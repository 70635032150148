<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import { ElMessage } from "element-plus";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const accountList = ref([]);
const loading = ref(false);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const changeStatus = (id, status) => {
    loading.value = true;
    http.put(`account/account-name/change-status/${id}`)
        .then(() => {
            getAccountList();
            ElMessage({
                message: t("changedStatus.accountName", {
                    msg: t(`statuses.${status ? "active" : "inactive"}`),
                }),
                type: status ? "success" : "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const getAccountList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/account-name", {
        params: {
            size: size.value,
            page: page.value,
        },
    })
        .then(({ data }) => {
            accountList.value = data.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getAccountList();
    }
);

onMounted(() => {
    getAccountList();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.account_numbers") }}</h2>

            <el-tooltip :content="t('buttons.add')" placement="top-start">
                <el-button type="primary" @click="router.push({ name: 'AccountCreate' })">
                    <el-icon>
                        <IconPlus />
                    </el-icon>
                </el-button>
            </el-tooltip>
        </div>

        <el-table
            :data="accountList"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
            v-loading="loading"
            border
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" align="center" width="220" />

            <el-table-column
                prop="balanceCode"
                :label="t('labels.balance_account')"
                align="center"
                width="124"
            />

            <el-table-column
                prop="currencyCode"
                :label="t('labels.currency_code')"
                align="center"
            />

            <el-table-column
                prop="currencyType"
                :label="t('labels.currency_type')"
                align="center"
            />

            <el-table-column
                prop="serialNumberType"
                :label="t('labels.account_number_type')"
                align="center"
            />

            <el-table-column
                prop="serialNumberCode"
                :label="t('labels.account_serial_number')"
                align="center"
            />

            <el-table-column :label="t('table.status')" align="center">
                <template #default="scope">
                    <el-space>
                        <el-switch
                            v-model="scope.row.active"
                            @change="changeStatus(scope.row.id, scope.row.active)"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        />
                    </el-space>
                </template>
            </el-table-column>

            <el-table-column prop="actions" :label="t('labels.actions')" align="center">
                <template #default="scope">
                    <el-tooltip :content="t('buttons.edit')" placement="top">
                        <el-button
                            type="warning"
                            plain
                            @click="
                                router.push({
                                    name: 'AccountNameForm',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEdit05 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>
