<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ $t("titles.add_user_information") }}</h2>

            <el-button @click="() => this.$router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ $t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="24">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.u_name')" prop="name">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            :placeholder="$t('labels.enter_u_name')"
                            size="large"
                            class="form-input"
                            v-model="form.name"
                            type="text"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.surname')" prop="surname">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            :placeholder="$t('labels.enter_surname')"
                            size="large"
                            class="form-input"
                            v-model="form.surname"
                            type="text"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.middle_name')" prop="middleName">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            :placeholder="$t('labels.enter_middle_name')"
                            size="large"
                            class="form-input"
                            v-model="form.middleName"
                            type="text"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.username')" prop="username">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            :placeholder="$t('labels.enter_username')"
                            size="large"
                            class="form-input"
                            v-model="form.username"
                            type="text"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.email')" prop="email">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            :placeholder="$t('labels.enter_email')"
                            size="large"
                            class="form-input"
                            v-model="form.email"
                            type="text"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.pinfil')" prop="pinfl">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            :placeholder="$t('labels.enter_pinfil')"
                            size="large"
                            maxlength="14"
                            class="form-input form_input_pinfl"
                            v-model="form.pinfl"
                            type="text"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.phone_number')" prop="phoneNumber">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            placeholder="98 123-45-67"
                            size="large"
                            class="form-input"
                            v-model="form.phoneNumber"
                            type="tel"
                            :maxlength="12"
                            @input="changePhoneNumber"
                        >
                            <template #prepend>+998</template>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="!userId">
                    <el-form-item :label="$t('labels.password')" prop="password">
                        <template #error="{ error }">
                            <span class="el-form-item__error"> {{ $t(error) }} </span>
                        </template>
                        <el-input
                            :minlength="8"
                            :placeholder="$t('labels.enter_password')"
                            size="large"
                            class="form-input"
                            v-model="form.password"
                        />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item :label="$t('labels.rol')" prop="roleId">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-select
                            @change="handleChangeRoleId"
                            style="width: 100%"
                            class="form-select"
                            v-model="form.roleId"
                            :placeholder="$t('labels.select_rol')"
                            value-key="id"
                        >
                            <el-option
                                v-for="item in roles"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.permissions_group')" prop="permissionGroupId">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-select
                            @change="handleChangeGroupId"
                            style="width: 100%"
                            class="form-select"
                            v-model="form.permissionGroupId"
                            :disabled="!form.roleId"
                            :placeholder="$t('labels.select_permissions_group')"
                            value-key="id"
                        >
                            <el-option
                                v-for="item in permissionGroupList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.permission')" prop="permissionIds">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-select
                            style="width: 100%"
                            class="form-select"
                            v-model="form.permissionIds"
                            :multiple="true"
                            :allow-create="true"
                            :collapse-tags="true"
                            :disabled="!form.permissionGroupId"
                            :placeholder="$t('labels.select_permission')"
                            popper-class="custom-select"
                            value-key="id"
                            @visible-change="handleVisibleChange"
                        >
                            <el-option
                                v-loading.body.lock="loadingPermissions"
                                v-for="item in data"
                                :key="item.id"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <div class="flex justify-end">
                <el-button type="primary" @click="onSubmit">
                    {{ $t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

export default {
    name: "UserForm",
    components: {
        IconArrowLeft,
    },
    data() {
        return {
            loading: false,
            userId: null,
            phoneNumber: null,
            totalCountAll: null,
            loadingPermissions: false,
            page: 0,
            data: [],

            selectedPermissions: [],
            form: {
                id: null,
                username: null,
                name: null,
                surname: null,
                middleName: null,
                roleId: null,
                password: null,
                permissionIds: [],
                permissionGroupId: null,
                email: null,
                phoneNumber: null,
                pinfl: null,
            },
            roles: [],
            permissionList: [],
            permissionGroupList: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                    {
                        max: 128,
                        message: "validations.the_maximum_number_of_characters_is_128",
                        trigger: "blur",
                    },
                ],
                surname: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },

                    {
                        max: 128,
                        message: "validations.the_maximum_number_of_characters_is_128",
                        trigger: "blur",
                    },
                ],
                middleName: [
                    {
                        max: 128,
                        message: "validations.the_maximum_number_of_characters_is_128",
                        trigger: "blur",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                    {
                        max: 32,
                        message: "validations.the_maximum_number_of_characters_is_32",
                        trigger: "blur",
                    },
                ],
                roleId: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "change",
                    },
                ],
                password: [{ validator: this.customValidate, trigger: "blur" }],
                email: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                    {
                        type: "email",
                        message: "validations.invalid_email",
                        trigger: "blur",
                    },
                ],
                permissionGroupId: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                ],
                permissionIds: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        changePhoneNumber() {
            const v = this.form.phoneNumber.replace(/[^0-9]/g, "");
            const l = v.length;
            let lastValue = "";
            if (l < 3) {
                lastValue = v.replace(/^([0-9]{2})/, "$1");
            } else if (l < 6) {
                const reg = new RegExp(`^([0-9]{2})([0-9]{${l - 2}})`);
                lastValue = v.replace(reg, "$1 $2");
            } else if (l < 8) {
                const reg = new RegExp(`^([0-9]{2})([0-9]{3})([0-9]{${l - 5}})`);
                lastValue = v.replace(reg, "$1 $2-$3");
            } else {
                const reg = new RegExp(`^([0-9]{2})([0-9]{3})([0-9]{2})([0-9]{${l - 7}})`);
                lastValue = v.replace(reg, "$1 $2-$3-$4");
            }

            return (this.form.phoneNumber = lastValue);
        },
        customValidate(rule, value, callback) {
            const rules = [
                this.isLengthValid(value),
                this.hasLowerCase(value),
                this.hasUpperCase(value),
                this.hasExtraCharacter(value),
            ];

            const errorMessages = {
                isLengthValid: "validations.password_must_be_at_least_8_characters_long",
                hasLowerCase: "validations.passwords_must_contain_at_least_one_lower_case_letter",
                hasUpperCase: "validations.passwords_must_contain_at_least_one_upper_case_letter",
                hasExtraCharacter:
                    "validations.the_password_must_contain_at_least_one_additional_character",
            };

            const invalidRules = rules.filter((rule, index) => {
                if (!rule) {
                    callback(new Error(errorMessages[Object.keys(errorMessages)[index]]));
                    return true;
                }
                return false;
            });

            if (invalidRules.length === 0) {
                callback();
            }
        },
        isLengthValid(password) {
            return password && password.length >= 8;
        },
        hasLowerCase(password) {
            return /(?=.*[a-z])/.test(password);
        },
        hasUpperCase(password) {
            return /(?=.*[A-Z])/.test(password);
        },
        hasExtraCharacter(password) {
            return /(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/.test(password);
        },
        handleChangeRoleId(id) {
            this.form.permissionGroupId = null;
            this.form.permissionIds = [];
            this.getPermissionGroups(id);
        },
        handleChangeGroupId(id) {
            this.form.permissionIds = [];
            this.getPermissions(id);
        },
        getRoles() {
            this.loading = true;
            this.$http
                .get("user/role/admin", {
                    params: {
                        size: 10,
                    },
                })
                .then(({ data }) => {
                    this.roles = data.content;

                    this.$nextTick(() => {
                        if (this.$route.params.id) {
                            this.userId = this.$route.params.id;
                            this.getUser();
                        }
                    });
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Ma'lumot yuklashda xatolik",
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPermissions(id) {
            this.$http
                .get(`user/permission/admin?permissionGroupId=${id}`, {
                    params: {
                        page: this.page,
                        size: 10,
                    },
                })
                .then(({ data }) => {
                    this.totalCountAll = data?.totalElements;
                    this.permissionList = [...this.permissionList, ...data?.content];
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Ma'lumot yuklashda xatolik",
                        type: "error",
                    });
                });
        },
        getPermissionGroups(id) {
            this.$http
                .get(`user/permission-group/admin?roleId=${id}`)
                .then(({ data }) => {
                    this.permissionGroupList = data.content;
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Ma'lumot yuklashda xatolik",
                        type: "error",
                    });
                });
        },
        getUser() {
            this.loading = true;
            this.$http
                .get(`user/admin/${this.userId}`)
                .then(({ data }) => {
                    this.form.id = data.id;
                    this.form.name = data.name;
                    this.form.surname = data.surname;
                    this.form.middleName = data.middleName;
                    this.form.username = data.username;
                    this.form.email = data.email;
                    this.form.pinfl = data.pinfl;
                    this.form.phoneNumber = data.phoneNumber?.replace("998", "");
                    this.form.roleId = data.role.id;
                    this.form.permissionGroupId = data.permissionGroup.id;
                    this.form.permissionIds = data.permissions.map((item) => item.id);
                    this.form.organizationId = data.organizationId;
                    this.totalCountAll = data.totalElements;
                    this.selectedPermissions = data.permissions;
                    this.permissionList = data.permissions;
                    this.getPermissionGroups(data.role.id);
                    this.getPermissions(data.permissionGroup.id);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || this.$t("notifications.error_loading_data"),
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;

                    let http;
                    if (this.userId) {
                        const phoneNumber = `+998${this.form.phoneNumber
                            .replaceAll("-", "")
                            .replaceAll(" ", "")}`;
                        http = this.$http.put(`user/admin/${this.userId}`, {
                            email: this.form.email,
                            middleName: this.form.middleName,
                            name: this.form.name,
                            permissionGroupId: this.form.permissionGroupId,
                            permissionIds: this.form.permissionIds,
                            phoneNumber: phoneNumber,
                            pinfl: this.form.pinfl,
                            roleId: this.form.roleId,
                            surname: this.form.surname,
                            username: this.form.username,
                        });
                    }
                    else {
                        delete this.form["id"];
                        const phoneNumber = `+998${this.form.phoneNumber
                            .replaceAll("-", "")
                            .replaceAll(" ", "")}`;
                        http = this.$http.post("user/admin/create", {
                            email: this.form.email,
                            middleName: this.form.middleName,
                            name: this.form.name,
                            password: this.form.password,
                            permissionGroupId: this.form.permissionGroupId,
                            permissionIds: this.form.permissionIds,
                            phoneNumber: phoneNumber,
                            pinfl: this.form.pinfl,
                            roleId: this.form.roleId,
                            surname: this.form.surname,
                            username: this.form.username,
                        });
                    }
                    http.then(() => {
                        ElMessage({
                            message: this.$t("notifications.saved_successfully"),
                            type: "success",
                        });
                        this.$router.go(-1);
                    })
                        .catch(({ response: { data } }) => {
                            ElMessage({
                                message: data.message || "Ma'lumot yuklashda xatolik",
                                type: "error",
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
        handleVisibleChange() {
            window.document
                .querySelectorAll(".el-select-dropdown .el-select-dropdown__wrap")[3]
                .addEventListener("scroll", (e) => {
                    const clientH = e.target.clientHeight;
                    const scrollTop = e.target.scrollTop;
                    const scrollH = e.target.scrollHeight;

                    if (
                        Math.ceil(clientH + scrollTop) >= scrollH &&
                        this.totalCountAll >= this.page * 5 &&
                        !this.loadingPermissions
                    ) {
                        this.page = this.page + 1;
                        window.scrollTo({
                            top: 50,
                        });
                        this.getPermissions(this.form.permissionGroupId);
                    }
                });
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.userId = this.$route.params.id;
            this.getUser();
        }
        this.getRoles();
    },
    watch: {
        "form.pinfl"() {
            this.form.pinfl = this.form.pinfl.replace(/[^0-9]/g, "");
        },
        "form.phoneNumber"() {
            this.changePhoneNumber();
        },
        permissionList() {
            let newArr = [];
            if (this.permissionList) {
                newArr = this.permissionList.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });

                if (this.selectedPermissions) {
                    const newObjArr = this.selectedPermissions.map((item) => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    });
                    newArr = [...newObjArr, ...newArr];
                }

                this.data = [...new Map(newArr.map((item) => [item["value"], item])).values()];
            }
        },
    },
};
</script>

<style scoped>
.el-select:deep(.el-input__wrapper) {
    min-height: 40px;
}
.form_input_pinfl:deep(.el-input__inner::-webkit-inner-spin-button),
.form_input_pinfl:deep(.el-input__inner::-webkit-outer-spin-button) {
    -webkit-appearance: none;
    margin: 0;
}
.form_input_pinfl:deep(.el-input__inner) {
    -moz-appearance: textfield;
}

.custom-btn {
    height: 40px;
    border-radius: 5px;
    padding: 0 20px;
}
</style>
