<script setup>
import { ArrowDown, Loading } from '@element-plus/icons-vue'
import { computed, ref, watch } from 'vue'
const props = defineProps([
  'disable',
  'remote',
  'i',
  'filterable',
  'reserveKeyword',
  'multiple',
  'collapseTags',
  'loading',
  'suffixLoading',
  'options',
  'placeholder',
  'totalCountAll',
  'getData',
  'pageableLoading'
])
const emit = defineEmits([
  'update:modelValue',
  'remoteMethod',
  'change',
  'getData',
  'page'
])

const element = ref(null)
const modelValue = ref(null)
const page = ref(0)
const clas = '.el-select-dropdown .el-select-dropdown__wrap'

const selectOptions = computed(() => [
  ...new Map(props.options.map((item) => [item['id'], item])).values()
])
watch(selectOptions, () => {
  if (selectOptions.value.length === 0) {
    page.value = 0
    emit('page', page.value)
  }
})
const updateSelect = () => {
  emit('update:modelValue', modelValue.value)
  emit('change', modelValue.value)
}

const handleVisibleChange = () => {
  document.querySelectorAll(clas)[props.i].addEventListener('scroll', (e) => {
    const clientH = e.target.clientHeight
    const scrollTop = e.target.scrollTop
    const scrollH = e.target.scrollHeight
    if (
      Math.ceil(clientH + scrollTop) >= scrollH &&
      props.totalCountAll >= page.value * 5 &&
      !props.loading
    ) {
      page.value = page.value + 1
      window.scrollTo({
        top: 50
      })
      emit('page', page.value)
      emit('getData')
    }
  })
}
</script>

<template>
  <el-select
    v-model="modelValue"
    @change="updateSelect"
    ref="element"
    class="m-2"
    :suffix-icon="suffixLoading ? Loading : ArrowDown"
    :loading="loading"
    :remote="remote"
    :filterable="filterable"
    :reserve-keyword="reserveKeyword"
    :remote-method="
      (query) => {
        page = 0
        emit('remoteMethod', query)
      }
    "
    :placeholder="placeholder"
    :disabled="disable"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    popper-class="custom-select"
    @visible-change="handleVisibleChange"
    size="large"
    style="width: 100%"
  >
    <el-option
      v-for="item in selectOptions"
      :key="item.id"
      :label="`${item?.name ? (item.name?.uzLat ? item.name.uzLat : item.name) : ''} ${
        item?.surname ? item?.surname : ''
      } ${item?.middleName ? item?.middleName : ''}`"
      :value="item.id"
    />
    <el-option v-if="pageableLoading" disabled value="loading" key="loading" label="loading">
     Loading
    </el-option>
    <template #loading>
      <svg class="circular" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" />
      </svg>
    </template>
  </el-select>
</template>

<style scoped></style>
