<template>
    <div class="page-header">
        <h3>{{ $t("titles.frequently_asked_questions") }}</h3>
        <div class="actions">
            <el-select
                style="margin-right: 10px"
                v-model="selectedCategory"
                value-key="id"
                @change="onchangeCategory"
            >
                <el-option
                    v-for="category in categoryList"
                    :key="category.id"
                    :value="category.id"
                    :label="category.name"
                />
            </el-select>
            <linked-button class="add-button ml-2" type="primary" route="FaqAdd"
                >+ {{ $t("buttons.add") }}</linked-button
            >
        </div>
    </div>
    <div class="main-data" v-loading="loading">
        <el-table
            class-name="table-custom-cls"
            :data="faqList"
            stripe
            style="width: 100%"
            height="calc(100vh - 200px)"
            table-layout="auto"
            border
            :empty-text="$t('notifications.information_not_available')"
        >
            <el-table-column style="padding: 10px" type="expand" label="#">
                <template #default="scope">
                    <div style="padding: 10px" class="answer">
                        <div v-html="scope.row.answer"></div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column type="index" label="#" />
            <el-table-column prop="question" :label="$t('labels.question')" />
            <el-table-column style="padding: 10px" prop="answer" :label="$t('labels.answer')">
                <template v-slot="scope">
                    <div v-html="scope.row.answer"></div>
                </template>
            </el-table-column>
            <el-table-column label="" width="100">
                <template v-slot="scope">
                    <el-space>
                        <el-button
                            size="small"
                            type="danger"
                            @click="deleteQuestion(scope.row.id)"
                            >{{ $t("buttons.delete") }}</el-button
                        >
                        <linked-button
                            route="FaqEdit"
                            size="small"
                            :params="{ id: scope.row.id }"
                            >{{ $t("buttons.edit") }}</linked-button
                        >
                    </el-space>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup>
import LinkedButton from "@/components/buttons/LinkedButton";
import http from "@/http";
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const loading = ref(false);
const categoryList = ref([]);
const faqList = ref([]);
const selectedCategory = ref();

const onchangeCategory = () => {
    router.replace({ params: { categoryId: selectedCategory.value } });
    getFaqList();
};

const getFaqList = () => {
    loading.value = true;
    http.get("bank/question", {
        params: {
            categoryId: selectedCategory.value,
        },
    })
        .then(({ data }) => {
            faqList.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const getCategories = () => {
    http.get("bank/question-category")
        .then(({ data }) => {
            categoryList.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        });
};

const deleteQuestion = (id) => {
    ElMessageBox.confirm(
        t("notifications.will_you_delete_the_question"),
        t("notifications.attention"),
        {
            confirmButtonText: t("buttons.yes"),
            cancelButtonText: t("buttons.no"),
            type: "warning",
        }
    ).then(() => {
        loading.value = true;
        http.delete(`bank/question/${id}`)
            .then(() => {
                ElMessage({
                    message: t("notifications.deleted_successfully"),
                    type: "success",
                });
                getFaqList();
            })
            .catch(({ response: { data } }) => {
                ElMessage({
                    message: data.message || "Ma'lumot o'chirishda xatolik",
                    type: "error",
                });
            })
            .finally(() => {
                loading.value = false;
            });
    });
};

onMounted(() => {
    getCategories();
    if (route.params.categoryId) {
        selectedCategory.value = parseInt(route.params.categoryId);
        getFaqList();
    }
});
</script>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-data {
    background-color: #ffffff;
    padding-top: 20px;
    border-radius: 10px;
}

.answer {
    padding: 10px;
}
</style>
