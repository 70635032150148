<script setup>
import {ref, computed, watch, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import {ElMessage, ElMessageBox} from "element-plus";
import {CircleCheck} from "@element-plus/icons-vue";

const router = useRouter();
const route = useRoute();
const {t} = useI18n();

const loading = ref(false);
const loadingRefresh = ref(false);
const tableData = ref([]);

const clientId = ref(null);
const userStatusList = ref([]);
const options = ref(null);
const selectedValue = ref(route.query.branchId ? Number(route.query.branchId) : null);

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
  if (totalPages.value > 1 && page.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

const handleCurrentChange = (value) => {
  router.push({
    query: {
      page: value,
      size: size.value,
      clientId: clientId.value,
    },
  });
};

const handleSelect = (value) => {
  router.replace({
    query: {
      page: currentPage.value,
      size: size.value,
      branchId: value.toString(),
    },
  });
  getAll();
};

const refreshData = () => {
  loadingRefresh.value = true;
  http.get("account/accounts-refresh", {
    params: {
      clientId: clientId.value,
      page: page.value,
      size: size.value,
    },
  })
      .then(() => {
        getAll();
        ElMessage({
          message: t("notifications.successfully_updated"),
          type: "success",
        });
      })
      .finally(() => {
        loadingRefresh.value = false;
      });
};

const getAll = () => {
  loading.value = true;
  page.value = route.query.page ? route.query.page - 1 : 0;
  http.get("account/admin", {
    params: {
      clientId: clientId.value,
      page: page.value,
      size: size.value,
      branchId: selectedValue.value,
    },
  })
      .then(({data}) => {
        tableData.value = data?.content;
        totalElements.value = data?.totalElements;
        totalPages.value = data?.totalPages;
      })
      .finally(() => {
        loading.value = false;
      });
};
const getAllClientStates = () => {

  http.get("account/states")
      .then(({data}) => {
        userStatusList.value = data
      })
  // .finally(() => {
  //     loading.value = false;
  // });
};

watch(
    () => currentPage.value,
    () => {
      getAll();
    }
);

const changeUserStatus = (id, status) => {

  ElMessageBox.confirm(
      t("notifications.do_you_want_to_change_user_status"),
      t("notifications.caution"),
      {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.no"),
        type: "warning",
      }
  ).then(() => {
    loading.value = true;
    http.put(`account/change-state/${id}?state=${status.state}`)
        .then(() => {
          ElMessage({
            message: t("notifications.user_changed_to", {
              msg: status.stateName,
            }),
            type: "success",
          });
          getAll();
        })
        .catch(({response: {data}}) => {
          ElMessage({
            title: t("notifications.caution"),
            message: data.message || "Ma'lumot o'zgartirishda xatolik",
            type: "error",
          });
        })
        .finally(() => {
          loading.value = false;
        });
  });
};

onMounted(() => {
  if (route.params.clientId) {
    clientId.value = Number(route.params.clientId);
    getAll();

    http.get("account/client-branch", {
      params: {
        clientId: route.params.clientId,
      },
    }).then(({data}) => {
      options.value = data?.content;
    });
  }
  getAllClientStates()
});
</script>

<template>
  <div v-loading="loading">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("titles.clientAccounts") }}</h2>
      <div class="flex flex-auto items-center justify-end gap-x-2">
        <el-select
            style="width: 420px"
            :clearable="true"
            @change="handleSelect"
            v-model="selectedValue"
            :placeholder="t('placeholders.select.branch')"
        >
          <el-option
              v-for="option in options"
              :label="option.name"
              :value="option.branchId"
              :key="`clientAccounts-${option.branchId}`"
          />
        </el-select>

        <el-tooltip :content="t('buttons.refresh')" placement="top">
          <el-button @click="refreshData">
            <template v-if="loadingRefresh">
              <el-icon>
                <IconLoading02/>
              </el-icon>
            </template>
            <template v-else>
              <el-icon>
                <IconRefreshCW01/>
              </el-icon>
            </template>
          </el-button>
        </el-tooltip>

        <el-button
            class="!ml-0"
            @click="
                        router.push({
                            name: 'ClientsView',
                        })
                    "
        >
          <el-icon>
            <IconArrowLeft/>
          </el-icon>
          <span>{{ t("buttons.back") }}</span>
        </el-button>
      </div>
    </div>

    <el-table
        border
        size="small"
        :data="tableData"
        table-layout="fixed"
        :header-cell-style="{ color: '#111', textAlign: 'center' }"
        :empty-text="t('notifications.information_not_available')"
    >
      <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center"/>

      <el-table-column prop="name" :label="t('table.name')" align="center" width="140"/>

      <el-table-column prop="orgName" :label="t('table.organizationName')" align="center"/>

      <el-table-column prop="balance" :label="t('table.balance')" align="center"/>

      <el-table-column :label="t('table.account')" align="center">
        <template #default="scope">
          <el-popover
              :width="240"
              trigger="hover"
              placement="top"
              :content="scope.row.account"
          >
            <template #reference>
              <div class="truncate">
                {{ scope.row.account }}
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column :label="t('table.mfoAndBranchName')" align="center" width="200">
        <template #default="scope">
          <el-popover
              :width="240"
              trigger="hover"
              placement="top"
              :content="scope.row.branch.mfo + ', ' + scope.row.branch.name"
          >
            <template #reference>
              <div class="truncate">
                {{ scope.row.branch.mfo }}, {{ scope.row.branch.name }}
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column :label="t('table.codeNumber')" align="center">
        <template #default="scope">
          <div class="truncate">
            {{ scope.row.currencyCode.codeNumber }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="currencyCode.codeAlfa"
          :label="t('table.codeAlpha')"
          align="center"
      />
      <el-table-column prop="state" :label="t('labels.status')" align="center">
        <template #default="scope">
          <el-dropdown trigger="click">
            <el-tag
                :type="
                                    scope.row.state.state === 'OPEN'
                                        ? 'success'
                                        : scope.row.state.state === 'CLOSE'
                                          ? 'warning'
                                          : scope.row.state.state === 'INACTIVE'
                                            ? 'danger'
                                            : ''
                                "
                class="cursor-pointer select-none transition-colors hover:bg-[#d9ecff]"
                round
            >
              {{ scope.row.state.stateName }}
            </el-tag>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                    v-for="item in userStatusList"
                    :key="item"
                    :class="
                                            scope.row.state.state === item.state
                                                ? 'text-blue-500 font-medium'
                                                : ''
                                        "
                    @click="changeUserStatus(scope.row.id, item)"
                >
                  <el-icon style="width: 14px">
                    <CircleCheck v-if="scope.row.state.state === item.state"/>
                  </el-icon>
                  {{ item.stateName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <!--              <div class="truncate">-->
          <!--                {{ scope.row.state.stateName }}-->
          <!--              </div>-->
        </template>
      </el-table-column>

      <!--            <el-table-column-->
      <!--                prop="currencyCode.name"-->
      <!--                :label="t('table.currencyName')"-->
      <!--                align="center"-->
      <!--                width="160"-->
      <!--            />-->
    </el-table>

    <div class="flex items-center justify-between mt-4">
      <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>

      <el-pagination
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="totalElements"
          :page-size="size"
          :page-count="totalPages"
          :background="true"
      />
    </div>
  </div>
</template>
