<script setup>
import {useRoute, useRouter} from "vue-router";
import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import {useI18n} from "vue-i18n";
import http from "@/http";
import {computed, onMounted, ref} from "vue";
import {ElMessage, ElMessageBox} from "element-plus";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const loading = ref(false);
const list = ref([]);
const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(8);
const page = ref(0);
const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const getList = () => {
  loading.value = true;
  page.value = route.query.page ? route.query.page - 1 : 0;
  http.get("bank/credit/admin", {
    params: {
      page: page.value,
      size: size.value,
    },
  })
      .then(({ data }) => {
        list.value = data?.content;
        totalElements.value = data?.totalElements;
        totalPages.value = data?.totalPages;
      })
      .finally(() => {
        loading.value = false;
      });
};

const handleCurrentChange = (value) => {
  router.push({
    query: { page: value },
  });
};

const deletePermission = (id) => {
  ElMessageBox.confirm(t("notifications.delete_data"), t("notifications.attention"), {
    confirmButtonText: t("buttons.yes"),
    cancelButtonText: t("buttons.cancellation"),
    type: "warning",
  }).then(() => {
    loading.value = true;
    http.delete(`bank/credit/admin/${id}`)
        .then(() => {
          ElMessage({
            message: t("notifications.deleted_successfully"),
            type: "success",
          });
         getList()
        })
        .finally(() => {
          loading.value = false;
        });
  });
}

const indexMethod = (index) => {
  if (totalPages.value > 1 && page.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

onMounted(()=>{
  getList()
})


</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("menu.Credit") }}</h2>

      <el-tooltip :content="t('buttons.add')" placement="top">
        <el-button type="primary" @click="router.push({ name: 'CreditAdd' })">
          <el-icon>
            <IconPlus />
          </el-icon>
        </el-button>
      </el-tooltip>
    </div>
    <div v-loading="loading">
      <el-table
          :data="list"
          :header-cell-style="{ color: '#111', textAlign: 'center' }"
          :empty-text="t('notifications.information_not_available')"
          border
      >
        <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

        <el-table-column prop="name" :label="t('labels.name')" align="center" >
          <template v-slot="name">
            {{name.row.name}}
          </template>
        </el-table-column>

        <el-table-column
            prop="initialAmount"
            :label="t('labels.initalAmount')"
            align="center"
        >
          <template v-slot="initialAmount">
            {{initialAmount.row.initialAmount?.toLocaleString("ru")}}
          </template>
        </el-table-column>

        <el-table-column
            prop="interestRate"
            :label="t('labels.interestRate')"
            align="center"
        >
          <template v-slot="interestRate">
            {{interestRate.row.interestRate}}
          </template>
        </el-table-column>
        <el-table-column
            prop="reviewTerm"
            :label="t('labels.reviewTerm')"
            align="center"
        >
          <template v-slot="reviewTerm">
            {{reviewTerm.row.reviewTerm}}
          </template>
        </el-table-column>



<!--        <el-table-column prop="key" :label="t('labels.key')" align="center" />-->

        <el-table-column :label="t('labels.settings')" align="center">
          <template #default="scope">
            <el-tooltip :content="t('buttons.edit')" placement="left-start">
              <el-button
                  type="warning"
                  plain
                  @click="
                                    router.push({
                                        name: 'CreditEdit',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
              >
                <el-icon>
                  <IconEdit05 />
                </el-icon>
              </el-button>
            </el-tooltip>

            <el-tooltip
                :content="t('buttons.delete')"
                placement="right-start"
                effect="dark"
            >
              <el-button
                  type="danger"
                  plain
                  @click.prevent="deletePermission(scope.row.id)"
              >
                <el-icon>
                  <IconTrash01 />
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex items-center justify-between mt-4">
        <template v-if="totalElements">
          <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
        </template>

        <el-pagination
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="totalElements"
            :page-size="size"
            :page-count="totalPages"
            :hide-on-single-page="true"
            :background="true"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
