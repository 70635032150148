<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 class="page-title">{{ $t(title) }}</h3>
        </div>
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form ref="formRef" :model="form" label-position="top">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="24" :lg="824" :xl="24">
                            <el-tabs v-model="activeName" class="demo-tabs">
                                <el-tab-pane :label="$t('langs.uzbek')" name="uzLat">
                                    <el-form-item
                                        :label="$t('labels.name')"
                                        prop="name.uzLat"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.name.uzLat"
                                            type="text"
                                            :rules="rule"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.address')"
                                        prop="address.uzLat"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.address.uzLat"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.additional_information')"
                                        prop="info.uzLat"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.info.uzLat"
                                            type="text"
                                        />
                                    </el-form-item>
                                </el-tab-pane>

                                <el-tab-pane :label="$t('langs.english')" name="en">
                                    <el-form-item
                                        :label="$t('labels.name')"
                                        prop="name.en"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.name.en"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.address')"
                                        prop="address.en"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.address.en"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.additional_information')"
                                        prop="info.en"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.info.en"
                                            type="text"
                                        />
                                    </el-form-item>
                                </el-tab-pane>

                                <el-tab-pane :label="$t('langs.russian')" name="ru">
                                    <el-form-item
                                        :label="$t('labels.name')"
                                        prop="name.ru"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.name.ru"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.address')"
                                        prop="address.ru"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.address.ru"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.additional_information')"
                                        prop="info.ru"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.info.ru"
                                            type="text"
                                        />
                                    </el-form-item>
                                </el-tab-pane>

                                <el-tab-pane :label="$t('langs.cyrill')" name="uzCyr">
                                    <el-form-item
                                        :label="$t('labels.name')"
                                        prop="name.uzCyr"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.name.uzCyr"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.address')"
                                        prop="address.uzCyr"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.address.uzCyr"
                                            type="text"
                                        />
                                    </el-form-item>
                                    <el-form-item
                                        :label="$t('labels.additional_information')"
                                        prop="info.uzCyr"
                                        :rules="rule"
                                    >
                                        <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                                        </template>
                                        <el-input
                                            size="large"
                                            class="form-input"
                                            v-model="form.info.uzCyr"
                                            type="text"
                                        />
                                    </el-form-item>
                                </el-tab-pane>
                            </el-tabs>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="24">
                            <el-form-item
                                :label="$t('labels.phone_number')"
                                prop="phoneNumber"
                                :rules="rule"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.phoneNumber"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="24">
                            <el-form-item
                                :label="$t('labels.serial_number')"
                                prop="order"
                                :rules="rule"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.order"
                                    type="number"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="24">
                            <el-form-item
                                :label="$t('labels.branch_type')"
                                prop="type"
                                :rules="rule"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-select
                                    v-model="form.type"
                                    class="m-2 w-100"
                                    :placeholder="$t('labels.branch_type')"
                                    size="large"
                                >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="12">
                            <el-form-item
                                :label="$t('labels.location') + ' (lat)'"
                                prop="lat"
                                :rules="rule"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.lat"
                                    type="text"
                                >
                                    <template #append>
                                        <el-button @click="openMap" link
                                            ><img src="@/assets/images/map.svg" alt="" />
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="12">
                            <el-form-item
                                :label="$t('labels.location') + ' (long)'"
                                prop="long"
                                :rules="rule"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.long"
                                    type="text"
                                >
                                    <template #append>
                                        <el-button @click="openMap" link
                                            ><img src="@/assets/images/map.svg" alt="" />
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button class="custom-btn" @click="() => $router.go(-1)">{{
                                    $t("buttons.back")
                                }}</el-button>
                                <el-button
                                    class="custom-btn"
                                    type="primary"
                                    @click="onSubmit(formRef)"
                                    >{{ $t("buttons.save") }}</el-button
                                >
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
        <el-dialog v-model="mapDialog">
            <YandexMap
                :coordinates="[form.lat, form.long]"
                @onChangePlacemark="changeLatLong"
                @onClose="closeMap"
            />
        </el-dialog>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from "vue";
import http from "@/http";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import YandexMap from "@/views/banks/branches/components/YandexMap";

const { t } = useI18n();

const mapDialog = ref(false);

const formRef = ref();
const loading = ref(false);
const id = ref(null);
const router = useRouter();
const route = useRoute();
const options = ref([]);
const activeName = ref("uzLat");

const form = reactive({
    name: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    address: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    info: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    phoneNumber: null,
    order: 1,
    long: null,
    lat: null,
    type: null,
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            let request;
            if (id.value) {
                request = http.put(`bank/branch/${id.value}`, form);
            } else {
                request = http.post("bank/branch", form);
            }
            request
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: t("notifications.saved_successfully"),
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                });
        }
    });
};

const getBranch = () => {
    http(`/bank/branch/admin/${id.value}`)
        .then(({ data }) => {
            form.phoneNumber = data.phoneNumber;
            form.name = data.name;
            form.address = data.address;
            form.order = data.order;
            form.lat = data.lat;
            form.long = data.long;
            form.type = data.type;
            form.info = data.info;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Branch yuklashda xatolik!",
                type: "error",
            });
        });
};

const title = ref("titles.add_branch");

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        title.value = "titles.edit_branch";
        getBranch();
    }

    http(`/bank/branch/type`)
        .then(({ data }) => {
            options.value = data.map((item) => {
                return {
                    label: item,
                    value: item,
                };
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Branch turini yuklashda xatolik!",
                type: "error",
            });
        });
});

const rule = computed(() => ({
    required: true,
    message: "validations.required_field",
    trigger: "blur",
}));

const changeLatLong = (coords) => {
    form.lat = coords[0];
    form.long = coords[1];
    closeMap();
};

const closeMap = () => {
    mapDialog.value = false;
};
const openMap = () => {
    mapDialog.value = true;
};
</script>

<style scoped>
:deep(.el-dialog__body) {
    padding: 5px;
}

:deep(.el-dialog__header) {
    display: none;
}
</style>
