<template>
  <div class="main-data" v-loading="loading">
    <div class="page-header">
      <h3 class="page-title">{{ $t(title) }}</h3>
    </div>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form ref="formRef" :model="form" label-position="top">
          <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
                  <el-form-item
                      :label="$t('labels.name') + ' (uzLat)'"
                      prop="name.uzLat"
                      :rules="rule"
                  >
                    <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                    </template>
                    <el-input
                        size="large"
                        class="form-input"
                        v-model="form.name.uzLat"
                        type="text"
                        :rules="rule"
                    />
                  </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
                  <el-form-item
                      :label="$t('labels.name') + ' (en)'"
                      prop="name.en"
                      :rules="rule"
                  >
                    <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                    </template>
                    <el-input
                        size="large"
                        class="form-input"
                        v-model="form.name.en"
                        type="text"
                    />
                  </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
                  <el-form-item
                      :label="$t('labels.name') + ' (ru)'"
                      prop="name.ru"
                      :rules="rule"
                  >
                    <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                    </template>
                    <el-input
                        size="large"
                        class="form-input"
                        v-model="form.name.ru"
                        type="text"
                    />
                  </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
                  <el-form-item
                      :label="$t('labels.name') + ' (uzCyr)'"
                      prop="name.uzCyr"
                      :rules="rule"
                  >
                    <template #error="{ error }">
                                            <span class="el-form-item__error">
                                                {{ $t(error) }}
                                            </span>
                    </template>
                    <el-input
                        size="large"
                        class="form-input"
                        v-model="form.name.uzCyr"
                        type="text"
                    />
                  </el-form-item>

              </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
              <el-form-item
                  :label="$t('labels.branch_id')"
                  prop="mfo"
                  :rules="rule"
              >
                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                </template>
                <el-input
                    size="large"
                    class="form-input"
                    v-model="form.mfo"
                    type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
              <el-form-item
                  :label="$t('labels.headOffice')"
                  prop="head"
                  :rules="rule"
              >
                <el-checkbox v-model="form.head">{{$t('labels.headOffice')}}</el-checkbox>
              </el-form-item>
            </el-col>


            <el-col :span="24">
              <el-form-item>
                <el-button class="custom-btn" @click="() => $router.go(-1)">{{
                    $t("buttons.back")
                  }}</el-button>
                <el-button
                    class="custom-btn"
                    type="primary"
                    @click="onSubmit(formRef)"
                >{{ $t("buttons.save") }}</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog v-model="mapDialog">
      <YandexMap
          :coordinates="[form.lat, form.long]"
          @onChangePlacemark="changeLatLong"
          @onClose="closeMap"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from "vue";
import http from "@/http";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import YandexMap from "@/views/banks/branches/components/YandexMap";

const { t } = useI18n();

const mapDialog = ref(false);

const formRef = ref();
const loading = ref(false);
const id = ref(null);
const router = useRouter();
const route = useRoute();
const options = ref([]);
const activeName = ref("uzLat");

const form = reactive({
  name: {
    en: null,
    ru: null,
    uzCyr: null,
    uzLat: null,
  },
  mfo: null,
  head: false
});

const onSubmit = (formRef) => {
  formRef.validate((valid) => {
    if (valid) {
      let request;
      if (id.value) {
        request = http.put(`user/branch/admin/${id.value}`, form);
      } else {
        request = http.post("user/branch/admin", form);
      }
      request
          .then(() => {
            ElMessage({
              type: "success",
              message: t("notifications.saved_successfully"),
            });
            router.go(-1);
          })
          .catch(({ response: { data } }) => {
            ElMessage({
              message: data.message || t("notifications.error_occurred_while_saving"),
              type: "error",
            });
          });
    }
  });
};

const getBranch = () => {
  http(`/user/branch/admin/${id.value}`)
      .then(({ data }) => {
        form.name = data.name;
        form.mfo = data.mfo;
        form.head = data.head;
      })
      .catch(({ response: { data } }) => {
        ElMessage({
          message: data.message || "Branch yuklashda xatolik!",
          type: "error",
        });
      });
};

const title = ref("titles.add_branch");

onMounted(() => {
  if (route.params.id) {
    id.value = route.params.id;
    title.value = "titles.edit_branch";
    getBranch();
  }

  http(`/bank/branch/type`)
      .then(({ data }) => {
        options.value = data.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      })
      .catch(({ response: { data } }) => {
        ElMessage({
          message: data.message || "Branch turini yuklashda xatolik!",
          type: "error",
        });
      });
});

const rule = computed(() => ({
  required: true,
  message: "validations.required_field",
  trigger: "blur",
}));

const changeLatLong = (coords) => {
  form.lat = coords[0];
  form.long = coords[1];
  closeMap();
};

const closeMap = () => {
  mapDialog.value = false;
};
const openMap = () => {
  mapDialog.value = true;
};
</script>

<style scoped>
:deep(.el-dialog__body) {
  padding: 5px;
}

:deep(.el-dialog__header) {
  display: none;
}
</style>
