<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import { ElMessage } from "element-plus";
import { errorMessage } from "@/composables";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const loading = ref(false);
const url = computed(() => `${process.env.VUE_APP_BASE_API_URL}/api/v1`);
const headers = reactive({
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
});
const formStateRef = ref();
const formState = ref({
    fileList: [],
});
const rules = ref({
    fileList: [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
});

const handleUpload = (response) => {
    formState.value.fileList[formState.value.fileList.length - 1].hashId = response.hashId;
};

const handleError = (res) => {
    errorMessage(res);
};

function onSubmit(formRef) {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.post(`account/swift/admin/upload`, {
                hashIds: formState.value.fileList.map((item) => item.hashId),
            })
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

onMounted(() => {});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ t("titles.prtFIleAdd") }}
            </h2>

            <el-button @click="router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form ref="formStateRef" :model="formState" :rules="rules" label-position="top">
            <div class="grid grid-cols-2 gap-4 items-end">
                <el-form-item prop="key" :label="t('placeholders.input.fileUpload')">
                    <el-upload
                        v-model:file-list="formState.fileList"
                        class="upload-demo"
                        :action="`${url}/file/upload`"
                        :headers="headers"
                        multiple
                        :on-success="handleUpload"
                        :on-error="handleError"
                        :limit="3"
                    >
                        <el-button type="primary">{{
                            t("placeholders.input.fileUpload")
                        }}</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                                {{ t("notifications.fileSize") }}
                            </div>
                        </template>
                    </el-upload>
                </el-form-item>
            </div>

            <div class="flex items-center justify-end">
                <el-button type="primary" @click="onSubmit(formStateRef)">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
