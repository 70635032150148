<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 class="page-title">
                {{ route.params.id ? $t("titles.edit_bank") : $t("titles.add_new_bank") }}
            </h3>
        </div>
        <el-row>
            <el-col :xs="24" :sm="20" :lg="12" :xl="14">
                <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.english')})`"
                                prop="name.en"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.en"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.russian')})`"
                                prop="name.ru"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.ru"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.cyrill')})`"
                                prop="name.uzCyr"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.uzCyr"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.uzbek')})`"
                                prop="name.uzLat"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.uzLat"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item :label="$t('labels.branch_id')" prop="mfo">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.mfo"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button class="custom-btn" @click="() => $router.go(-1)">
                                    {{ $t("buttons.back") }}
                                </el-button>
                                <el-button
                                    class="custom-btn"
                                    type="primary"
                                    @click="onSubmit(formRef)"
                                >
                                    {{ $t("buttons.save") }}
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import http from "@/http";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";

const formRef = ref();
const loading = ref(false);
const id = ref(null);
const router = useRouter();
const route = useRoute();

const form = reactive({
    name: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    mfo: null,
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            let request;
            if (id.value) {
                request = http.put(`bank/${id.value}`, form);
            } else {
                request = http.post("/bank", form);
            }
            request
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: "Muvaffaqiyatli saqlandi!",
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Saqlashda xatolik sodir bo'ldi!",
                        type: "error",
                    });
                });
        }
    });
};

const getBank = () => {
    http(`/bank/admin/${id.value}`)
        .then(({ data }) => {
            form.mfo = data.mfo;
            form.name = data.name;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                type: "error",
                message: data.message || "Foydalanuvchi ma'lumotni yuklashda xatolik sodir bo'ldi!",
            });
        });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getBank();
    }
});

const rules = reactive({
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    mfo: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});
</script>

<style scoped></style>
