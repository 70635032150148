import { createWebHistory, createRouter } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import store from "@/store";
import AuthPage from "@/views/AuthPage";
import PageNotFound from "@/components/404/PageNotFound";
import Navigations from "@/router/navigation";
import { messages, selectedLocale } from "@/i18n/i18n";
import { checkPermission, useUserAgent } from "@/composables";
import { ElMessage } from "element-plus";

const mainNavigation = [];
const { result, id } = useUserAgent();
const version = process.env.VUE_APP_LOGIN_VERSION;

store.commit("setUniversal", {
    key: "userAgent",
    payload: {
        device_type: "WEB",
        device_os: result.os.name + " " + result.os.version,
        device_model: result.browser.name + " " + result.browser.version,
        device_id: id,
        version,
    },
});

if (Navigations) {
    Navigations?.forEach((item) => {
        if (item.isSubmenu) {
            item.children?.forEach((subItem) => {
                mainNavigation.push(subItem);
            });
        } else {
            mainNavigation.push(item);
        }
    });
}

const pagesAuthorize = mainNavigation.map((item) => {
    let role;
    item.meta.authorize.forEach((key) => {
        role = key;
    });
    return role;
});

export const allowedMainPaths = () =>
    mainNavigation
        .filter((item) => {
            let list = [];

            item.meta.permission.forEach((item) => {
                list.push(checkPermission(item));
            });
            let permission = list.includes(true) || item.meta.permission?.length === 0;
            let hasCurrentUserRole = pagesAuthorize.includes(store.state.user?.role?.key);
            return (item.main && (permission || !item.meta.permission)) || hasCurrentUserRole;
        })
        .map((item) => item.name);

const allowedPaths = () =>
    mainNavigation
        .filter((item) => {
            let list = [];

            item.meta.permission.forEach((item) => {
                list.push(checkPermission(item));
            });
            let permission = list.includes(true) || item.meta.permission?.length === 0;
            let hasCurrentUserRole = pagesAuthorize.includes(store.state.user?.role?.key);
            return permission || hasCurrentUserRole || !item.meta.permission;
        })
        .map((item) => item.name);

const routes = [
    {
        path: "/",
        redirect: "/app",
    },
    {
        name: "MainContainer",
        path: "/app",
        component: Dashboard,
        children: mainNavigation?.length ? mainNavigation : [],
    },
    {
        name: "Auth",
        path: "/auth",
        component: AuthPage,
    },
    {
        name: "PageNotFound",
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const allPageNames = mainNavigation.map((item) => item.name);

router.beforeEach((to, from, next) => {
    if (to.path.includes("app")) {
        if (localStorage.getItem("accessToken")) {
            if (!allowedPaths().includes(to.name)) {
                if (allPageNames.includes(to.name)) {
                    ElMessage({
                        message:
                            messages[selectedLocale.value]["notifications"][
                                "you_do_not_have_access_to_this_page"
                            ],
                        type: "warning",
                    });
                    return allowedMainPaths().length
                        ? next({ name: allowedMainPaths()[0] })
                        : next({ name: "PageNotFound" });
                }
                return next({ name: allowedMainPaths()[0] });
            } else {
                return next();
            }
        } else {
            next("/auth");
        }
    } else {
        store.commit("updateHeaderTitle", to.name);
        next();
    }
});

export default router;
