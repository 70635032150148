<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import { ElMessage } from "element-plus";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const loading = ref(false);
const activeTab = ref("uzLat");
const formStateRef = ref();
const formState = ref({
    name: {
        uzLat: null,
        uzCyr: null,
        en: null,
        ru: null,
    },
    key: null,
  iso: null,
  alpha2: null,
  alpha3: null,
});
const rules = ref({
    "name.uzLat": [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
    "name.en": [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
  key: [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
  iso: [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
  alpha2: [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
  alpha3: [
        {
            required: true,
            message: t("validations.required_field"),
            trigger: "change",
        },
    ],
});

function getCountryCode() {
    loading.value = true;
    http.get(`account/country-codes/${route.params.id}`)
        .then(({ data }) => {
            formState.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
}

function onSubmit(formRef) {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;
            let request;
            if (route.params.id) {
              request = http.put(`account/country-codes/${route.params.id}`, formState.value)
            }
            else {
              request = http.post(`account/country-codes`, formState.value)
            }
                request.then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

onMounted(() => {
  if (route.params.id){
    getCountryCode();
  }
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ route.params.id ? t("titles.countryCodeEdit") : t("titles.countryCodeAdd") }}
            </h2>

            <el-button @click="router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form ref="formStateRef" :model="formState" :rules="rules" label-position="top">
            <div class="grid grid-cols-2 gap-4 items-end">
                <el-tabs v-model="activeTab">
                    <el-tab-pane :label="t('langs.uzbek')" name="uzLat">
                        <div>
                            <el-form-item prop="name.uzLat" :label="t('labels.name')">
                                <el-input
                                    v-model="formState.name.uzLat"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="t('langs.cyrill')" name="uzCyr">
                        <div>
                            <el-form-item prop="name.uzCyr" :label="t('labels.name')">
                                <el-input
                                    v-model="formState.name.uzCyr"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="t('langs.english')" name="en">
                        <div>
                            <el-form-item prop="name.en" :label="t('labels.name')">
                                <el-input
                                    v-model="formState.name.en"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="t('langs.russian')" name="ru">
                        <div>
                            <el-form-item prop="name.ru" :label="t('labels.name')">
                                <el-input
                                    v-model="formState.name.ru"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>
                </el-tabs>

                <el-form-item prop="key" :label="t('labels.key')">
                    <el-input v-model="formState.key" :placeholder="t('labels.enter_key')" />
                </el-form-item>
                <el-form-item prop="iso" label="ISO">
                    <el-input v-model="formState.iso" placeholder="ISO" />
                </el-form-item>
                <el-form-item prop="alpha2" label="Alfa-2">
                    <el-input v-model="formState.alpha2" placeholder="Alfa-2" />
                </el-form-item>
                <el-form-item prop="alpha3" label="Alfa-3">
                    <el-input v-model="formState.alpha3" placeholder="Alfa-3" />
                </el-form-item>
            </div>

            <div class="flex items-center justify-end">
                <el-button type="primary" @click="onSubmit(formStateRef)">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
