import axios from "axios";
import store from "@/store";
import {errorMessage} from "@/composables";

let isRefreshing = false;
let isRefreshingBSession = false;
let refreshPromise = null;
let refreshBSessionPromise = null;
const failedRequests = [];
let failedRequestsQueue = [];

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`,
    transformRequest: (data, headers) => {
        headers["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;
        headers["Content-Type"] = headers["Content-Type"]
            ? headers["Content-Type"]
            : "application/json";
        headers["hl"] = localStorage.getItem("locale");
        if (headers["Content-Type"].includes("json")) {
            data = JSON.stringify(data);
        }
        if (store.state.bSession) headers['B-Session'] = store.state.bSession?.sessionId
        return data;
    },
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const {
            config,
            response: {status},
        } = error;
        const originalRequest = config;

        if (status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const refreshToken = localStorage.getItem("refreshToken");
                    if (refreshToken) {
                        const response = await axios.post(
                            `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}/auth/oauth/token`,
                            {
                                grant_type: "refresh_token",
                                refresh_token: refreshToken,
                            },
                            {
                                headers: {
                                    Authorization: `Basic ${process.env.VUE_APP_BASIC_AUTH_TOKEN}`,
                                    "Content-Type": "multipart/form-data",
                                },
                            }
                        );

                        const {access_token, refresh_token} = response.data;
                        setTokens(access_token, refresh_token);
                        localStorage.setItem("accessToken", access_token);
                        localStorage.setItem("refreshToken", refresh_token);
                        originalRequest.headers.Authorization = `Bearer ${access_token}`;
                        return instance(originalRequest);
                    }
                } catch (refreshError) {
                    store.commit("signOut");
                    setError(refreshError);
                    return Promise.reject(refreshError);
                } finally {
                    isRefreshing = false;
                }
            } else {
                if (!refreshPromise) {
                    refreshPromise = new Promise((resolve, reject) => {
                        failedRequests.push({resolve, reject});
                    });
                }
                return refreshPromise.then(() => {
                    originalRequest.headers["Authorization"] =
                        "Bearer " + localStorage.getItem("accessToken");
                    return instance(originalRequest);
                });
            }
        } else if (status === 400 && error.response?.data?.code === 11) {
            if (!isRefreshingBSession) {
                try {
                    isRefreshingBSession = true;
                    const data = await store.dispatch("selectBranchSession", store.state.bSession?.mfo)

                    retryFailedRequests("data");
                    originalRequest.headers["B-Session"] = data.sessionId
                    return instance(originalRequest);
                } catch (refreshError) {
                    return Promise.reject(refreshError);
                } finally {
                    isRefreshingBSession = false
                }


            } else {
                if (!refreshBSessionPromise) {
                    refreshBSessionPromise = new Promise((resolve, reject) => {
                        failedRequestsQueue.push({resolve, reject});
                    });
                }
                return refreshBSessionPromise.then(() => {
                    originalRequest.headers["B-Session"] = store.state.bSession?.sessionId
                    return instance(originalRequest);
                });
            }
        } else {
            errorMessage(error);
        }

        return Promise.reject(error);
    }
);

function retryFailedRequests(data) {
    // Retry all failed requests in the queue
    failedRequestsQueue.forEach((request) => {
        request.resolve(data);
    });
    failedRequestsQueue.length = 0;
    refreshBSessionPromise = null;
}

function processQueue(status, data) {
    failedRequests.forEach((request) => {
        if (status === "success") {
            request.resolve(data);
        } else {
            request.reject(data);
        }
    });
    failedRequests.length = 0;
    refreshPromise = null;
}

export const setTokens = (newAccessToken) => {
    processQueue("success", newAccessToken);
};

export const setError = (error) => {
    processQueue("error", error);
};

export default instance;
