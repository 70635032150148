<template>
    <div id="map" style="height: 512px; width: 100%"></div>

    <div class="dialog-footer">
        <el-button size="small" @click="onClickClose">{{ $t("buttons.close") }}</el-button>
        <el-button size="small" type="primary" @click="onClickChoose" v-if="!isShowMode">
            {{ $t("buttons.choose") }}
        </el-button>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import ymaps from "ymaps";

const props = defineProps(["coordinates", "isShowMode"]);
const emit = defineEmits(["onChangePlacemark", "onClose"]);

const center = ref([41.3158161, 69.2758219]);

const onClickChoose = () => {
    emit("onChangePlacemark", center.value);
};
const onClickClose = () => {
    emit("onClose", center.value);
};

const initMaps = () => {
    ymaps.load().then((maps) => {
        const map = new maps.Map("map", {
            center: center.value,
            zoom: 10,
            controls: ["zoomControl", "typeSelector", "fullscreenControl"],
        });

        const placemark = new maps.Placemark(center.value);
        map.geoObjects.add(placemark);

        map.events.add("click", function (e) {
            if (!props.isShowMode) {
                center.value = e.get("coords");
                // setCenter();
                setPlaceMark();
            }
        });

        // const setCenter = () => {
        //   map.setCenter(center.value);
        // };

        const setPlaceMark = () => {
            placemark.geometry.setCoordinates(center.value);
        };
    });
};

onMounted(() => {
    if (props.coordinates[0] !== null && props.coordinates[1] !== null) {
        center.value = props.coordinates;
    }
    initMaps();
});
</script>

<style scoped>
.dialog-footer {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}
</style>
