import { UAParser } from 'ua-parser-js'

export default function useUserAgent() {
  const userAgent = navigator.userAgent
  const parser = new UAParser()
  const result = parser.setUA(userAgent).getResult()

  const dec2hex = (dec) => {
    return dec < 10 ? '0' + String(dec) : dec.toString(16)
  }
  let arr = new Uint8Array(40 / 2)
  window.crypto.getRandomValues(arr)
  const uid = Array.from(arr, dec2hex).join('')

  return {
    result,
    id: uid
  }
}
