<template>
  <div class="page-not-found">
    <h1 class="title">{{ $t('notifications.page_not_found') }} (404)</h1>
    <el-button @click="onClick"
      ><i class="ion-md-home"></i>{{ $t('buttons.go_home') }}</el-button
    >
  </div>
</template>

<script>
import { allowedMainPaths } from '@/router'

export default {
  name: 'PageNotFound',
  methods: {
    onClick() {
      if (!this.$store.state.accessToken) {
        this.$router.push('/auth')
      } else {
        this.$router.push({ name: allowedMainPaths()[0] })
      }
    }
  }
}
</script>

<style scoped>
.page-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-not-found > .title {
  font-size: 27px;
  margin-bottom: 10px;
}
</style>
