<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 v-if="!id" class="page-title">
                {{ $t("titles.add_question") }}
            </h3>
            <h3 v-else class="page-title">{{ $t("titles.edit_question") }}</h3>
        </div>
        <el-row>
            <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="10">
                    <!--          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">-->
                    <!--            <el-form-item label="Savol(en)" prop="question.en">-->
                    <!--              <el-input size="large" class="form-input" v-model="form.question.en" type="textarea"/>-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <!--          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">-->
                    <!--            <el-form-item label="Savol(ru)" prop="question.ru">-->
                    <!--              <el-input size="large" class="form-input" v-model="form.question.ru" type="textarea"/>-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <!--          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">-->
                    <!--            <el-form-item label="Savol(uzCyr)" prop="question.uzCyr">-->
                    <!--              <el-input size="large" class="form-input" v-model="form.question.uzCyr" type="textarea"/>-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->
                    <!--          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">-->
                    <!--            <el-form-item label="Savol(uzLat)" prop="question.uzLat">-->
                    <!--              <el-input size="large" class="form-input" v-model="form.question.uzLat" type="textarea"/>-->
                    <!--            </el-form-item>-->
                    <!--          </el-col>-->

                    <el-col
                        style="margin-bottom: 10px"
                        :xs="24"
                        :sm="24"
                        :md="24"
                        :lg="24"
                        :xl="24"
                    >
                        <el-tabs v-model="activeName" class="demo-tabs">
                            <el-tab-pane :label="$t('langs.uzbek')" name="uzLat">
                                <el-form-item :label="$t('labels.question')" prop="question.uzLat">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <el-input
                                        size="large"
                                        class="form-input"
                                        v-model="form.question.uzLat"
                                        type="textarea"
                                    />
                                </el-form-item>
                                <el-form-item :label="$t('labels.answer')">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <ckeditor
                                        :editor="editor"
                                        v-model="form.answer.uzLat"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane :label="$t('langs.english')" name="en">
                                <el-form-item :label="$t('labels.question')" prop="question.en">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <el-input
                                        size="large"
                                        class="form-input"
                                        v-model="form.question.en"
                                        type="textarea"
                                    />
                                </el-form-item>
                                <el-form-item :label="$t('labels.answer')">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <ckeditor
                                        :editor="editor"
                                        v-model="form.answer.en"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane :label="$t('langs.russian')" name="ru">
                                <el-form-item :label="$t('labels.question')" prop="question.ru">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <el-input
                                        size="large"
                                        class="form-input"
                                        v-model="form.question.ru"
                                        type="textarea"
                                    />
                                </el-form-item>
                                <el-form-item :label="$t('labels.answer')">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <ckeditor
                                        :editor="editor"
                                        v-model="form.answer.ru"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane :label="$t('langs.cyrill')" name="uzCyr">
                                <el-form-item :label="$t('labels.question')" prop="question.uzCyr">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <el-input
                                        size="large"
                                        class="form-input"
                                        v-model="form.question.uzCyr"
                                        type="textarea"
                                    />
                                </el-form-item>
                                <el-form-item :label="$t('labels.answer')">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ $t(error) }}
                                        </span>
                                    </template>
                                    <ckeditor
                                        :editor="editor"
                                        v-model="form.answer.uzCyr"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('labels.category')" prop="categoryId">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>
                            <el-select
                                size="large"
                                style="margin-right: 10px; width: 100%"
                                v-model="form.categoryId"
                                value-key="id"
                            >
                                <el-option
                                    v-for="category in categoryList"
                                    :key="category.id"
                                    :value="category.id"
                                    :label="category.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('labels.serial_number')" prop="order">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>
                            <el-input-number
                                :min="1"
                                size="large"
                                class="form-input-number"
                                v-model="form.order"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button class="custom-btn" @click="() => $router.go(-1)">{{
                                $t("buttons.back")
                            }}</el-button>
                            <el-button
                                class="custom-btn"
                                type="primary"
                                @click="onSubmit(formRef)"
                                >{{ $t("buttons.save") }}</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-col :xs="24" :sm="22" :lg="18" :xl="16"> </el-col>
        </el-row>
    </div>
</template>

<script setup>
import http from "@/http";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const rules = reactive({
    "question.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "question.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "question.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "question.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "answer.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "answer.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "answer.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "answer.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    order: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    categoryId: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});
const formRef = ref();
const loading = ref(false);
const id = ref(null);
const categoryList = ref([]);

const activeName = ref("uzLat");
const editor = ref(ClassicEditor);
const editorConfig = ref({
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "|",
            "|",
            "undo",
            "redo",
        ],
    },
});

const form = reactive({
    question: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    answer: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    order: 1,
    categoryId: null,
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            let request;
            if (id.value) {
                request = http.put(`bank/question/${id.value}`, form);
            } else {
                request = http.post("bank/question", form);
            }
            request
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: t("notifications.saved_successfully"),
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                });
        }
    });
};

const getCategories = () => {
    http.get("bank/question-category")
        .then(({ data }) => {
            categoryList.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        });
};

const getQuestion = () => {
    http(`bank/question/${id.value}`)
        .then(({ data }) => {
            form.question = data.question;
            form.answer = data.answer;
            form.categoryId = data.categoryId;
            form.order = data.order;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        });
};

onMounted(() => {
    getCategories();
    if (route.params.categoryId) {
        form.categoryId = parseInt(route.params.categoryId);
    }
    if (route.params.id) {
        id.value = route.params.id;
        getQuestion();
    }
});
</script>
