<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import LinkedButton from "@/components/buttons/LinkedButton.vue";
import { ElMessage } from "element-plus";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const loadingItem = ref(new Set([]));
const countryCodes = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};
const handleDeleteCountryCode = (id) => {
    loadingItem.value.add(id);
    http.delete(`account/country-codes/${id}`)
        .then(() => {
            ElMessage({
                message: t("notifications.deleted_successfully"),
                type: "success",
            });
            getCountryCodes();
        })
        .finally(() => {
            loadingItem.value.delete(id);
        });
};
const getCountryCodes = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/country-codes", {
        params: {
            size: size.value,
            page: page.value,
        },
    })
        .then(({ data }) => {
            countryCodes.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getCountryCodes();
    }
);

onMounted(() => {
    getCountryCodes();
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.countryCodes") }}</h2>
            <linked-button class="ml-2" route="CountryCodesAdd">Qo'shish</linked-button>
        </div>

        <el-table
            border
            :data="countryCodes"
            :empty-text="t('notifications.information_not_available')"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />
            <el-table-column prop="name" :label="t('labels.name')" align="center" />
            <el-table-column prop="iso" label="ISO" align="center" />
            <el-table-column prop="alpha2" label="Alpha 2" align="center" />
            <el-table-column prop="alpha3" label="Alpha 3" align="center" />
            <el-table-column prop="key" :label="t('labels.key')" align="center">
                <template #default="scope">
                    {{ scope.row.key ? scope.row.key : t("notifications.data_not_found") }}
                </template>
            </el-table-column>
            <el-table-column :label="t('labels.actions')" align="center" :width="160">
                <template #default="scope">
                    <div>
                        <el-space>
                            <el-tooltip :content="t('buttons.edit')" placement="top" effect="dark">
                                <linked-button
                                    type="warning"
                                    plain
                                    route="CountryCodesEdit"
                                    :params="{ id: scope.row.id }"
                                >
                                    <el-icon>
                                        <IconEdit05 />
                                    </el-icon>
                                </linked-button>
                            </el-tooltip>

                            <el-popconfirm
                                width="220"
                                :confirm-button-text="$t('buttons.yes')"
                                :cancel-button-text="$t('buttons.no')"
                                :title="$t('notifications.do_you_want_to_delete_the_country_code')"
                                @confirm="handleDeleteCountryCode(scope.row.id)"
                            >
                                <template #reference>
                                    <span>
                                        <el-tooltip
                                            :content="t('buttons.delete')"
                                            placement="top"
                                            effect="dark"
                                        >
                                            <el-button
                                                type="danger"
                                                :loading="loadingItem.has(scope.row.id)"
                                                plain
                                                route="CountryCodesEdit"
                                            >
                                                <template #icon>
                                                    <el-icon>
                                                        <IconTrash01 />
                                                    </el-icon>
                                                </template>
                                            </el-button>
                                        </el-tooltip>
                                    </span>
                                </template>
                            </el-popconfirm>
                        </el-space>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>
