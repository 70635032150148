<template>
    <div class="page-header">
        <h3>{{ $t("menu.FaqCategoryList") }}</h3>
        <div class="actions">
            <linked-button class="add-button" type="primary" route="FaqCategoryAdd"
                >+ {{ $t("buttons.add") }}</linked-button
            >
        </div>
    </div>
    <div class="main-data" v-loading="loading">
        <el-table
            class-name="table-custom-cls"
            :data="categoryList"
            stripe
            style="width: 100%"
            height="calc(100vh - 200px)"
            table-layout="auto"
            border
            :empty-text="$t('notifications.information_not_available')"
        >
            <el-table-column type="index" label="№" />
            <el-table-column prop="name" :label="$t('labels.name')" />
            <el-table-column :label="$t('labels.actions')" width="100">
                <template v-slot="scope">
                    <el-space>
                        <el-button
                            size="small"
                            type="danger"
                            @click="deleteCategory(scope.row.id)"
                            >{{ $t("buttons.delete") }}</el-button
                        >
                        <linked-button
                            class="ml-2"
                            route="FaqCategoryEdit"
                            size="small"
                            :params="{ id: scope.row.id }"
                            >{{ $t("buttons.edit") }}</linked-button
                        >
                        <linked-button
                            class="ml-2"
                            route="FaqList"
                            size="small"
                            :params="{ categoryId: scope.row.id }"
                            >{{ $t("menu.FaqList") }}</linked-button
                        >
                    </el-space>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup>
import LinkedButton from "@/components/buttons/LinkedButton";
import http from "@/http";
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const loading = ref(false);
const categoryList = ref([]);

const getCategories = () => {
    loading.value = true;
    http.get("bank/question-category")
        .then(({ data }) => {
            console.log(data);
            categoryList.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const deleteCategory = (id) => {
    ElMessageBox.confirm(t("notifications.delete_category"), t("notifications.attention"), {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.cancellation"),
        type: "warning",
    }).then(() => {
        loading.value = true;
        http.delete(`bank/question-category/${id}`)
            .then(() => {
                ElMessage({
                    message: t("notifications.deleted_successfully"),
                    type: "success",
                });
                getCategories();
            })
            .catch(({ response: { data } }) => {
                ElMessage({
                    message: data.message || "Ma'lumot o'chirishda xatolik",
                    type: "error",
                });
            })
            .finally(() => {
                loading.value = false;
            });
    });
};

onMounted(() => {
    getCategories();
});
</script>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-data {
    background-color: #ffffff;
    padding-top: 20px;
    border-radius: 10px;
}
</style>
