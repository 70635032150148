import { createI18n } from 'vue-i18n'
import { ru } from './langs/ru.json'
import { uz_lat } from './langs/uz.json'
import { en } from './langs/en.json'
import { uz_cyr } from './langs/cr.json'
import { ref, watch } from 'vue'
import store from '@/store'

export const selectedLocale = ref('uz_lat')

export const languages = [
  { value: 'uz_lat', label: "O'zbek" },
  { value: 'uz_cyr', label: 'Ўзбек' },
  { value: 'ru', label: 'Русский' },
  { value: 'en', label: 'English' },
]

export const messages = {
  ru,
  uz_lat,
  en,
  uz_cyr
}

selectedLocale.value = sessionStorage.getItem('lang') || 'uz_lat'

const i18n = createI18n({
  legacy: false,
  locale: selectedLocale.value,
  fallbackLocale: 'uz_lat',
  messages
})

watch(selectedLocale, () => {
  sessionStorage.setItem('lang', selectedLocale.value)
  store.commit('updateLocale', selectedLocale.value)
})
export default i18n
