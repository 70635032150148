<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import store from "@/store";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconEye from "@/components/icons/general/IconEye.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const loading = ref(false);
const loadingRefresh = ref(false);
const uzbBankList = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const getUzbBankList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/uzb-bank", {
        params: {
            page: page.value,
            size: 10,
        },
    })
        .then(({ data }) => {
            uzbBankList.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
            size.value = data.size;
        })
        .finally(() => {
            loading.value = false;
        });
};

const setBankName = (name) => {
    store.commit("updatePageTitle", name);
};

const refreshCurrencyData = () => {
    loadingRefresh.value = true;
    http.post("account/uzb-bank/refresh")
        .then(() => {
            getUzbBankList();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getUzbBankList();
    }
);

onMounted(() => {
    getUzbBankList();
});
</script>

<template>
    <div>
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.uzb_banks") }}</h2>

            <el-tooltip :content="t('buttons.refresh')" placement="top-start">
                <el-button @click="refreshCurrencyData">
                    <template v-if="loadingRefresh">
                        <el-icon>
                            <IconLoading02 />
                        </el-icon>
                    </template>
                    <template v-else>
                        <el-icon>
                            <IconRefreshCW01 />
                        </el-icon>
                    </template>
                </el-button>
            </el-tooltip>
        </div>

        <div v-loading="loading">
            <el-table
                :data="uzbBankList"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column
                    prop="name"
                    :label="t('labels.name')"
                    align="center"
                    :width="380"
                />

                <el-table-column prop="codeAlfa" :label="t('labels.codeAlpha')" align="center" />

                <el-table-column prop="codeNumber" :label="t('labels.code')" align="center" />

                <el-table-column prop="dateOpen" :label="t('labels.dateOpen')" align="center">
                    <template #default="scope">
                        {{ scope.row.dateOpen }}
                    </template>
                </el-table-column>

                <el-table-column prop="id" :label="t('labels.actions')" align="center" :width="200">
                    <template #default="scope">
                        <el-tooltip :content="t('buttons.edit')" placement="left-start">
                            <el-button
                                type="warning"
                                plain
                                @click="
                                    router.push({
                                        name: 'UzbBankEditForm',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEdit05 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content="t('buttons.view')" placement="right-end">
                            <el-button
                                type="primary"
                                plain
                                @click="
                                    () => {
                                        router.push({
                                            name: 'UzbBankBranches',
                                            params: {
                                                id: scope.row.id,
                                            },
                                        });
                                        setBankName(scope.row.name);
                                    }
                                "
                            >
                                <el-icon>
                                    <IconEye />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-4">
                <template v-if="totalElements">
                    <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
                </template>

                <el-pagination
                    :current-page="currentPage"
                    class="pagination"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="totalElements"
                    :page-size="size"
                    :page-count="totalPages"
                    :hide-on-single-page="true"
                    :background="true"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-data {
    background-color: #ffffff;
    padding-top: 20px;
    border-radius: 10px;
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.pagination-wrapper > span {
    color: #0a194e;
    font-family: "Montserrat-Bold", sans-serif;
}
</style>
