<script setup>
import { computed, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import http from "@/http";
import SendIcon from "@/assets/images/icon-components/SendIcon.vue";
import PaperclipIcon from "@/assets/images/icon-components/PaperclipIcon.vue";
import FileMessage from "@/views/chat/FileMessage.vue";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";

const store = useStore();
const route = useRoute();
const { t } = useI18n();

const baseUrl = process.env.VUE_APP_BASE_API_URL + "/api/v1";
const uploadHeaders = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
};

const messageLoading = ref(false);
const messageList = ref([]);
const pageable = reactive({
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
});
const context = ref(null);
const fileList = ref([]);
const chatInfo = ref(null);
const userId = computed(() => store.state.user.id);

const getCHatMessages = (getLast = false) => {
    messageLoading.value = !getLast;
    http.get(`bank/chat/operator/${route.params.chatId}`, {
        params: {
            page: getLast ? 0 : pageable.currentPage,
        },
    })
        .then(({ data }) => {
            if (getLast) {
                const lastMessageId = messageList.value[0].id;
                const newMessages = [];
                data.content.forEach((item) => {
                    if (item.id > lastMessageId) {
                        newMessages.push(item);
                    }
                });
                messageList.value = [...newMessages, ...messageList.value];
            } else {
                messageList.value = [...messageList.value, ...data.content];
                pageable.totalElements = data.totalElements;
                pageable.totalPages = data.totalPages;
            }
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_chats"),
                type: "error",
            });
        })
        .finally(() => {
            messageLoading.value = false;
        });
};

const getChatInfo = () => {
    messageLoading.value = true;
    http.get(`bank/chat/info/${route.params.chatId}`)
        .then(({ data }) => {
            chatInfo.value = data;
            if (data.status === "ON_PROCESS") {
                setGetLastMessageInterval();
            }
        })
        .finally(() => {
            messageLoading.value = false;
        });
};

const changeStatus = (status) => {
    ElMessageBox.confirm(
        t("notifications.change_chat_status_to", {
            msg: t(`chat_status.${status}`),
        }),
        t("notifications.attention"),
        {
            confirmButtonText: t("buttons.yes"),
            cancelButtonText: t("buttons.cancellation"),
            type: "warning",
        }
    ).then(() => {
        messageLoading.value = true;
        http.put(`bank/chat/${route.params.chatId}`, null, {
            params: {
                status,
            },
        })
            .then(() => {
                chatInfo.value.status = status;
            })
            .finally(() => {
                messageLoading.value = false;
            });
    });
};

const sendMessage = (type = "TEXT", fileHash = null) => {
    if (context.value || fileHash) {
        const data = {
            chatId: route.params.chatId,
            type,
            context: fileHash ? fileHash : context.value,
        };
        context.value = null;
        http.post(`bank/chat/message`, data)
            .then(({ data }) => {
                messageList.value.unshift({
                    ...data,
                });
            })
            .catch(({ response: { data } }) => {
                ElNotification.error({
                    message:
                        data.message || t("notifications.there_was_an_error_sending_the_message"),
                    type: "error",
                });
            });
    }
};

const beforeUpload = (file) => {
    messageLoading.value = true;
    if (file.size > 20 * 1024 * 1024) {
        ElMessage.error(t("notifications.fileSize"));
    }
};

const uploadSuccess = (response) => {
    messageLoading.value = false;
    sendMessage("FILE", response.hashId);
};

const uploadError = () => {
    messageLoading.value = false;
};

const handleScroll = ({ target: { clientHeight, scrollTop, scrollHeight } }) => {
    if (
        scrollHeight + scrollTop === clientHeight &&
        pageable.currentPage + 1 < pageable.totalPages &&
        !messageLoading.value
    ) {
        pageable.currentPage++;
        getCHatMessages();
    }
};

let interval;
const setGetLastMessageInterval = () => {
    interval = setInterval(() => getCHatMessages(true), 3000);
};

onMounted(() => {
    if (route.params.chatId) {
        getCHatMessages();
        getChatInfo();
    }
});

onBeforeUnmount(() => {
    if (interval) {
        clearInterval(interval);
    }
});
</script>

<template>
    <div class="chat-field" v-loading="messageLoading">
        <div class="chat-header">
            <div v-if="chatInfo">
                <el-avatar style="color: #0d6992; background-color: rgba(143, 173, 189, 0.54)"
                    >{{ chatInfo.userFullName.substring(0, 1) }}
                </el-avatar>
                {{ chatInfo.userFullName }}
            </div>
            <el-dropdown v-if="chatInfo" split-button type="primary" @command="changeStatus">
                {{ $t(`chat_status.${chatInfo.status}`) }}
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item
                            command="PENDING"
                            :disabled="
                                chatInfo.status === 'CLOSED' || chatInfo.status === 'PENDING'
                            "
                            divided
                            >{{ $t("chat_status.PENDING") }}
                        </el-dropdown-item>
                        <el-dropdown-item
                            command="ON_PROCESS"
                            :disabled="
                                chatInfo.status === 'CLOSED' || chatInfo.status === 'ON_PROCESS'
                            "
                            divided
                            >{{ $t("chat_status.ON_PROCESS") }}
                        </el-dropdown-item>
                        <el-dropdown-item
                            command="CLOSED"
                            :disabled="chatInfo.status === 'CLOSED' || chatInfo.status === 'NEW'"
                            divided
                            >{{ $t("chat_status.CLOSED") }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <div class="chat-content" @scroll="handleScroll" v-if="route.params.chatId">
            <div class="message-row" v-for="message in messageList" :key="message.id">
                <div
                    class="message"
                    :class="{
                        'my-message': userId === message.senderId,
                        'system-message': message.senderId === 0,
                    }"
                >
                    <div class="message-text" v-if="message.type === 'TEXT'">
                        {{ message.text }}
                    </div>
                    <FileMessage v-else :file-info="message.fileInfo" />
                    <div class="message-time">{{ dayjs(message.createdAt).format("HH:mm") }}</div>
                </div>
            </div>
        </div>
        <div class="chat-content" v-else>
            <el-empty :description="$t('notifications.select_chat_from_the_list')"></el-empty>
        </div>
        <div class="input-field p-4" v-if="route.params.chatId && chatInfo">
            <el-input
                v-if="chatInfo.status === 'ON_PROCESS' || chatInfo.status === 'PENDING'"
                v-model="context"
                type="textarea"
                autosize
                @keydown.alt.enter="sendMessage('TEXT')"
                :placeholder="$t('labels.write_your_answer')"
                :maxlength="255"
                show-word-limit
            />
            <el-button
                v-if="
                    (chatInfo.status === 'ON_PROCESS' || chatInfo.status === 'PENDING') && context
                "
                @click="sendMessage('TEXT')"
                type="success"
            >
                <SendIcon />
            </el-button>
            <el-upload
                v-model:file-list="fileList"
                multiple
                :action="`${baseUrl}/file/upload`"
                :headers="uploadHeaders"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :show-file-list="false"
                v-if="
                    (chatInfo.status === 'ON_PROCESS' || chatInfo.status === 'PENDING') && !context
                "
            >
                <template #trigger>
                    <el-button type="primary">
                        <PaperclipIcon />
                    </el-button>
                </template>
            </el-upload>
            <el-button
                @click="changeStatus('ON_PROCESS')"
                style="width: 100%"
                type="primary"
                v-if="chatInfo.status === 'NEW'"
                >{{ $t("buttons.acceptance") }}
            </el-button>
        </div>
    </div>
</template>

<style scoped>
.chat-field {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(137, 148, 153, 0.2);
    padding-right: 10px;
    white-space: nowrap;
}

.chat-header {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #a0a0a5;
}

.chat-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    height: max-content;
    overflow-y: auto;
    padding-right: 10px;
}

.message-row {
    margin: 5px;
}

.message {
    width: fit-content;
    max-width: 90%;
    padding: 8px;
    border-radius: 10px 10px 10px 0;
    background-color: #356980;
    color: #ffffff;
}

.my-message {
    margin-inline: auto 0;
    background-color: #3f9453;
    border-radius: 10px 10px 0 10px;
}

.system-message {
    margin-inline: auto;
    background-color: #828582;
    border-radius: 10px;
    font-size: 12px;
    max-width: 90%;
}

.message-text {
    white-space: pre-line;
}

.message-time {
    font-size: x-small;
    text-align: right;
    color: #dcdbdb;
}

.input-field {
    display: flex;
    gap: 10px;
    align-items: end;
    justify-content: center;
    padding-top: 5px;
}
</style>
