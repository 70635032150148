<script setup>
import { onMounted, ref } from "vue";
import http from "@/http";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const btnApprovedLoading = ref(false);
const btnRejectedLoading = ref(false);

const id = ref(null);
const aplicationData = ref({
    id: null,
    organizationName: null,
    organizationType: null,
    organizationUid: null,
    ownerFullName: null,
    status: null,
    info: [],
});
const getAplicationData = (id) => {
    loading.value = true;
    http.get(`appeal/link-abs-admin/${id}`)
        .then(({ data }) => {
            aplicationData.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};
const confirmAplication = (status) => {
    if (status === "REJECTED") {
        ElMessageBox.prompt(
            t("notifications.would_you_like_to_change_application_status"),
            t("notifications.caution"),
            {
                confirmButtonText: t("buttons.yes"),
                cancelButtonText: t("buttons.no"),
                inputPlaceholder: t("notifications.appeal_reject_reason_message"),
                inputValidator: (value) => Boolean(value),
                inputErrorMessage: t("notifications.appeal_reject_reason_message"),
                type: "warning",
            }
        ).then(({ value }) => {
            btnRejectedLoading.value = true;
            http.put(`appeal/link-abs-admin/${id.value}`, {
                status: status,
                reason: value,
            })
                .then(() => {
                    getAplicationData(route.params.id);
                    ElMessage({
                        message: t("notifications.appeal_changed_to", {
                            msg: t(`appeals_status.${status}`),
                        }),
                        type: "success",
                    });
                })
                .finally(() => {
                    btnApprovedLoading.value = false;
                    btnRejectedLoading.value = false;
                });
        });
    } else if (status === "APPROVED") {
        ElMessageBox.confirm(
            t("notifications.would_you_like_to_change_application_status"),
            t("notifications.caution"),
            {
                confirmButtonText: t("buttons.yes"),
                cancelButtonText: t("buttons.no"),
                type: "warning",
            }
        ).then(() => {
            btnApprovedLoading.value = true;
            http.put(`appeal/link-abs-admin/${id.value}`, {
                status: status,
            })
                .then(() => {
                    getAplicationData(route.params.id);
                    ElMessage({
                        message: t("notifications.appeal_changed_to", {
                            msg: t(`appeals_status.${status}`),
                        }),
                        type: "success",
                    });
                })
                .finally(() => {
                    btnApprovedLoading.value = false;
                    btnRejectedLoading.value = false;
                });
        });
    }
};
onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getAplicationData(route.params.id);
    }
});
</script>

<template>
    <div>
        <h3 style="margin-bottom: 20px">{{ $t("titles.appeal_information") }}</h3>
        <div class="main-data">
            <div class="appeal-container" v-loading.body.lock="loading">
                <div v-if="!loading">
                    <h5 style="margin-bottom: 10px">
                        {{ $t("titles.organization_info").toUpperCase() }}
                    </h5>
                    <div class="org-data">
                        <div class="org-data-item">
                            <div class="data-label">
                                <h5>
                                    {{ $t("labels.organization_name") }}
                                </h5>
                            </div>

                            <div class="data-value">
                                <p>
                                    {{ aplicationData.organizationName }}
                                </p>
                            </div>
                        </div>
                        <div class="org-data-item">
                            <div class="data-label">
                                <h5>
                                    {{ $t("labels.organization_owner") }}
                                </h5>
                            </div>

                            <div class="data-value">
                                <p>
                                    {{ aplicationData.ownerFullName }}
                                </p>
                            </div>
                        </div>
                        <div class="org-data-item">
                            <div class="data-label">
                                <h5>
                                    {{ $t("labels.organization_type") }}
                                </h5>
                            </div>

                            <div class="data-value">
                                <p>
                                    {{
                                        aplicationData.organizationType
                                            ? $t(
                                                  `organization_type.${aplicationData.organizationType}`
                                              )
                                            : ""
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="org-data-item">
                            <div class="data-label">
                                <h5>
                                    {{ $t("labels.status") }}
                                </h5>
                            </div>

                            <div class="data-value">
                                <p>
                                    {{
                                        aplicationData.status
                                            ? $t(`appeals_status.${aplicationData.status}`)
                                            : ""
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="org-data-item">
                            <div class="data-label">
                                <h5>{{ $t("labels.pinfil") }} / {{ $t("labels.i_n_n") }}</h5>
                            </div>

                            <div class="data-value">
                                <p>{{ aplicationData.organizationUid }}</p>
                            </div>
                        </div>
                    </div>
                    <h5 style="margin-top: 20px">{{ $t("titles.abs_information") }}</h5>
                    <el-row :gutter="12" class="org-data">
                        <el-col
                            :span="12"
                            v-for="(item, index) in aplicationData.info"
                            :key="item.id"
                            style="margin-bottom: 10px"
                        >
                            <div>
                                <div>
                                    <h5 class="abs-data-index">
                                        {{ index + 1 }}
                                    </h5>
                                </div>

                                <div class="info-item">
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>{{ $t("labels.branch_id") }}</h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>
                                                {{ item.branch }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>{{ $t("labels.client_name") }}</h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>
                                                {{ item.name }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>{{ $t("labels.client_id") }}</h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>
                                                {{ item.clientId }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>{{ $t("labels.owner_full_name") }}</h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>
                                                {{ item.ownerFullName }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>{{ $t("labels.account_number") }}</h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>
                                                {{ item.account }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>
                                                {{ $t("labels.pinfil") }} / {{ $t("labels.i_n_n") }}
                                            </h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>{{ item.ownerPinfl }}</p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>
                                                {{ $t("labels.phone_number") }}
                                            </h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>{{ aplicationData?.phone }}</p>
                                        </div>
                                    </div>
                                    <div class="info-data-item">
                                        <div class="info-item-label">
                                            <h5>Fax</h5>
                                        </div>

                                        <div class="info-item-value">
                                            <p>{{ aplicationData?.fax }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="button-group">
                <el-button @click="() => router.go(-1)">
                    {{ $t("buttons.back") }}
                </el-button>

                <div v-if="aplicationData.status && aplicationData.status === 'CREATED'">
                    <el-space>
                        <el-button
                            class="confirm_btn"
                            type="danger"
                            @click="confirmAplication('REJECTED')"
                            :loading="btnRejectedLoading"
                            :disabled="btnRejectedLoading || btnApprovedLoading"
                        >
                            <template #default v-if="!btnRejectedLoading">
                                {{ $t("buttons.reject") }}
                            </template>
                        </el-button>
                        <el-button
                            class="confirm_btn"
                            type="primary"
                            @click="confirmAplication('APPROVED')"
                            :loading="btnApprovedLoading"
                            :disabled="btnRejectedLoading || btnApprovedLoading"
                        >
                            <template #default v-if="!btnApprovedLoading">
                                {{ $t("buttons.approve") }}
                            </template>
                        </el-button>
                    </el-space>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.main-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 270px);
}

.appeal-container {
    min-height: 380px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.org-data {
    width: 100%;
    margin: 0 !important;
}

.org-data-item {
    display: flex;
    align-items: center;
    min-height: 40px;
    border-color: #636c794d;
    border-style: solid;
    border-width: 0 1px 1px 1px;
}

.abs-data-index {
    margin: 10px 0 5px 0;
    font-size: 14px;
}

.org-data-item:nth-child(1) {
    border-top: 1px solid #636c794d;
}

.data-label {
    display: flex;
    align-items: center;
    width: 30%;
    height: 40px;
    padding-left: 4px;
    font-weight: 600;
    font-size: 16px;
    color: black;
    background-color: #f1f2f4;
}

.data-value {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 4px;
    font-size: 16px;
    font-weight: 500;
    color: black;
    border-left: 1px solid #636c794d;
}

.info-data-item {
    display: flex;
    align-items: center;
    min-height: 30px;
    border-style: solid;
    border-color: #636c794d;
    border-width: 0 1px 1px 1px;
}

.info-data-item:nth-child(1) {
    border-top: 1px solid #636c794d;
}

.info-item-label {
    display: flex;
    align-items: center;
    width: 30%;
    height: 30px;
    padding-left: 4px;
    font-weight: 600;
    font-size: 14px;
    color: black;
    background-color: #f1f2f4;
}

.info-item-value {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 4px;
    font-size: 14px;
    font-weight: 500;
    color: black;
    border-left: 1px solid #636c794d;
}

.confirm_btn {
    min-width: 100px;
}
</style>
