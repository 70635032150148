<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import http from "@/http";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

const router = useRouter();
const route = useRoute();

const { t } = useI18n();
const loading = ref(false);
const formRef = ref();
const id = ref(null);
const form = ref({
    uzLat: null,
    uzCyr: null,
    ru: null,
    en: null,
});

const rules = ref({
    uzLat: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    uzCyr: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    ru: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    en: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
});
const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        loading.value = true;
        if (valid) {
            http.put(`/account/currency-type/${id.value}`, { name: form.value })
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};
const getCurrencyType = (id) => {
    loading.value = true;
    http.get(`account/currency-type/${id}`)
        .then(({ data }) => {
            form.value = data.name;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};
onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getCurrencyType(route.params.id);
    }
});
</script>

<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3>{{ $t("titles.edit_currency_type") }}</h3>
        </div>
        <el-divider border-style="double" style="margin-top: 10px" />
        <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item
                        :label="`${$t('labels.name')}(${$t('langs.uzbek')})`"
                        prop="uzLat"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.uzLat"
                            :placeholder="$t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item
                        :label="`${$t('labels.name')}(${$t('langs.cyrill')})`"
                        prop="uzCyr"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.uzCyr"
                            :placeholder="$t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item :label="`${$t('labels.name')}(${$t('langs.russian')})`" prop="ru">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.ru"
                            :placeholder="$t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item :label="`${$t('labels.name')}(${$t('langs.english')})`" prop="en">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.en"
                            :placeholder="$t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item style="margin-top: 10px">
                        <el-button class="custom-btn" @click="() => router.go(-1)">
                            {{ $t("buttons.back") }}
                        </el-button>
                        <el-button class="custom-btn" type="primary" @click="onSubmit(formRef)">
                            {{ $t("buttons.save") }}
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
