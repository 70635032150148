<script setup>
import LinkedButton from '@/components/buttons/LinkedButton.vue'
import { onMounted, ref } from 'vue'
import http from '@/http'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const districtsList = ref([])

const getDistrictsList = (id) => {
  loading.value = true
  http
    .get(`account/uzb-bank/districts/${id}`)
    .then(({ data }) => {
      districtsList.value = data.content
    })
    .finally(() => {
      loading.value = false
    })
}
onMounted(() => {
  if (route.params.id) {
    getDistrictsList(route.params.id)
  }
})
</script>

<template>
  <div class="page-header">
    <h3>{{ $t('titles.districts') }}</h3>
  </div>
  <div class="main-data" v-loading="loading">
    <el-table
      class-name="table-custom-cls"
      :data="districtsList"
      stripe
      style="width: 100%"
      height="calc(100vh - 225px)"
      :empty-text="$t('notifications.information_not_available')"
      table-layout="auto"
      border
    >
      <el-table-column type="index" label="№" />
      <el-table-column prop="name" :label="$t('labels.name')" />
      <el-table-column prop="status" :label="$t('labels.actions')" width="100">
        <template #default="scope">
          <el-space>
            <linked-button
              route="RegionsAndDistrictEditForm"
              size="small"
              :params="{ type: 'district', id: scope.row.id }"
            >
              {{ $t('buttons.edit') }}
            </linked-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div>
    <el-button class="custom-btn" @click="() => router.go(-1)">
      {{ $t('buttons.back') }}
    </el-button>
  </div>
</template>

<style scoped>
.main-data {
  background-color: #ffffff;
  padding-top: 20px;
  margin-bottom: 15px;
}
</style>
