<script>
import { ElMessage } from "element-plus";

export default {
    name: "UserOrganizationForm",
    data() {
        return {
            loading: false,
            userId: null,
            orgTypes: [{ value: "YATT" }, { value: "YUR" }],
            orgStatuses: [
                { value: "CREATED", disabled: false },
                { value: "ACTIVE", disabled: true },
                { value: "INACTIVE", disabled: true },
                { value: "REJECTED", disabled: true },
            ],
            form: {
                uid: null,
                type: null,
                name: null,
                ownerFullName: "",
                ownerPinfl: null,
            },
            rules: {
                ownerFullName: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "change",
                    },
                ],
                uid: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "change",
                    },
                    {
                        min: 9,
                        max: 9,
                        message: "validations.length_should_be_no_more_or_less_than_9_characters",
                        trigger: "change",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "change",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "change",
                    },
                ],
                ownerPinfl: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "change",
                    },
                    {
                        min: 14,
                        max: 14,
                        message: "validations.length_should_be_no_more_or_less_than_14_characters",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
        getUserOrganizationById() {
            this.loading = true;

            this.$http
                .get(`user/organization/admin/${this.userId}`)
                .then(({ data }) => {
                    this.form = data;
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || this.$t("notifications.error_loading_data"),
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;

                    let http;
                    if (this.userId) {
                        http = this.$http.put(`user/organization/admin/${this.userId}`, this.form);
                    } else {
                        http = this.$http.post("user/organization/admin", this.form);
                    }

                    http.then(() => {
                        ElMessage({
                            message: this.$t("notifications.saved_successfully"),
                            type: "success",
                        });
                        this.$router.go(-1);
                    })
                        .catch(({ response: { data } }) => {
                            ElMessage({
                                message: data.message || "Ma'lumot saqlashda xatolik",
                                type: "error",
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.userId = this.$route.params.id;
            this.getUserOrganizationById();
        }
    },
    watch: {
        "form.uid"() {
            this.form.uid = this.form.uid.replace(/[^0-9]/g, "");
        },
        "form.ownerPinfl"() {
            this.form.ownerPinfl = this.form.ownerPinfl.replace(/[^0-9]/g, "");
        },
        "form.type"() {
            this.form.uid = "";
        },
    },
};
</script>

<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 class="page-title">
                <span v-if="!$route.params.id">
                    {{ $t("titles.add_organization_information") }}
                </span>
                <span v-else>
                    {{ $t("titles.edit_organization_information") }}
                </span>
            </h3>
        </div>

        <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="24">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.s_n_m')" prop="ownerFullName">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            v-model="form.ownerFullName"
                            :placeholder="$t('labels.enter_full_name')"
                            size="large"
                            class="form-input"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.organization_name')" prop="name">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            v-model="form.name"
                            :placeholder="$t('labels.enter_organization_name')"
                            size="large"
                            class="form-input"
                            type="text"
                            maxlength="128"
                        />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.organization_type')" prop="type">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-select
                            v-model="form.type"
                            size="large"
                            :placeholder="$t('labels.select_organization_type')"
                            :style="{ width: '100%' }"
                        >
                            <el-option
                                v-for="option in orgTypes"
                                :key="option"
                                :label="$t(`organization_type.${option.value}`)"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <template v-if="form.type === 'YUR'">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('labels.i_n_n')" prop="uid">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>
                            <el-input
                                v-model="form.uid"
                                placeholder="012345678"
                                size="large"
                                class="form-input"
                                maxlength="9"
                                type="text"
                            />
                        </el-form-item>
                    </el-col>
                </template>

                <template v-if="form.type === 'YATT' || form.type === 'YUR'">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('labels.pinfil')" prop="ownerPinfl">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>
                            <el-input
                                v-model="form.ownerPinfl"
                                autocomplete="on"
                                placeholder="30101800050014"
                                size="large"
                                class="form-input"
                                maxlength="14"
                                minlength="14"
                            />
                        </el-form-item>
                    </el-col>
                </template>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item
                        :label="$t('labels.status')"
                        prop="status"
                        v-if="$route.params.id"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-select
                            v-model="form.status"
                            autocomplete="on"
                            size="large"
                            :placeholder="$t('labels.select_status_type')"
                            :style="{ width: '100%' }"
                        >
                            <el-option
                                v-for="option in orgStatuses"
                                :key="option.value"
                                :label="$t(`organization_status.${option.value}`)"
                                :value="option.value"
                                :disabled="option.disabled"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <div
                :style="{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }"
            >
                <el-button size="large" class="custom-btn" @click="() => this.$router.go(-1)">
                    {{ $t("buttons.back") }}
                </el-button>
                <el-button size="large" class="custom-btn" type="primary" @click="onSubmit">
                    {{ $t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
