<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import http from "@/http";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import store from "@/store";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const debt = ref({});
const loadingDebt = ref(false);
const loadingRefresh = ref(false);
const loadingChangeStatus = ref(false);

function getDebtPaymentById() {
    loadingDebt.value = true;
    http.get(`account/debt-payment-code/${route.params.id}`)
        .then(({ data }) => {
            debt.value = data;
        })
        .finally(() => {
            loadingDebt.value = false;
        });
}

function refreshDebtPaymentCode() {
    loadingRefresh.value = true;
    http.get(`account/debt-payment-code/refresh/?type=DEBT`)
        .then(() => {
            getDebtPaymentById();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
}

function changeDebtStatus(id) {
    loadingChangeStatus.value = true;
    http.put(`account/debt-payment-code/change-status/${id}`)
        .then(({ data }) => {
            getDebtPaymentById();
            ElMessage({
                message: t("notifications.currency_changed_to_msg", {
                    msg: t(`currency_status.${data ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_currency_status"),
                type: "warning",
            });
        })
        .finally(() => {
            loadingChangeStatus.value = false;
        });
}

onMounted(() => {
    getDebtPaymentById();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2>{{ t("menu.DebtPaymentCodeAll") }}</h2>

            <div class="flex items-center gap-x-2">
                <el-button @click="router.go(-1)">
                    <el-icon class="mr-2">
                        <IconArrowLeft />
                    </el-icon>
                    {{ t("buttons.back") }}
                </el-button>

                <el-tooltip :content="t('buttons.refresh')" placement="top">
                    <el-button @click="refreshDebtPaymentCode" :loading="loadingRefresh">
                        <template v-if="!loadingRefresh">
                            <el-icon>
                                <IconRefreshCW01 />
                            </el-icon>
                        </template>
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="main-data" v-loading="loadingDebt">
            <el-table
                :data="[debt]"
                table-layout="auto"
                :header-cell-style="{ color: '#111' }"
                :empty-text="t('notifications.information_not_available')"
                stripe
                fixed
                border
            >
                <el-table-column type="index" label="#" align="center" />

                <el-table-column prop="name.uzLat" :label="t('labels.name')" align="center" />

                <el-table-column prop="code" :label="t('labels.code')" align="center" />

                <el-table-column prop="active" :label="t('labels.active')" align="center">
                    <template #default="scope">
                        <el-switch
                            v-model="scope.row.active"
                            @change="changeDebtStatus(scope.row.id)"
                        />
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
