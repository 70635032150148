<script setup>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const activeName = ref("uzLat");
const id = ref(null);
const formRef = ref();
const form = ref({
    mfo: null,
    bankCode: null,
    region: null,
    distr: null,
    adress: null,
    status: null,
    dateOpen: null,
    name: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        loading.value = true;
        if (valid) {
            http.put(`account/uzb-bank/branch/${id.value}`, { name: form.value.name })
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: t("notifications.saved_successfully"),
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

const getBankBranch = () => {
    loading.value = true;
    http(`account/uzb-bank/branch/${id.value}`)
        .then(({ data }) => {
            form.value.name = data.name;
            form.value.mfo = data.mfo;
            form.value.bankCode = data.bankCode;
            form.value.region = data.region;
            form.value.distr = data.distr;
            form.value.adress = data.adress;
            form.value.status = data.status;
            form.value.dateOpen = data.dateOpen;
        })
        .finally(() => {
            loading.value = false;
        });
};

const rules = reactive({
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    mfo: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getBankBranch();
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ t("titles.edit_branch") }}
            </h2>

            <el-button @click="() => $router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form
            ref="formRef"
            :model="form"
            :rules="rules"
            label-position="top"
            v-loading="loading"
        >
            <el-tabs v-model="activeName">
                <el-tab-pane :label="t('langs.uzbek')" name="uzLat">
                    <el-form-item :label="`${t('labels.name')}`" prop="name.uzLat">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.uzLat"
                            type="text"
                        />
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane :label="t('langs.russian')" name="ru">
                    <el-form-item :label="`${t('labels.name')}`" prop="name.ru">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.ru"
                            type="text"
                        />
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane :label="t('langs.english')" name="en">
                    <el-form-item :label="`${t('labels.name')}`" prop="name.en">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.en"
                            type="text"
                        />
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane :label="t('langs.cyrill')" name="uzCyr">
                    <el-form-item :label="`${t('labels.name')}`" prop="name.uzCyr">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.uzCyr"
                            type="text"
                        />
                    </el-form-item>
                </el-tab-pane>
            </el-tabs>

            <div class="grid grid-cols-3 gap-4">
                <div>
                    <el-form-item :label="`${t('labels.region')}`" prop="region">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            disabled
                            class="form-input"
                            v-model="form.region"
                            type="text"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="`${t('labels.district')}`" prop="distr">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            disabled
                            class="form-input"
                            v-model="form.distr"
                            type="text"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="`${t('labels.date')}`" prop="openDate">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-date-picker
                            v-model="form.dateOpen"
                            disabled
                            type="date"
                            :placeholder="t('labels.select_date')"
                            size="large"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="`${t('labels.branch_id')}`" prop="openDate">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            disabled
                            class="form-input"
                            v-model="form.mfo"
                            type="number"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="`${t('labels.code')}`" prop="openDate">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            disabled
                            class="form-input"
                            v-model="form.bankCode"
                            type="number"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="`${t('labels.address')}`" prop="adress">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            disabled
                            class="form-input"
                            v-model="form.adress"
                            type="text"
                        />
                    </el-form-item>
                </div>
            </div>

            <div class="flex items-center justify-end">
                <el-button type="primary" @click="onSubmit(formRef)">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
