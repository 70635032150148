<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 25 24" fill="none">
    <path d="M17.4268 17.0401L20.9 20.4001M19.78 11.4401C19.78 15.77 16.2699 19.2801 11.94 19.2801C7.61006 19.2801 4.09998 15.77 4.09998 11.4401C4.09998 7.11019 7.61006 3.6001 11.94 3.6001C16.2699 3.6001 19.78 7.11019 19.78 11.4401Z" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<style scoped>

</style>