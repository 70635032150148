<script setup>
import SearchIcon from "@/assets/images/icon-components/SearchIcon.vue";
import http from "@/http";
import { ElMessage } from "element-plus";
import { onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const chatList = ref([]);
const pageable = reactive({
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
    size: 10,
});
const searchValue = ref(null);
const loading = ref(false);
const selectedStatus = ref("ON_PROCESS");

const statusColors = new Map([
    ["NEW", ""],
    ["ON_PROCESS", "success"],
    ["PENDING", "warning"],
    ["CLOSED", "danger"],
]);

const openChat = (item) => {
    router.push({ name: "ChatView", params: { chatId: item.chatId } });
    item.count = 0;
};

let interval;
const setGetNewMessagesInterval = () => {
    interval = setInterval(() => getAllChats(true), 5000);
};

const changeTab = () => {
    pageable.currentPage = 0;
    chatList.value = [];
    getAllChats();
    router.replace({ query: { tab: selectedStatus.value } });
    if (selectedStatus.value === "NEW") {
        setGetNewMessagesInterval();
    } else if (interval) {
        clearInterval(interval);
    }
};

const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a];
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)));
    return c;
};

const getAllChats = (isInterval = false) => {
    loading.value = !isInterval;
    http.get("bank/chat/operator", {
        params: {
            status: selectedStatus.value,
            page: isInterval ? 0 : pageable.currentPage,
            size: pageable.size,
            search: searchValue.value,
        },
    })
        .then(({ data }) => {
            chatList.value = merge(data.content, chatList.value, (a, b) => a.id === b.id);
            pageable.totalElements = data.totalElements;
            pageable.totalPages = data.totalPages;
            pageable.size = data.size;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_chats"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const handleSearch = () => {
    pageable.currentPage = 0;
    chatList.value = [];
    getAllChats();
};

onMounted(() => {
    if (route.query.tab) {
        selectedStatus.value = route.query.tab;
        changeTab();
    }
    getAllChats();
});

onBeforeUnmount(() => {
    if (interval) {
        clearInterval(interval);
    }
});
</script>

<template>
    <div class="chat-list" v-loading="loading">
        <div class="flex items-center justify-center mb-4">
            <el-radio-group v-model="selectedStatus" @change="changeTab">
                <el-radio-button label="NEW">
                    {{ $t("chat_status.NEW") }}
                </el-radio-button>

                <el-radio-button label="ON_PROCESS">
                    {{ $t("chat_status.ON_PROCESS") }}
                </el-radio-button>

                <el-radio-button label="PENDING">
                    {{ $t("chat_status.PENDING") }}
                </el-radio-button>

                <el-radio-button label="CLOSED">
                    {{ $t("chat_status.archive") }}
                </el-radio-button>
            </el-radio-group>
        </div>

        <div class="search-input" v-if="selectedStatus === 'CLOSED'">
            <el-input
                clearable
                v-model="searchValue"
                @keydown.enter="handleSearch"
                @clear="handleSearch"
            >
                <template #append>
                    <el-button @click="handleSearch">
                        <SearchIcon />
                    </el-button>
                </template>
            </el-input>
        </div>

        <div
            v-for="item in chatList"
            :key="item.chatId"
            class="flex items-center mb-4 p-4 rounded-lg cursor-pointer"
            :class="{
                'active-chat': route.params.chatId === item.chatId.toString(),
            }"
            @click="openChat(item)"
        >
            <div class="relative">
                <el-avatar style="color: #0d6992; background-color: rgba(143, 173, 189, 0.54)">
                    {{ item.userFullName.substring(0, 1) }}
                </el-avatar>
                <template v-if="item.count > 0">
                    <div
                        class="absolute -top-2 -end-2 inline-flex items-center justify-center w-6 h-6 rounded-full text-xs font-bold text-white bg-red-500 border-2 border-white"
                    >
                        {{ item.count }}
                    </div>
                </template>
            </div>
            <div class="chat-item-info">
                <div class="chat-item-title">
                    <div class="truncate">
                        {{ item.userFullName }}
                    </div>
                    <el-tag :type="statusColors.get(item.status)">
                        {{ $t(`chat_status.${item.status}`) }}
                    </el-tag>
                </div>
                <div class="chat-last-message">
                    <div class="truncate">
                        {{ item.lastMessage.type === "TEXT" ? item.lastMessage.text : "File" }}
                    </div>
                    <div>{{ dayjs(item.createdDate).format('DD.MM.YYYY') }}</div>
                </div>
            </div>
        </div>
        <el-empty v-if="!chatList.length" :description="$t('notifications.chats_not_available')" />
    </div>
</template>

<style scoped>
.chat-list {
    height: 100%;
    flex-basis: 50%;
    padding-left: 10px;
    overflow-y: auto;
}

.active-chat {
    background-color: rgba(140, 164, 175, 0.45);
}

.chat-item:hover {
    background-color: rgba(187, 187, 187, 0.47);
    cursor: pointer;
}

.chat-item-info {
    margin-inline: 10px;
    flex-grow: 1;
    width: calc(100% - 60px);
}

.chat-item-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.chat-last-message {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    font-size: 12px;
    color: #888585;
}

.search-input {
    padding: 10px;
}
</style>
