<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const uzbBankBranchList = ref([]);
const id = ref(null);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const getUzbBankBranchList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get(`account/uzb-bank/branches/${id.value}`, {
        params: {
            page: page.value,
            size: 10,
        },
    })
        .then(({ data }) => {
            uzbBankBranchList.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
            size.value = data.size;
        })
        .finally(() => {
            loading.value = false;
        });
};

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

watch(
    () => currentPage.value,
    () => {
        getUzbBankBranchList();
    }
);

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getUzbBankBranchList();
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.branches_of_bank") }}</h2>

            <el-button @click="() => $router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <div v-loading="loading">
            <el-table
                :data="uzbBankBranchList"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column
                    prop="name"
                    :label="t('labels.name')"
                    align="center"
                    :width="300"
                />

                <el-table-column prop="mfo" :label="t('labels.branch_id')" align="center" />

                <el-table-column prop="bankCode" :label="t('labels.code')" align="center" />

                <el-table-column prop="region" :label="t('labels.region')" align="center" />

                <el-table-column prop="distr" :label="t('labels.district')" align="center" />

                <el-table-column :label="t('labels.actions')" align="center">
                    <template #default="scope">
                        <el-tooltip
                            :content="t('buttons.edit')"
                            placement="right-start"
                            effect="dark"
                        >
                            <el-button
                                type="warning"
                                plain
                                @click="
                                    router.push({
                                        name: 'UzbBankBranchEditForm',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEdit05 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-2">
                <template v-if="totalElements">
                    <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
                </template>

                <el-pagination
                    :current-page="currentPage"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="totalElements"
                    :page-size="size"
                    :page-count="totalPages"
                    :hide-on-single-page="true"
                    :background="true"
                />
            </div>
        </div>
    </div>
</template>
