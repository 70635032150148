<template>
  <router-link :to="{name: route, query: {...query}, params:{...params}}">
  <el-button
      :type="type"
      :size="size"
      :route="route"
      :disabled="disabled"
      :link="link"
      class="link-button"
  >
    <slot></slot>
  </el-button>
  </router-link>
</template>

<script>
export default {
  name: "LinkedButton",
  props: {
    route: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      required: false
    },
    query: {
      type: Object,
      required: false
    },
    type: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "default"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // routedLink() {
    //   this.$router.push({
    //     name: this.route,
    //     params: this.params,
    //     query: this.query
    //   }).catch(() => {
    //   });
    // }
  }
};
</script>

<style scoped>
.link-button {
  border: none;
  border-radius: 5px;
  padding: 0 20px;
}

</style>