<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import LinkedButton from "@/components/buttons/LinkedButton.vue";
import dayjs from "dayjs";
import IconEye from "@/components/icons/general/IconEye.vue";
import { ElMessage } from "element-plus";
import IconDownload from "@/components/icons/general/IconDownload.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const countryCodes = ref([]);

const dateRange = ref([]);
const selectedStatus = ref("CREATED");

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const getPRTFiles = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/swift/admin/files", {
        params: {
            startDate: dateRange.value[0]
                ? dayjs(dateRange.value[0]).startOf("day").valueOf()
                : null,
            endDate: dateRange.value[1] ? dayjs(dateRange.value[1]).endOf("day").valueOf() : null,
            status: selectedStatus.value,
            size: size.value,
            page: page.value,
        },
    })
        .then(({ data }) => {
            countryCodes.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

const downloadFile = (fileResponse) => {
    if (!loading.value) {
        loading.value = true;
        http.get(`file/download`, {
            params: {
                hashId: fileResponse.hashId,
            },
            responseType: "blob",
        })
            .then((response) => {
                const url = URL.createObjectURL(
                    new Blob([response.data], {
                        type: response.headers["content-type"],
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileResponse.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(({ response: { data } }) => {
                ElMessage({
                    message: data.message || t("error_loading_data"),
                    type: "error",
                });
            })
            .finally(() => {
                loading.value = false;
            });
    }
};

watch(
    () => currentPage.value,
    () => {
        getPRTFiles();
    }
);

onMounted(() => {
    getPRTFiles();
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.prtFiles") }}</h2>
            <div class="flex items-center gap-2">
                <el-date-picker
                    :start-placeholder="$t('labels.from_the_date')"
                    :end-placeholder="$t('labels.until_date')"
                    type="daterange"
                    @change="getPRTFiles"
                    v-model="dateRange"
                />
                <el-select v-model="selectedStatus" @change="getPRTFiles">
                    <el-option value="CREATED" :label="$t('status_list.FINISHED')" />
                    <el-option value="ERROR" :label="$t('status_list.ERROR')" />
                    <el-option value="FINISHED" :label="$t('status_list.FINISHED')" />
                </el-select>
                <linked-button route="PRTFileAdd">{{ $t("buttons.add") }}</linked-button>
            </div>
        </div>

        <el-table
            border
            :data="countryCodes"
            :empty-text="t('notifications.information_not_available')"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />
            <el-table-column prop="field20" label="field20" align="center" />
            <el-table-column prop="status" :label="$t('table.status')" align="center" />
            <el-table-column
                prop="fileItemResponse.name"
                :label="$t('table.file_name')"
                align="center"
            />

            <el-table-column :label="t('labels.actions')" align="center" :width="160">
                <template #default="scope">
                    <el-tooltip :content="t('buttons.download')" placement="top" effect="dark">
                        <el-button
                            type="info"
                            plain
                            @click="downloadFile(scope.row.fileItemResponse)"
                        >
                            <el-icon>
                                <IconDownload />
                            </el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :content="t('buttons.view')" placement="top-start" effect="dark">
                        <linked-button
                            type="primary"
                            plain
                            route="PRTFileView"
                            :params="{ id: scope.row.id }"
                        >
                            <el-icon>
                                <IconEye />
                            </el-icon>
                        </linked-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>
