<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/http'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const formRef = ref()
const loading = ref(false)
const type = ref(null)
const id = ref(null)
const form = reactive({
  name: {
    uzLat: null,
    uzCyr: null,
    ru: null,
    en: null
  }
})
const rules = ref({
  'name.uzLat': {
    required: true,
    message: 'validations.required_field',
    trigger: 'blur'
  },
  'name.uzCyr': {
    required: true,
    message: 'validations.required_field',
    trigger: 'blur'
  },
  'name.ru': {
    required: true,
    message: 'validations.required_field',
    trigger: 'blur'
  },
  'name.en': {
    required: true,
    message: 'validations.required_field',
    trigger: 'blur'
  }
})
const getRegionOrDistrictByIdOrType = (type, id) => {
  loading.value = true
  http
    .get(`account/uzb-bank/${type}/${id}`)
    .then(({ data }) => {
      form.name = data?.name
    })
    .finally(() => {
      loading.value = false
    })
}
const onSubmit = (formRef) => {
  formRef.validate((valid) => {
    if (valid) {
      loading.value = true
      http
        .put(`account/uzb-bank/${type.value}/${id.value}`, form)
        .then(() => {
          ElMessage({
            message: t('notifications.done_successfully'),
            type: 'success'
          })
          router.go(-1)
        })
        .finally(() => {
          loading.value = false
        })
    }
  })
}

onMounted(() => {
  if (
    (route.params.type === 'region' || route.params.type === 'district') &&
    route.params.id
  ) {
    type.value = route.params.type
    id.value = route.params.id
    getRegionOrDistrictByIdOrType(type.value, id.value)
  }
})
</script>

<template>
  <div class="page-header">
    <h3>
      {{
        type === 'region'
          ? $t('titles.edit_region')
          : type === 'district'
            ? $t('titles.edit_region')
            : ''
      }}
    </h3>
  </div>
  <el-divider border-style="double" style="margin-top: 10px" />
  <div class="main-data" v-loading="loading">
    <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
            :label="`${$t('labels.name')}(${$t('langs.uzbek')})`"
            prop="name.uzLat"
          >
            <template #error="{ error }">
              <span class="el-form-item__error">
                {{ $t(error) }}
              </span>
            </template>
            <el-input
              type="text"
              v-model="form.name.uzLat"
              :placeholder="$t('labels.enter_name')"
              size="large"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
            :label="`${$t('labels.name')}(${$t('langs.cyrill')})`"
            prop="name.uzCyr"
          >
            <template #error="{ error }">
              <span class="el-form-item__error">
                {{ $t(error) }}
              </span>
            </template>
            <el-input
              type="text"
              v-model="form.name.uzCyr"
              :placeholder="$t('labels.enter_name')"
              size="large"
            />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
            :label="`${$t('labels.name')}(${$t('langs.russian')})`"
            prop="name.ru"
          >
            <template #error="{ error }">
              <span class="el-form-item__error">
                {{ $t(error) }}
              </span>
            </template>
            <el-input
              type="text"
              v-model="form.name.ru"
              :placeholder="$t('labels.enter_name')"
              size="large"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-form-item
            :label="`${$t('labels.name')}(${$t('langs.english')})`"
            prop="name.en"
          >
            <template #error="{ error }">
              <span class="el-form-item__error">
                {{ $t(error) }}
              </span>
            </template>
            <el-input
              type="text"
              v-model="form.name.en"
              :placeholder="$t('labels.enter_name')"
              size="large"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item style="margin-top: 10px">
            <el-button class="custom-btn" @click="() => router.go(-1)">
              {{ $t('buttons.back') }}
            </el-button>
            <el-button
              class="custom-btn"
              type="primary"
              @click="onSubmit(formRef)"
            >
              {{ $t('buttons.save') }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
