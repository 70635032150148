<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const formRef = ref();
const loading = ref(false);
const id = ref(null);
const form = reactive({
    type: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
    order: 0,
});

const rules = ref({
    "type.uzLat": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    "type.uzCyr": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    "type.ru": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    "type.en": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
});

const getAppealTypeById = (id) => {
    loading.value = true;
    http.get(`bank/mail-type/${id}`)
        .then(({ data }) => {
            form.type = data?.type;
            form.order = data?.order;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;
            if (id.value) {
                http.put(`bank/mail-type/${id.value}`, form)
                    .then(() => {
                        ElMessage({
                            message: t("notifications.done_successfully"),
                            type: "success",
                        });
                        router.go(-1);
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            } else {
                http.post("bank/mail-type", form)
                    .then(() => {
                        ElMessage({
                            message: t("notifications.done_successfully"),
                            type: "success",
                        });
                        router.go(-1);
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }
        }
    });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getAppealTypeById(id.value);
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2>
                {{
                    id
                        ? t("titles.edit_appeal_type")
                        : t("titles.add_appeal_type")
                }}
            </h2>

            <el-button @click="() => router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <div v-loading="loading">
            <el-form
                ref="formRef"
                :model="form"
                :rules="rules"
                label-position="top"
            >
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.uzbek')})`"
                            prop="type.uzLat"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                type="text"
                                v-model="form.type.uzLat"
                                :placeholder="t('labels.enter_name')"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.cyrill')})`"
                            prop="type.uzCyr"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                type="text"
                                v-model="form.type.uzCyr"
                                :placeholder="t('labels.enter_name')"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <el-form-item
                            :label="`${t('labels.name')}(${t(
                                'langs.russian'
                            )})`"
                            prop="type.ru"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                type="text"
                                v-model="form.type.ru"
                                :placeholder="t('labels.enter_name')"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <el-form-item
                            :label="`${t('labels.name')}(${t(
                                'langs.english'
                            )})`"
                            prop="type.en"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                type="text"
                                v-model="form.type.en"
                                :placeholder="t('labels.enter_name')"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-form-item
                            :label="t('labels.serial_number')"
                            prop="order"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input-number
                                v-model="form.order"
                                size="large"
                                :min="0"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="flex justify-end">
                    <el-button
                        type="primary"
                        @click="onSubmit(formRef)"
                    >
                        {{ t("buttons.save") }}
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>
