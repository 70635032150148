<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <template v-if="!$route.params.id">
                <h2 class="text-xl">
                    {{ t("titles.add_new_purpose") }}
                </h2>
            </template>
            <template v-else>
                <h2 class="text-xl">{{ t("titles.edit_purpose") }}</h2>
            </template>

            <el-button @click="() => $router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <div v-loading="loading">
            <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                <div class="grid grid-cols-2 gap-x-4">
                    <div>
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.english')})`"
                            prop="name.en"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                :placeholder="t('labels.enter_name')"
                                v-model="form.name.en"
                                type="text"
                            />
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.russian')})`"
                            prop="name.ru"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                :placeholder="t('labels.enter_name')"
                                v-model="form.name.ru"
                                type="text"
                            />
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.cyrill')})`"
                            prop="name.uzCyr"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                :placeholder="t('labels.enter_name')"
                                v-model="form.name.uzCyr"
                                type="text"
                            />
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.uzbek')})`"
                            prop="name.uzLat"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                :placeholder="t('labels.enter_name')"
                                v-model="form.name.uzLat"
                                type="text"
                            />
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item :label="t('labels.code')" prop="code">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input size="large" v-model="form.code" type="number" :min="0" />
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item :label="t('labels.code')" prop="code">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-select v-model="form.active" size="large">
                                <el-option
                                    v-for="item in statusOptions"
                                    :key="item"
                                    :label="t(`purpose_code_status.${item.label}`)"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <div class="flex items-center justify-end">
                    <el-button type="primary" @click="onSubmit(formRef)">
                        {{ t("buttons.save") }}
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const formRef = ref();
const loading = ref(false);
const id = ref(null);
const statusOptions = [
    { label: "ACTIVE", value: true },
    { label: "INACTIVE", value: false },
];
const form = reactive({
    name: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    active: false,
    code: null,
});

const rules = reactive({
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    mfo: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});

watchEffect(() => {
    if (form.code && form.code >= 5) {
        form.code = form.code.slice(0, 5);
    }
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        loading.value = true;
        if (valid) {
            let request;
            if (id.value) {
                request = http.put(`account/purpose-code/${id.value}`, form);
            } else {
                request = http.post("/account/purpose-code", form);
            }
            request
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: t("notifications.saved_successfully"),
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

const getBank = () => {
    loading.value = true;
    http(`/account/purpose-code/admin/${id.value}`)
        .then(({ data }) => {
            form.code = data.code;
            form.name = data.name;
            form.active = data.active;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getBank();
    }
});
</script>
