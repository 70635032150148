<script setup>
import { reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const AccountNameType = [
    "DEFAULT_ACCOUNT",
    "MASTER_ACCOUNT",
    "SECONDARY_ACCOUNT",
    "CURRENCY_ACCOUNT",
    "SAVING_ACCOUNT",
    "URGENT_ACCOUNT",
    "ACCRUED_ACCOUNT",
    "LETTER_OR_CREDIT_ACCOUNT",
    "SPECIAL_ACCOUNT",
    "CORPORATE_CARD_ACCOUNT",
    "TRANSIT_ACCOUNT",
];
const AccountCodeType = ["FIXED", "AUTO"];

const loading = ref(false);
const id = ref(null);
const formRef = ref();
const form = reactive({
    balanceCode: null,
    currencyType: null,
    currencyCode: null,
    serialNumberType: null,
    serialNumberCode: null,
    type: null,
});

const rules = ref({
    balanceCode: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
        {
            min: 5,
            max: 5,
            message: "validations.length_should_be_no_more_or_less_than_5_characters",
            trigger: "blur",
        },
    ],
    currencyType: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    currencyCode: [
        {
            validator: (rule, value, callback) => {
                if (value && value?.length !== 3) {
                    callback(
                        new Error(
                            t("validations.length_should_be_no_more_or_less_than_3_characters")
                        )
                    );
                } else {
                    callback();
                }
            },
            trigger: "blur",
        },
    ],
    serialNumberType: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    serialNumberCode: [
        {
            validator: (rule, value, callback) => {
                if (value && value?.length !== 3) {
                    callback(
                        new Error(
                            t("validations.length_should_be_no_more_or_less_than_3_characters")
                        )
                    );
                } else {
                    callback();
                }
            },
            trigger: "blur",
        },
    ],
    type: {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;

            form.serialNumberCode = form.serialNumberCode || null;
            form.currencyCode = form.currencyCode || null;

            http.post("account/account-name", form)
                .then(() => {
                    ElMessage({
                        message: t("notifications.done_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.add_account") }}</h2>

            <el-button @click="() => router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form
            ref="formRef"
            :model="form"
            :rules="rules"
            label-position="top"
            v-loading="loading"
        >
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <el-form-item :label="t('labels.balance_account')" prop="balanceCode">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            v-model="form.balanceCode"
                            type="text"
                            :placeholder="t('labels.balance_account')"
                            size="large"
                            maxlength="5"
                            minlength="5"
                            @input="
                                (value) => {
                                    form.balanceCode = value?.replace(/[^0-9]/g, '');
                                }
                            "
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="t('labels.currency_code')" prop="currencyCode">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            v-model="form.currencyCode"
                            type="text"
                            :placeholder="t('labels.currency_code')"
                            size="large"
                            maxlength="3"
                            minlength="3"
                            @input="
                                (value) => {
                                    form.currencyCode = value?.replace(/[^0-9]/g, '');
                                }
                            "
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="t('labels.account_serial_number')"
                        prop="serialNumberCode"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            v-model="form.serialNumberCode"
                            type="text"
                            :placeholder="t('labels.account_serial_number')"
                            size="large"
                            maxlength="3"
                            minlength="3"
                            @input="
                                (value) => {
                                    form.serialNumberCode = value?.replace(/[^0-9]/g, '');
                                }
                            "
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="t('labels.account_number_type')" prop="serialNumberType">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-select
                            v-model="form.serialNumberType"
                            :placeholder="t('labels.select')"
                            size="large"
                            class="w-full"
                        >
                            <el-option
                                v-for="item in AccountCodeType"
                                :key="item"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="t('labels.currency_type')" prop="currencyType">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-select
                            v-model="form.currencyType"
                            :placeholder="t('labels.select')"
                            size="large"
                            class="w-full"
                        >
                            <el-option
                                v-for="item in AccountCodeType"
                                :key="item"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                    </el-form-item>
                </div>

                <div>
                    <el-form-item :label="t('labels.account_name_type')" prop="type">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-select
                            v-model="form.type"
                            :placeholder="t('labels.select')"
                            size="large"
                            class="w-full"
                        >
                            <el-option
                                v-for="item in AccountNameType"
                                :key="item"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="flex items-center justify-end">
                <el-button type="primary" @click="onSubmit(formRef)">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
