<script setup>
import LinkedButton from '@/components/buttons/LinkedButton.vue'
import { onMounted, ref } from 'vue'
import http from '@/http'
import { ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`
const loading = ref(false)
const categoryList = ref([])

function deleteCategoryBy(id) {
  ElMessageBox.confirm(
    t('notifications.delete_data'),
    t('notifications.attention'),
    {
      confirmButtonText: t('buttons.yes'),
      cancelButtonText: t('buttons.no'),
      type: 'warning'
    }
  ).then(() => {
    loading.value = true
    http
      .delete(`notification/category/${id}`)
      .then(() => {
        getCategoryList()
      })
      .finally(() => {
        loading.value = false
      })
  })
}
function getCategoryList() {
  loading.value = true
  http
    .get('notification/category')
    .then(({ data }) => {
      categoryList.value = data
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  getCategoryList()
})
</script>

<template>
  <div class="page-header">
    <h3>{{ $t('menu.NotificationCategoryList') }}</h3>
    <linked-button route="NotificationCategoryCreate" size="large">
      {{ $t('buttons.add') }}
    </linked-button>
  </div>
  <div class="main-data" v-loading="loading">
    <el-table
      class-name="table-custom-cls"
      :data="categoryList"
      stripe
      style="width: 100%"
      height="calc(100vh - 215px)"
      :empty-text="$t('notifications.information_not_available')"
      table-layout="auto"
      border
    >
      <el-table-column type="index" label="№" />
      <el-table-column prop="name" :label="$t('labels.name')" />
      <el-table-column prop="image" :label="$t('labels.image')" align="center">
        <template #default="scope">
          <div class="category-image">
            <img
              v-if="scope.row.image"
              :src="`${baseUrl}/file/download?hashId=${scope.row.image}`"
              class="image"
              alt=""
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template #default="scope">
          <el-space>
            <linked-button
              route="NotificationCategoryEdit"
              size="small"
              :params="{ id: scope.row.id }"
              class="btn_icon"
              type="primary"
            >
              {{ $t('buttons.edit') }}
            </linked-button>
            <el-button
              type="danger"
              size="small"
              class="btn_icon"
              @click="deleteCategoryBy(scope.row.id)"
            >
              {{ $t('buttons.delete') }}
            </el-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.category-image {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image {
  width: 100px;
  display: block;
}
</style>
