<script setup>

</script>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1029 16.2001V18.3001C10.1029 18.8571 10.3261 19.3912 10.7232 19.785C11.1203 20.1788 11.659 20.4001 12.2206 20.4001H19.6324C20.194 20.4001 20.7326 20.1788 21.1298 19.785C21.5269 19.3912 21.75 18.8571 21.75 18.3001L21.75 5.7001C21.75 5.14314 21.5269 4.609 21.1298 4.21517C20.7326 3.82135 20.194 3.6001 19.6324 3.6001L12.2206 3.6001C11.659 3.6001 11.1203 3.82135 10.7232 4.21517C10.3261 4.609 10.1029 5.14314 10.1029 5.7001V7.8001M16.4559 12.0001L3.75 12.0001M3.75 12.0001L6.92647 15.1501M3.75 12.0001L6.92647 8.8501" stroke="#C05E5E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>