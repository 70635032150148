<script setup>
import http from "@/http";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RefreshRight } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const refresh_loading = ref(false);

const id = ref(null);
const currencyRates = ref([]);


const refreshCurrencyData = () => {
    refresh_loading.value = true;
    http.get(`account/currency-${route.params.by}/refresh`)
        .then(() => {
            getCurrencyRatesByCodeId();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            refresh_loading.value = false;
        });
};
const getCurrencyRatesByCodeId = () => {
    loading.value = true;
    http.get(`account/currency-${route.params.by}/rates/${id.value}`)
        .then(({ data }) => {
            currencyRates.value = data.content;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.currency_changed_to_msg", {
                        msg: t(`currency_status.`),
                    }),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const changeCurrencyRateStatus = (id) => {
    loading.value = true;
    http.put(`account/currency-rate/change-active/${id}`)
        .then(({ data }) => {
            getCurrencyRatesByCodeId();
            ElMessage({
                message: t("notifications.currency_changed_to_msg", {
                    msg: t(`currency_status.${data ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_currency_status"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getCurrencyRatesByCodeId();
    }
});
</script>

<template>
    <div class="page-header">
        <h3>{{ $t("menu.CurrencyRateList") }}</h3>
        <el-button
            @click="refreshCurrencyData"
            type="primary"
            size="default"
            class="btn_refresh"
            :loading="refresh_loading"
            :icon="RefreshRight"
            :loading-icon="RefreshRight"
        />
    </div>
    <div class="main-data" v-loading="loading">
        <el-table
            class-name="table-custom-cls"
            :data="currencyRates"
            stripe
            style="width: 100%"
            height="calc(100vh - 250px)"
            :empty-text="$t('notifications.information_not_available')"
            table-layout="auto"
            border
        >
            <el-table-column type="index" label="№" />
            <el-table-column prop="type.name.uzLat" :label="$t('labels.type')" />
            <el-table-column prop="codeNumber.name.uzLat" :label="$t('labels.name')" />
            <el-table-column prop="codeNumber.codeNumber" :label="$t('labels.code')" />
            <el-table-column prop="codeNumber.codeAlfa" :label="$t('labels.ico_code')" />
            <el-table-column prop="value" :label="$t('labels.currency_rate')" />
            <el-table-column prop="active" :label="$t('labels.status')" width="80" align="center">
                <template #default="scope">
                    <el-space>
                        <el-switch
                            v-model="scope.row.active"
                            @change="changeCurrencyRateStatus(scope.row.id)"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        />
                    </el-space>
                </template>
            </el-table-column>
        </el-table>
        <el-row class="actions">
            <el-col :span="12">
                <el-button class="custom-btn" @click="() => $router.go(-1)">
                    {{ $t("buttons.back") }}
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn_refresh {
    padding: 8px;
}
.btn_refresh:deep(.el-icon) {
    font-size: 18px;
}
.actions {
    margin-top: 10px;
    margin-bottom: 15px;
}
.main-data {
    background-color: #ffffff;
    padding-top: 20px;
    border-radius: 10px;
}
.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.pagination-wrapper > span {
    color: #0a194e;
    font-family: "Montserrat-Bold", sans-serif;
}
</style>


