<template>
    <div v-loading="loading || loadingRefresh">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.typeOperations") }}</h2>

            <el-tooltip :content="t('buttons.refresh')" placement="top">
                <el-button @click="refreshData" :loading="loadingRefresh">
                    <template v-if="!loadingRefresh">
                        <el-icon>
                            <IconRefreshCW01 />
                        </el-icon>
                    </template>
                </el-button>
            </el-tooltip>
        </div>

        <el-table
            border
            :data="typeOperations"
            :empty-text="t('notifications.information_not_available')"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" align="center" />

            <el-table-column prop="tid" :label="t('labels.commissionId')" align="center" />

            <el-table-column
                prop="active"
                :label="t('labels.changeState')"
                align="center"
                :width="200"
            >
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.active"
                        @change="changeTypeOperationStatus(scope.row)"
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                    />
                </template>
            </el-table-column>

            <el-table-column :label="t('labels.actions')" align="center" :width="160">
                <template #default="scope">
                    <el-tooltip :content="t('buttons.edit')" placement="top" effect="dark">
                        <el-button
                            type="warning"
                            plain
                            @click="
                                router.push({
                                    name: 'TypeOperationEditView',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEdit05 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import { ElMessage } from "element-plus";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const loadingRefresh = ref(false);
const typeOperations = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

function getTypeOperations() {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/type-operations/admin")
        .then(({ data }) => {
            typeOperations.value = data;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
}

const refreshData = () => {
    loadingRefresh.value = true;
    http.post(`account/type-operations/admin/refresh`)
        .then(() => {
            getTypeOperations();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const changeTypeOperationStatus = (row) => {
    loading.value = true;
    http.put(`account/type-operations/admin/status/${row.id}`)
        .then(({ data }) => {
            getTypeOperations();
            ElMessage({
                message: t("notifications.typeOperationChangedToMsg", {
                    msg: t(`typeOperation_status.${data ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_type_operation_status"),
                type: "warning",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getTypeOperations();
    }
);

onMounted(() => {
    getTypeOperations();
});
</script>
