<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import http from "@/http";
import {ElMessage} from "element-plus";
import IconEye from "@/components/icons/general/IconEye.vue";

const {t} = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const filterStatus = ref(null);
const filterType = ref(null);
const applicationsData = ref([]);
const applicationStatusList = ref(["CREATED", "APPROVED", "REJECTED"]);
const applicationsTypeList = ref(["YATT", "YUR"]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && currentPage.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.replace({
        query: {page: value},
    });
};

const getApplications = () => {
    loading.value = true;
    http.get("appeal/secondary-account/admin/all", {
        params: {
            status: filterStatus.value,
            type: filterType.value,
            page: currentPage.value - 1,
            size: size.value
        },
    })
        .then(({data}) => {
            applicationsData.value = data.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .catch(({response: {data}}) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "warning",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getApplications();
    }
);

onMounted(() => {
    getApplications();
});
</script>

<template>
    <div>
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.appeals_list") }}</h2>

<!--            <div class="flex items-center gap-x-2">-->
<!--                <el-select-->
<!--                    v-model="filterType"-->
<!--                    :placeholder="$t('labels.filter_by_type')"-->
<!--                    size="large"-->
<!--                    style="width: 220px"-->
<!--                    @change="getApplications"-->
<!--                    :clearable="true"-->
<!--                >-->
<!--                    <el-option-->
<!--                        v-for="item in applicationsTypeList"-->
<!--                        :key="item"-->
<!--                        :value="item"-->
<!--                        :label="$t(`organization_type.${item}`)"-->
<!--                    />-->
<!--                </el-select>-->

<!--                <el-select-->
<!--                    v-model="filterStatus"-->
<!--                    :placeholder="$t('labels.filter_by_status')"-->
<!--                    size="large"-->
<!--                    style="width: 220px"-->
<!--                    @change="getApplications"-->
<!--                    :clearable="true"-->
<!--                >-->
<!--                    <el-option-->
<!--                        v-for="item in applicationStatusList"-->
<!--                        :key="item"-->
<!--                        :value="item"-->
<!--                        :label="$t(`appeals_status.${item}`)"-->
<!--                    />-->
<!--                </el-select>-->
<!--            </div>-->
        </div>

        <div v-loading="loading">
            <el-table
                :data="applicationsData"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center"/>

                <el-table-column
                    prop="applicantName"
                    :label="t('labels.applicantName')"
                    align="center"
                />
                <el-table-column
                    prop="organizationName"
                    :label="t('labels.organization_name')"
                    align="center"
                />

                <el-table-column
                    prop="accountHolderName"
                    :label="t('labels.organization_owner')"
                    align="center"
                />

                <el-table-column prop="status" :label="t('labels.status')" align="center" width="150">
                    <template v-slot="scope">
                        <el-tag
                            :type="
                                scope.row.status === 'CREATED'
                                    ? 'success'
                                    : scope.row.status === 'APPROVED'
                                      ? 'warning'
                                      : scope.row.status === 'REJECTED'
                                        ? 'danger'
                                        : ''
                            "
                            round
                        >
                            {{ t(`appeals_status.${scope.row.status}`) }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column :label="t('labels.actions')" align="center" width="120">
                    <template v-slot="scope">
                        <el-tooltip
                            :content="t('buttons.view')"
                            placement="right-end"
                            effect="dark"
                        >
                            <el-button
                                type="primary"
                                plain
                                @click="
                                    router.push({
                                        name: 'SecondaryAccount',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEye/>
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="flex items-center justify-between mt-4">
            <span class="font-semibold" v-if="totalElements">
                {{ t("labels.total") }}: {{ totalElements }}
            </span>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>
