<script setup>
import {onMounted, reactive, ref, watch} from "vue";
import http from "@/http";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {useI18n} from "vue-i18n";
import SelectDropdown from "@/components/dropdown/SelectDropdown.vue";

const route = useRoute();
const router = useRouter();
const {t} = useI18n();

const loading = ref();
// user
const branchLoading = ref(false);
const pageableLoading = ref(false);
const totalBranches = ref(null);
const branchPage = ref(0);
const searchQuery = ref(null);
const branchList = ref([]);
const userBranches = ref([]);

const formRef = ref();
const form = reactive({
  branchIds: [],
  userId: null,
});

const rules = reactive({
  branchIds: [
    {
      required: true,
      message: "validations.required_field",
      trigger: "blur",
    },
  ],
});
const handleSearchEmployee = (query) => {
  searchQuery.value = query;
  branchPage.value = 0;
  branchList.value = []
  branchLoading.value = true;
  getBranchList()
};
const getUserBranches = () => {
  http.get(`user/branch/admin/user-branch`, {
    params:{
      userId: route.params.userId
    }
  })
      .then(({data}) => {

        userBranches.value = data;
        branchList.value = [...branchList.value, ...data];
        form.branchIds = data.map(item=>item.id)
      })
      .finally(() => {
      });
};

const getBranchList = () => {
  http.get(`user/branch/admin`, {
    params: {
      search: searchQuery.value,
      size: 10,
      page: branchPage.value,
    },
  })
      .then(({data}) => {
        branchList.value = [...branchList.value, ...data.content];
        totalBranches.value = data.totalElements;
      })
      .finally(() => {
        branchLoading.value = false;
        pageableLoading.value = false;
      });
};

const handlePageChange = (value) => {
  branchPage.value = value
  pageableLoading.value = true
}

const submitAttachForm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      loading.value = true;
      http.post("user/branch/admin/user", {
        organizationId: route.params.id,
        ...form,
      }).then(() => {
        ElMessage({
          message: t("notifications.done_successfully"),
          type: "success",
        });
        router.go(-1);
      })
          .finally(() => {
            loading.value = false;
          });
    }
  });
};

onMounted(() => {
  getUserBranches()
  getBranchList()

  form.userId = route.params.userId
});
</script>

<template>
  <div class="main-data" v-loading="loading">
    <div class="page-header mb-4">
      <h3 class="page-title">
        {{ $t("titles.branchAttach") }}
      </h3>
    </div>

    <el-form ref="formRef" class="form" :model="form" :rules="rules" label-position="top">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item :label="$t('labels.username')">
            <el-input size="large" disabled :value="route.query.name"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item :label="$t('menu.Branches')" prop="userId">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <SelectDropdown
                v-model="form.branchIds"
                :loading="branchLoading"
                :pageable-loading="pageableLoading"
                :options="branchList"
                :multiple="true"
                :total-count-all="totalBranches"
                :placeholder="$t('labels.select')"
                :i="1"
                :remote="true"
                :filterable="true"
                :reserve-keyword="true"
                @page="handlePageChange"
                @remote-method="(query) => handleSearchEmployee(query)"
                @get-data="getBranchList"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="btn-group">
        <el-button size="large" class="custom-btn" @click="() => router.go(-1)">
          {{ $t("buttons.back") }}
        </el-button>
        <el-button
            size="large"
            class="custom-btn"
            type="primary"
            @click="submitAttachForm"
        >
          {{ $t("buttons.save") }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<style scoped>
.form {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
