<script setup>
import {useRoute, useRouter} from "vue-router";
import http from "@/http";
import {useI18n} from "vue-i18n";
import {computed, onMounted, reactive, ref} from "vue";
import dayjs from "dayjs";
import IconXClose from "@/components/icons/general/IconXClose.vue";
import IconCheckSquare from "@/components/icons/general/IconCheckSquare.vue";


const route = useRoute();
const router = useRouter();
const {t} = useI18n();

const loading = ref(false);
const loadingReject=ref(false)
const list = ref([]);
const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(8);
const page = ref(0);
const dialogFormVisible = ref(false);
const isReject = ref(false);
const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));


const form = reactive({
  contactPhoneNumber: null,
  reason: null,
  id: null
})
const formRef=ref()

const rules = reactive({
  contactPhoneNumber: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
  reason: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
})

const getList = () => {
  loading.value = true;
  page.value = route.query.page ? route.query.page - 1 : 0;
  http.get("appeal/credit/admin", {
    params: {
      page: page.value,
      size: size.value,
    },
  })
      .then(({data}) => {
        list.value = data?.content;
        totalElements.value = data?.totalElements;
        totalPages.value = data?.totalPages;
      })
      .finally(() => {
        loading.value = false;
      });
};

const reject = (id,type) => {
  dialogFormVisible.value = true
  form.id=id
  isReject.value=type === 'reject'
}

const indexMethod = (index) => {
  if (totalPages.value > 1 && page.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

const handleCurrentChange = (value) => {
  router.push({
    query: {page: value},
  }).then(() => {
    getList()
  })
};

const changePhoneNumber = () => {
  const v = form.contactPhoneNumber?.replace(/[^0-9]/g, "");
  const l = v.length;
  let lastValue = "";
  if (l < 3) {
    lastValue = v.replace(/^([0-9]{2})/, "$1");
  } else if (l < 6) {
    const reg = new RegExp(`^([0-9]{2})([0-9]{${l - 2}})`);
    lastValue = v.replace(reg, "$1 $2");
  } else if (l < 8) {
    const reg = new RegExp(`^([0-9]{2})([0-9]{3})([0-9]{${l - 5}})`);
    lastValue = v.replace(reg, "$1 $2-$3");
  } else {
    const reg = new RegExp(`^([0-9]{2})([0-9]{3})([0-9]{2})([0-9]{${l - 7}})`);
    lastValue = v.replace(reg, "$1 $2-$3-$4");
  }

  return (form.contactPhoneNumber = lastValue);
}

const onSubmit = async (formEl) => {
  await formEl.validate((valid, fields) => {
    if (valid) {
      loadingReject.value=true
      const phoneNumber = `+998${form.contactPhoneNumber
          .replaceAll("-", "")
          .replaceAll(" ", "")}`
          .slice(1)
     const data={
       id:form.id,
       contactPhoneNumber:phoneNumber,
       reason:form.reason
     }
      http.post(`appeal/credit/admin/${isReject.value?'reject':'confirm'}`,data)
          .then(()=>{
            getList()
          })
          .finally(()=>{
            loadingReject.value=false
            dialogFormVisible.value = false
          })
    }
  })
}

const onClose = () => {
  form.id=null
  form.contactPhoneNumber=null
  form.reason=null
  isReject.value=false
}

onMounted(() => {
  getList()
})
</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("menu.CreditAppeal") }}</h2>

      <!--      <el-tooltip :content="t('buttons.add')" placement="top">-->
      <!--        <el-button type="primary" @click="router.push({ name: 'CreditAdd' })">-->
      <!--          <el-icon>-->
      <!--            <IconPlus />-->
      <!--          </el-icon>-->
      <!--        </el-button>-->
      <!--      </el-tooltip>-->
    </div>
    <div v-loading="loading">
      <el-table
          :data="list"
          :header-cell-style="{ color: '#111', textAlign: 'center' }"
          :empty-text="t('notifications.information_not_available')"
          border
      >
        <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center"/>

        <el-table-column prop="date" :label="t('labels.date')" align="center">
          <template v-slot="name">
            {{ dayjs(name.row?.application?.date).format('DD.MM.YYYY') }}
          </template>
        </el-table-column>

        <el-table-column prop="status" :label="t('labels.status')" align="center">
          <template v-slot="scope">
            <el-tag
                round
                :type="
                                scope.row.status.key === 'APPROVED'
                                    ? 'success'
                                    : scope.row.status.key === 'CREATED'
                                      ? 'info'
                                      : scope.row.status.key === 'INACTIVE'
                                        ? 'danger'
                                        : scope.row.status.key === 'REJECTED'
                                          ? 'danger'
                                          : ''
                            "
            >
              {{ scope.row.status.name }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
            prop="initialAmount"
            :label="t('labels.initalAmount')"
            align="center"
        >
          <template v-slot="initialAmount">
            {{ initialAmount.row.initialAmount?.toLocaleString("ru") }}
          </template>
        </el-table-column>

        <el-table-column
            prop="amount"
            :label="t('labels.amount')"
            align="center"
        >
          <template v-slot="amount">
            {{ amount.row.amount?.toLocaleString("ru") }}
          </template>
        </el-table-column>


        <el-table-column
            prop="reviewTerm"
            :label="t('labels.client_name')"
            align="center"
        >
          <template v-slot="reviewTerm">
            {{ reviewTerm.row.createdBy.fullName }}
          </template>
        </el-table-column>

        <el-table-column :label="t('labels.settings')" align="center">
          <template #default="scope">
            <el-tooltip v-if="scope.row.status.key!=='CONFIRMED' && scope.row.status.key==='APPROVED'" :content="t('buttons.acceptance')" placement="left-start">
              <el-button
                  type="warning"
                  plain
                  @click.prevent="reject(scope.row.id,'confirm')"
              >
                <el-icon>
                  <IconCheckSquare/>
                </el-icon>
              </el-button>
            </el-tooltip>

            <el-tooltip
                v-if="scope.row.status.key!=='REJECTED' && scope.row.status.key==='APPROVED'  "
                :content="t('buttons.reject')"
                placement="right-start"
                effect="dark"
            >
              <el-button
                  type="danger"
                  plain
                  @click.prevent="reject(scope.row.id,'reject')"
              >
                <el-icon>
                  <IconXClose/>
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex items-center justify-between mt-4">
        <template v-if="totalElements">
          <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
        </template>

        <el-pagination
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="totalElements"
            :page-size="size"
            :page-count="totalPages"
            :hide-on-single-page="true"
            :background="true"
        />
      </div>
    </div>
  </div>
  <el-dialog  @close="onClose"
               v-model="dialogFormVisible" :title="isReject? t('buttons.reject'):t('buttons.acceptance')"  :width="500">
    <el-form ref="formRef" label-position="top"  :rules="rules" :model="form">
      <el-form-item :label="$t('labels.phone_number')" prop="contactPhoneNumber">
        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
        </template>
        <el-input
            placeholder="98 123-45-67"
            size="large"
            class="form-input"
            v-model="form.contactPhoneNumber"
            type="tel"
            :maxlength="12"
            @input="changePhoneNumber"
        >
          <template #prepend>+998</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="reason" :label="isReject?$t('notifications.appeal_reject_reason_message'):$t('notifications.appeal_confirm_reason_message')">
        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
        </template>

        <el-input :placeholder="isReject?$t('notifications.appeal_reject_reason_message'):$t('notifications.appeal_confirm_reason_message')" size="large"
                  class="form-input" v-model="form.reason"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button :loading="loadingReject" type="primary" @click="onSubmit(formRef)">
          {{isReject? t('buttons.reject'):t('buttons.acceptance') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>
