<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import http from "@/http";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const formRef = ref(null);
const formData = ref({
    name: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
});
const loadingDebtPaymentCodeById = ref(false);
const loadingSubmitForm = ref(false);

const rules = ref({
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});

function getDebtPaymentCodeById() {
    loadingDebtPaymentCodeById.value = true;
    http.get(`account/debt-payment-code/${route.params.id}`)
        .then(({ data }) => {
            formData.value = {
                name: data?.name,
            };
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "warning",
            });
        })
        .finally(() => {
            loadingDebtPaymentCodeById.value = false;
        });
}

function onSubmit(value) {
    value.validate((valid) => {
        if (valid) {
            loadingSubmitForm.value = true;
            http.put(`account/debt-payment-code/${route.params.id}`, formData.value)
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                })
                .finally(() => {
                    loadingSubmitForm.value = false;
                });
        }
    });
}

onMounted(() => {
    getDebtPaymentCodeById();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2>{{ t("titles.editDebtPaymentCode") }}</h2>
            <el-button @click="router.go(-1)">
                <el-icon class="mr-2">
                    <IconArrowLeft />
                </el-icon>
                {{ t("buttons.back") }}
            </el-button>
        </div>

        <div class="main-data" v-loading="loadingDebtPaymentCodeById">
            <el-form ref="formRef" :model="formData" label-position="top" :rules="rules">
                <el-row :gutter="6">
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.uzbek')})`"
                            prop="name.uzLat"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                v-model="formData.name.uzLat"
                                :placeholder="t('labels.enter_name')"
                                type="text"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.cyrill')})`"
                            prop="name.uzCyr"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                v-model="formData.name.uzCyr"
                                :placeholder="t('labels.enter_name')"
                                type="text"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.russian')})`"
                            prop="name.ru"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                v-model="formData.name.ru"
                                :placeholder="t('labels.enter_name')"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                        <el-form-item
                            :label="`${t('labels.name')}(${t('langs.english')})`"
                            prop="name.en"
                        >
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                v-model="formData.name.en"
                                :placeholder="t('labels.enter_name')"
                                type="text"
                                size="large"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="flex items-center justify-end mt-4">
                    <el-button type="primary" size="large" @click="onSubmit(formRef)">
                        {{ t("buttons.save") }}
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>
