<template>
    <el-form
        ref="form"
        :model="form"
        status-icon
        class="login-form w-full"
        label-position="top"
        size="large"
        :rules="rules"
    >
        <el-form-item :label="$t('labels.login')" prop="username">
            <template #error="{ error }">
                <span class="el-form-item__error">
                    {{ $t(error) }}
                </span>
            </template>
            <el-input v-model="form.username" type="text" autocomplete="off" class="form-input" />
        </el-form-item>
        <el-form-item :label="$t('labels.password')" prop="password">
            <template #error="{ error }">
                <span class="el-form-item__error">
                    {{ $t(error) }}
                </span>
            </template>
            <el-input
                v-model="form.password"
                type="password"
                autocomplete="off"
                class="form-input"
                show-password
                @change="handleChangePasswordInput"
                @keyup.enter="submitForm"
            />
        </el-form-item>
        <el-form-item>
            <el-button type="success" class="submit-btn" @click="submitForm">
                <div class="dot-loading" :class="{ active: loading }">
                    <DotsLoader />
                </div>
                <span :class="{ login: loading }">{{ $t("labels.enter") }}</span>
            </el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import DotsLoader from "@/components/loader/DotsLoader";
import router, { allowedMainPaths } from "@/router";
import axios from "axios";
import { ElMessage } from "element-plus";
// dev11223344.
export default {
    name: "LoginForm",
    components: { DotsLoader },
    data() {
        return {
            loading: false,
            form: {
                username: null,
                password: null,
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "validations.required_field",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        handleChangePasswordInput() {
            this.form.password = this.form.password.trim();
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    const userAgent = this.$store.state["userAgent"];
                    const { username, password } = this.form;
                    let form_data = new FormData();
                    form_data.append("username", username);
                    form_data.append("password", password);
                    form_data.append("grant_type", "password");
                    form_data.append("device_type", userAgent["device_type"]);
                    form_data.append("device_os", userAgent["device_os"]);
                    form_data.append("device_model", userAgent["device_model"]);
                    form_data.append("device_id", userAgent["device_model"]);
                    form_data.append("version", userAgent["version"]);
                    axios
                        .post(
                            `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}/auth/oauth/token`,
                            form_data,
                            {
                                headers: {
                                    Authorization: `Basic ${process.env.VUE_APP_BASIC_AUTH_TOKEN}`,
                                },
                            }
                        )
                        .then(({ data }) => {
                            this.$store.commit("authenticate", data);
                            this.loadUser();
                        })
                        .catch(({ response: { data } }) => {
                            ElMessage({
                                message:
                                    data.message ||
                                    this.$t("notifications.login_password_did_not_match"),
                                type: "warning",
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
        loadUser() {
            this.$http
                .get("user/me")
                .then(({ data }) => {
                    this.$store.commit("userData", data);
                    if (allowedMainPaths().length) {
                        router.push({ name: allowedMainPaths()[0] });
                    } else {
                        this.$store.commit("signOut");
                        ElMessage({
                            message: `"${data.name}" "${this.$t(
                                "notifications.user_cannot_access_this_system"
                            )}"`,
                            type: "warning",
                        });
                        this.$router.replace("/auth");
                    }
                })
                .catch(({ response: { data } }) => {
                    this.$store.commit("signOut");
                    ElMessage({
                        message: data.message || this.$t("notifications.error_loading_user"),
                        type: "warning",
                    });
                    this.$router.replace("/auth");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.login-form:deep(.el-form-item__label),
.submit-btn {
    font-size: 16px;
    font-weight: bolder;
    color: #313131;
}

.submit-btn {
    margin-left: auto;
    width: 100%;
    color: #ffffff;
}

.dot-loading {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.login {
    opacity: 0;
    transition: opacity 0.2s;
}

.dot-loading.active {
    opacity: 1;
}

:deep(.el-button),
.form-input {
    border-radius: 8px;
    height: 50px;
}
</style>
