<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
    <path d="M13.2 2.3999V7.1999C13.2 7.86264 13.7373 8.3999 14.4 8.3999H19.2M13.4059 2.3999H7.20005C5.87457 2.3999 4.80005 3.47442 4.80005 4.7999V19.1999C4.80005 20.5254 5.87457 21.5999 7.20005 21.5999H16.8C18.1255 21.5999 19.2 20.5254 19.2 19.1999V8.19401C19.2 7.5575 18.9472 6.94705 18.4971 6.49696L15.103 3.10285C14.6529 2.65276 14.0425 2.3999 13.4059 2.3999Z" stroke="currentColor" stroke-width="2"/>
  </svg>
</template>

<style scoped>

</style>