<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         " viewBox="0 0 25 24" fill="none">
    <path d="M10.3571 7.92878V13.9288C10.3571 15.1122 11.3165 16.0716 12.5 16.0716C13.6835 16.0716 14.6429 15.1122 14.6429 13.9288V7.7145C14.6429 5.46591 12.82 3.64307 10.5714 3.64307C8.32284 3.64307 6.5 5.46591 6.5 7.71449V14.3574C6.5 17.6711 9.18629 20.3574 12.5 20.3574C15.8137 20.3574 18.5 17.6711 18.5 14.3574V7.92878" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>