<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/http'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const activeName = ref('uzLat')
const loading = ref(false)
const loadingPermissions = ref(false)
const totalCountAll = ref(null)
const route = useRoute()
const router = useRouter()
const page = ref(0)
const form = ref({
  id: null,
  name: {
    uzLat: null,
    uzCyr: null,
    ru: null,
    en: null
  },
  permissions: null,
  key: null,
  description: {
    uzLat: null,
    uzCyr: null,
    ru: null,
    en: null
  }
})
const formRef = ref(null)
const rules = ref({
  'name.uzLat': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'name.uzCyr': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'name.ru': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'name.en': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'description.uzLat': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'description.uzCyr': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'description.ru': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  'description.en': [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  permissions: [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ],
  key: [
    {
      required: true,
      message: 'validations.required_field',
      trigger: 'blur'
    }
  ]
})
const data = ref([])
const selectedPermissions = ref([])
const allPermissions = ref([])
onMounted(() => {
  if (route.params.id) {
    getPermissionGroup(route.params.id)
  }
  getPermissionGroups()
})

const getPermissionGroup = (id) => {
  loading.value = true
  http
    .get(`user/permission-group/admin/${id}`)
    .then(({ data }) => {
      form.value.description = data.description
      form.value.name = data.name
      form.value.id = data.id
      form.value.permissions = data.permissions.map((item) => item.id)
      form.value.key = data.key
      selectedPermissions.value = data.permissions
      allPermissions.value = data.permissions
    })
    .finally(() => {
      loading.value = false
    })
}

const getPermissionGroups = () => {
  loadingPermissions.value = true
  http
    .get('user/permission/admin', {
      params: {
        page: page.value,
        size: 5
      }
    })
    .then(({ data: dataValue }) => {
      totalCountAll.value = dataValue?.totalElements

      allPermissions.value = [...allPermissions?.value, ...dataValue?.content]
    })
    .finally(() => {
      loadingPermissions.value = false
    })
}
const handleVisibleChange = () => {
  document
    .querySelectorAll('.el-select-dropdown .el-select-dropdown__wrap')[1]
    .addEventListener('scroll', (e) => {
      const clientH = e.target.clientHeight
      const scrollTop = e.target.scrollTop
      const scrollH = e.target.scrollHeight
      if (
        Math.ceil(clientH + scrollTop) >= scrollH &&
        totalCountAll.value >= page.value * 5 &&
        !loadingPermissions.value
      ) {
        page.value = page.value + 1
        window.scrollTo({
          top: 50
        })
        getPermissionGroups()
      }
    })
}
watch(allPermissions, () => {
  let newArr = []
  if (allPermissions.value) {
    newArr = allPermissions.value.map((item) => {
      return {
        value: item.id,
        label: item.name
      }
    })

    if (selectedPermissions.value) {
      const newObjArr = selectedPermissions.value.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      newArr = [...newObjArr, ...newArr]
    }

    data.value = [
      ...new Map(newArr.map((item) => [item['value'], item])).values()
    ]
  }
})

const onSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      loading.value = true
      let https

      if (form.value.id) {
        https = http.put(
          `user/permission-group/admin/${form.value.id}`,
          form.value
        )
      } else {
        https = http.post('user/permission-group/admin', form.value)
      }
      https
        .then(() => {
          ElMessage({
            message: t('notifications.saved_successfully'),
            type: 'success'
          })
          router.go(-1)
        })
        .finally(() => {
          loading.value = false
        })
    }
  })
}
</script>

<template>
  <div class="main-data" v-loading="loading">
    <div class="page-header">
      <h3 v-if="!route.params?.id" class="page-title">
        {{ $t('titles.add_permission_group') }}
      </h3>
      <h3 v-else class="page-title">
        {{ $t('titles.edit_permission_group') }}
      </h3>
    </div>
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24" :xl="24">
        <el-form
          ref="formRef"
          :model="form"
          :rules="rules"
          label-position="top"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-tabs v-model="activeName" class="demo-tabs">
                <el-tab-pane :label="$t('langs.uzbek')" name="uzLat">
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_name')"
                        prop="name.uzLat"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_name')"
                          v-model="form.name.uzLat"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_comment')"
                        prop="description.uzLat"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_comment')"
                          v-model="form.description.uzLat"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane :label="$t('langs.english')" name="en">
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_name')"
                        prop="name.en"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_name')"
                          v-model="form.name.en"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_comment')"
                        prop="description.en"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_comment')"
                          v-model="form.description.en"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane :label="$t('langs.russian')" name="ru">
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_name')"
                        prop="name.ru"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_name')"
                          v-model="form.name.ru"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_comment')"
                        prop="description.ru"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_comment')"
                          v-model="form.description.ru"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane :label="$t('langs.cyrill')" name="uzCyr">
                  <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_name')"
                        prop="name.uzCyr"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_name')"
                          v-model="form.name.uzCyr"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                      <el-form-item
                        :label="$t('labels.enter_comment')"
                        prop="description.uzCyr"
                      >
                        <template #error="{ error }">
                          <span class="el-form-item__error">
                            {{ $t(error) }}
                          </span>
                        </template>
                        <el-input
                          size="large"
                          class="form-input"
                          :placeholder="$t('labels.enter_comment')"
                          v-model="form.description.uzCyr"
                          type="text"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item :label="$t('labels.enter_key')" prop="key">
                <template #error="{ error }">
                  <span class="el-form-item__error">
                    {{ $t(error) }}
                  </span>
                </template>
                <el-input
                  size="large"
                  class="form-input"
                  :placeholder="$t('labels.enter_key')"
                  v-model="form.key"
                  type="text"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                :label="$t('labels.select_permissions')"
                prop="permissions"
              >
                <template #error="{ error }">
                  <span class="el-form-item__error">
                    {{ $t(error) }}
                  </span>
                </template>
                <el-select
                  v-model="form.permissions"
                  :multiple="true"
                  :collapse-tags="true"
                  :placeholder="$t('labels.select_permissions')"
                  size="large"
                  popper-class="custom-select"
                  style="width: 100%"
                  @visible-change="handleVisibleChange"
                >
                  <el-option
                    v-for="item in data"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <el-button class="custom-btn" @click="() => router.go(-1)">{{
                  $t('buttons.back')
                }}</el-button>
                <el-button
                  class="custom-btn"
                  type="primary"
                  @click="onSubmit"
                  >{{ $t('buttons.save') }}</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
