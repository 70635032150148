<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.commissions") }}</h2>

            <el-tooltip :content="t('buttons.refresh')" placement="top">
                <el-button @click="refreshData" :loading="loadingRefresh">
                    <template v-if="!loadingRefresh">
                        <el-icon>
                            <IconRefreshCW01 />
                        </el-icon>
                    </template>
                </el-button>
            </el-tooltip>
        </div>

        <el-table
            :data="commissions"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
            border
            v-loading="loading"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" align="center" />

            <el-table-column prop="cid" :label="t('labels.commissionId')" align="center" />

            <el-table-column
                prop="active"
                :label="t('labels.changeState')"
                align="center"
                :width="200"
            >
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.active"
                        @change="changeCommissionStatus(scope.row)"
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                    />
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const loadingRefresh = ref(false);
const commissions = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(4);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

function getCommissions() {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get(`account/commissions/admin`)
        .then(({ data }) => {
            commissions.value = data;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
}

const refreshData = () => {
    loadingRefresh.value = true;
    http.post(`account/commissions/admin/refresh`)
        .then(() => {
            getCommissions();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const changeCommissionStatus = (row) => {
    loading.value = true;
    http.put(`account/commissions/admin/${row.id}`)
        .then(({ data }) => {
            getCommissions();
            ElMessage({
                message: t("notifications.commissionChangedToMsg", {
                    msg: t(`commission_status.${data ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_commission_status"),
                type: "warning",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getCommissions();
    }
);

onMounted(() => {
    getCommissions();
});
</script>
