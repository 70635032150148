<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 22 22" fill="none">
    <path d="M20.0703 1.92949L9.40635 12.5935M2.271 7.23517L18.8769 1.47394C19.8995 1.11915 20.8807 2.10028 20.5259 3.12291L14.7646 19.7288C14.37 20.8664 12.7725 20.8976 12.3337 19.7763L9.69679 13.0375C9.56509 12.701 9.29882 12.4347 8.96226 12.303L2.22354 9.66613C1.10219 9.22734 1.13338 7.62985 2.271 7.23517Z" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<style scoped>

</style>