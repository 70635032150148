<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconSearchMd from "@/components/icons/general/IconSearchMd.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const timeout = ref(null);
const loading = ref(false);
const loadingRefresh = ref(false);
const tableData = ref([]);
const searchedValue = ref(route.query.search);
const clientId = ref(null);

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value, size: size.value, clientId: clientId.value },
    });
};

const refreshData = () => {
    loadingRefresh.value = true;
    http.get("account/client-branch/refresh", {
        params: {
            page: page.value,
            size: size.value,
            clientId: clientId.value,
        },
    })
        .then(() => {
            getAll();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const handleSearch = () => {
    clearTimeout(timeout.value);

    if (searchedValue.value) {
        router.push({
            query: {
                page: currentPage.value,
                size: size.value,
                clientId: clientId.value,
                search: searchedValue.value,
            },
        });
    } else {
        router.push({
            query: {
                page: currentPage.value,
                size: size.value,
                clientId: clientId.value,
            },
        });
    }

    timeout.value = setTimeout(() => {
        getAll();
    }, 1000);
};

const getAll = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/client-branch", {
        params: {
            page: page.value,
            size: size.value,
            clientId: clientId.value,
            search: searchedValue.value,
        },
    })
        .then(({ data }) => {
            tableData.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getAll();
    }
);

onMounted(() => {
    if (route.params.clientId) {
        clientId.value = route.params.clientId;
        getAll();
    }
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.clientBranches") }}</h2>

            <div class="flex items-center gap-x-2">
                <div class="max-w-[240px]">
                    <el-input
                        type="text"
                        @keyup="handleSearch"
                        v-model="searchedValue"
                        :placeholder="t('placeholders.input.searchBranches')"
                    >
                        <template #prefix>
                            <el-icon size="18px">
                                <IconSearchMd />
                            </el-icon>
                        </template>
                    </el-input>
                </div>

                <el-tooltip :content="t('buttons.refresh')" placement="top">
                    <el-button @click="refreshData">
                        <template v-if="loadingRefresh">
                            <el-icon>
                                <IconLoading02 />
                            </el-icon>
                        </template>
                        <template v-else>
                            <el-icon>
                                <IconRefreshCW01 />
                            </el-icon>
                        </template>
                    </el-button>
                </el-tooltip>

                <el-button
                    class="!ml-0"
                    @click="
                        router.push({
                            name: 'ClientsView',
                        })
                    "
                >
                    <el-icon>
                        <IconArrowLeft />
                    </el-icon>
                    <span>{{ t("buttons.back") }}</span>
                </el-button>
            </div>
        </div>

        <el-table
            border
            :data="tableData"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('table.name')" align="center" width="300" />

            <el-table-column prop="address" :label="t('table.address')" align="center" />

            <el-table-column prop="mfo" :label="t('table.mfo')" align="center" width="100" />

            <el-table-column :label="t('labels.actions')" align="center">
                <template #default="scope">
                    <el-button
                        class="text-xs"
                        size="small"
                        type="primary"
                        @click="
                            router.push({
                                name: 'ClientAccountsView',
                                params: {
                                    clientId: clientId,
                                },
                                query: {
                                    branchId: scope.row.branchId,
                                },
                            })
                        "
                    >
                        {{ t("buttons.accountNumber") }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :background="true"
            />
        </div>
    </div>
</template>
