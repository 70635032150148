<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 v-if="!id" class="page-title">
                {{ $t("titles.add_category") }}
            </h3>
            <h3 v-else class="page-title">{{ $t("titles.edit_category") }}</h3>
        </div>
        <el-row>
            <el-col :xs="24" :sm="20" :lg="12" :xl="14">
                <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.english')})`"
                                prop="name.en"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.en"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.russian')})`"
                                prop="name.ru"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.ru"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.cyrill')})`"
                                prop="name.uzCyr"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.uzCyr"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.uzbek')})`"
                                prop="name.uzLat"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    size="large"
                                    class="form-input"
                                    v-model="form.name.uzLat"
                                    type="text"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                            <el-form-item :label="$t('labels.serial_number')" prop="order">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input-number
                                    :min="1"
                                    size="large"
                                    class="form-input-number"
                                    v-model="form.order"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button class="custom-btn" @click="() => $router.go(-1)">
                                    {{ $t("buttons.back") }}
                                </el-button>
                                <el-button
                                    class="custom-btn"
                                    type="primary"
                                    @click="onSubmit(formRef)"
                                    >{{ $t("buttons.save") }}</el-button
                                >
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import http from "@/http";
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const rules = reactive({
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    order: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});
const formRef = ref();
const loading = ref(false);
const id = ref(null);

const form = reactive({
    name: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
    order: null,
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            let request;
            if (id.value) {
                request = http.put(`bank/question-category/${id.value}`, form);
            } else {
                request = http.post("bank/question-category", form);
            }
            request
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: t("notifications.saved_successfully"),
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                });
        }
    });
};

const getCategory = () => {
    http(`bank/question-category/${id.value}`)
        .then(({ data }) => {
            form.order = data.order;
            form.name = data.name;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getCategory();
    }
});
</script>
