<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import http from "@/http";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import SelectDropdown from "@/components/dropdown/SelectDropdown.vue";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const totalCountAll = ref(null);
const loading = ref();
// user
const userLoading = ref(false);
const totalUsers = ref(null);
const userPage = ref(0);
const searchQuery = ref(null);
const userList = ref([]);
// role
const roleLoading = ref(false);
const roleList = ref([]);
// permission Group
const permissionGroupLoading = ref(false);
const totalPermissionGroups = ref(null);
const permissionGroupList = ref([]);
// permission
const permissionLoading = ref(false);
const permissionPage = ref(0);
const permissionList = ref([]);

const formRef = ref();
const id = ref(null);
const form = reactive({
    roleId: null,
    permissionGroupId: null,
    permissionIds: [],
    userId: null,
});

const rules = reactive({
    userId: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    roleId: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    permissionGroupId: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    permissionIds: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});

const getEmployee = (id) => {
    loading.value = true;
    http.get(`user/organization-employee-admin/get/${id}`)
        .then(({ data }) => {
            roleList.value.push(data?.role);
            permissionGroupList.value.push(data?.permissionGroup);
            permissionList.value = [...data?.permissions];
            form.userId = `${data?.userResponse?.name} ${data?.userResponse?.surname} ${
                data?.userResponse?.middleName ? data?.userResponse.middleName : ""
            }`;
            form.roleId = data.role.id;
            form.permissionGroupId = data?.permissionGroup.id;
            form.permissionIds = data?.permissions.map((item) => item.id);
            getPermissionGroupList();
            getPermissionList();
        })
        .finally(() => {
            loading.value = false;
        });
};
const handleSearchEmployee = (query) => {
    searchQuery.value = query;
    userPage.value = 0;
    userLoading.value = true;
    http.get(`user/admin/find-all/${route.params.id}`, {
        params: {
            search: query,
            size: 10,
            page: userPage.value,
        },
    })
        .then(({ data }) => {
            userList.value = data.content;
            totalUsers.value = data.totalElements;
        })
        .finally(() => {
            userLoading.value = false;
        });
};
const getUserList = () => {
    http.get(`user/admin/find-all/${route.params.id}`, {
        params: {
            search: searchQuery.value,
            size: 10,
            page: userPage.value,
        },
    })
        .then(({ data }) => {
            userList.value = [...userList.value, ...data.content];
            totalUsers.value = data.totalElements;
        })
        .finally(() => {});
};

const getRoleList = () => {
    roleLoading.value = true;
    http.get("user/role")
        .then(({ data }) => {
            roleList.value = [...roleList.value, ...data];
        })
        .finally(() => {
            roleLoading.value = false;
        });
};
const getPermissionGroupList = () => {
    permissionGroupLoading.value = true;
    http.get(`user/permission-group/`, {
        params: {
            roleId: form.roleId,
        },
    })
        .then(({ data }) => {
            permissionGroupList.value = [...permissionGroupList.value, ...data];
            totalPermissionGroups.value = data.totalElements;
        })
        .finally(() => {
            permissionGroupLoading.value = false;
        });
};
const getPermissionList = () => {
    permissionLoading.value = true;
    http.get("user/permission", {
        params: {
            roleId: form.roleId,
            permissionGroupId: form.permissionGroupId,
        },
    })
        .then(({ data }) => {
            permissionList.value = [...permissionList.value, ...data];
        })
        .finally(() => {
            permissionLoading.value = false;
        });
};
const handleChooseRole = (id) => {
    permissionGroupList.value = [];
    form.permissionGroupId = null;
    form.permissionIds = [];
    getPermissionGroupList(id);
};
const handleChoosePermissionGroup = () => {
    permissionList.value = [];
    form.permissionIds = [];
    permissionPage.value = 0;
    getPermissionList();
};

const submitEmployeeForm = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true;
            let https;
            if (route.params.type === "combine") {
                https = http.post("user/organization-employee-admin", {
                    organizationId: route.params.id,
                    ...form,
                });
            } else if (route.params.type === "edit") {
                https = http.put(`user/organization-employee-admin/${id.value}`, {
                    roleId: form.roleId,
                    permissionGroupId: form.permissionGroupId,
                    permissionIds: form.permissionIds,
                });
            }
            https
                .then(() => {
                    ElMessage({
                        message: t("notifications.done_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

onMounted(() => {
    getRoleList();
    if (route.params.type === "combine") {
        getUserList();
    } else if (route.params.type === "edit") {
        id.value = route.params.id;
        getEmployee(route.params.id);
    }
});
</script>

<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3 class="page-title">
                {{
                    route.params.type === "combine"
                        ? $t("titles.combine_employee")
                        : route.params.type === "edit"
                          ? $t("titles.edit_employee")
                          : ""
                }}
            </h3>
        </div>

        <el-form ref="formRef" class="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item :label="$t('labels.employee')" prop="userId">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <SelectDropdown
                            v-model="form.userId"
                            :loading="userLoading"
                            :options="userList"
                            :total-count-all="totalUsers"
                            :placeholder="$t('labels.select_employee')"
                            :i="1"
                            :disable="route.params.type === 'edit'"
                            :remote="true"
                            :filterable="true"
                            :reserve-keyword="true"
                            @page="(value) => (userPage = value)"
                            @remote-method="(query) => handleSearchEmployee(query)"
                            @get-data="getUserList"
                        />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item :label="$t('labels.role')" prop="roleId">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <SelectDropdown
                            v-model="form.roleId"
                            :suffix-loading="roleLoading"
                            :options="roleList"
                            @change="handleChooseRole"
                            :i="2"
                            :placeholder="$t('labels.select_rol')"
                        />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item :label="$t('labels.permissions_group')" prop="permissionGroupId">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <SelectDropdown
                            v-model="form.permissionGroupId"
                            :suffix-loading="permissionGroupLoading"
                            :options="permissionGroupList"
                            :disable="!form.roleId"
                            :i="3"
                            :placeholder="$t('labels.select_permission_group')"
                            @change="handleChoosePermissionGroup"
                        />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item :label="$t('labels.permission')" prop="permissionIds">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <SelectDropdown
                            v-model="form.permissionIds"
                            :suffix-loading="permissionLoading"
                            :options="permissionList"
                            :disabled="!form.permissionGroupId"
                            :multiple="true"
                            :collapse-tags="true"
                            :i="4"
                            :placeholder="$t('labels.select_permissions')"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="btn-group">
                <el-button size="large" class="custom-btn" @click="() => router.go(-1)">
                    {{ $t("buttons.back") }}
                </el-button>
                <el-button
                    size="large"
                    class="custom-btn"
                    type="primary"
                    @click="submitEmployeeForm"
                >
                    {{ $t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<style scoped>
.form {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>
