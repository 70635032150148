<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import http from "@/http";
import { checkPermission } from "@/composables";
import IconEye from "@/components/icons/general/IconEye.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const dbusinessStatusList = ref(["DELETED", "FINISHED", "REJECTED", "ERROR", "ON_APPEAL"]);
const appealsList = ref([]);
const absStatusList = ref([]);
const documentStatus = ref("");
const absStatus = ref("");
const docAbsStatus = ref(route.query?.absStatus);
const docStatus = ref(route.query?.status);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const handleFilterByDocumentStatus = (value) => {
    router.push({
        query: {
            page: currentPage.value,
            status: value,
            absStatus: docAbsStatus.value,
        },
    });
    getOnAppealsList();
};

const handleFilterByAbsStatus = (value) => {
    router.push({
        query: {
            page: currentPage.value,
            status: docStatus.value,
            absStatus: value,
        },
    });
    getOnAppealsList();
};

const getAbsStatusList = () => {
    loading.value = true;
    http.get("account/document/abs-document-status")
        .then(({ data }) => {
            absStatusList.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const getOnAppealsList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/on-appeal-documents", {
        params: {
            page: page.value,
            size: size.value,
            status: docStatus.value,
            absStatus: docAbsStatus.value,
        },
    })
        .then(({ data }) => {
            appealsList.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getOnAppealsList();
    }
);

onMounted(() => {
    getOnAppealsList();
    if (absStatusList.value.length === 0) {
        getAbsStatusList();
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ $t("titles.appeals_list") }}</h2>

            <el-row :gutter="10" v-if="checkPermission('DOCUMENT_ADMIN')">
                <el-col :span="12">
                    <el-select
                        v-model="docStatus"
                        style="width: 220px"
                        size="large"
                        :placeholder="$t('labels.document_status')"
                        :clearable="true"
                        @change="handleFilterByDocumentStatus"
                    >
                        <el-option
                            v-for="item in dbusinessStatusList"
                            :label="$t(`status_list.${item}`)"
                            :value="item"
                            :key="item"
                        />
                    </el-select>
                </el-col>
                <el-col :span="12">
                    <el-select
                        v-model="docAbsStatus"
                        style="width: 220px"
                        size="large"
                        :placeholder="$t('labels.document_status_of_abs')"
                        :clearable="true"
                        @change="handleFilterByAbsStatus"
                    >
                        <el-option
                            v-for="item in absStatusList"
                            :key="item.status"
                            :label="item.localName"
                            :value="item.status"
                        />
                    </el-select>
                </el-col>
            </el-row>
        </div>

        <div v-loading="loading">
            <el-table
                :data="appealsList"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />
              <el-table-column
                  prop="id"
                  :label="t('labels.appealId')"
                  align="center"
                  width="150"
              />
                <el-table-column
                    prop="clientName"
                    :label="t('labels.name')"
                    align="center"
                    :width="160"
                />

                <el-table-column prop="amount" :label="t('labels.amount')" align="center">
                  <template #default="scope">
                    {{scope.row.amount.toLocaleString('ru')}}
                  </template>
                </el-table-column>

                <el-table-column
                    prop="departureDate"
                    :label="t('labels.date')"
                    align="center"
                    :width="160"
                >
                    <template #default="scope">
                        {{ dayjs.unix(scope.row.departureDate).format("DD.MM.YYYY") }}
                    </template>
                </el-table-column>

                <el-table-column
                    prop="documentErrorCode"
                    :label="t('labels.error_code')"
                    align="center"
                />

                <el-table-column
                    prop="documentErrorMessage"
                    :label="t('labels.reason_for_error')"
                    align="center"
                >
                    <template #default="scope">
                        <div class="truncate">
                            {{ scope.row.documentErrorMessage }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column :label="t('labels.actions')" align="center">
                    <template #default="scope">
                        <el-tooltip
                            :content="t('buttons.view')"
                            placement="right-start"
                            effect="dark"
                        >
                            <el-button
                                type="primary"
                                plain
                                @click="
                                    router.push({
                                        name: 'OnAppealForm',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEye />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="flex items-center justify-between mt-4">
            <span class="inline-block font-semibold" v-if="totalElements">
                {{ t("labels.total") }}: {{ totalElements }}
            </span>
            <el-pagination
                :current-page="currentPage"
                class="pagination"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            >
            </el-pagination>
        </div>
    </div>
</template>

<style scoped>
.button-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
