<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessageBox, ElMessage } from "element-plus";
import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const data = ref([]);
const loading = ref(false);
const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route?.query?.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (route.query.page - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router
        .replace({
            query: { page: value },
        })
        .then(() => {
            getAppealTypeList();
        });
};

const deleteAppealType = (id) => {
    ElMessageBox.confirm(t("notifications.delete_data"), t("notifications.attention"), {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.cancellation"),
        type: "warning",
    }).then(() => {
        loading.value = true;
        http.delete(`bank/mail-type/${id}`)
            .then(() => {
                ElMessage({
                    message: t("notifications.deleted_successfully"),
                    type: "success",
                });
                getAppealTypeList();
            })
            .finally(() => {
                loading.value = false;
            });
    });
};

const getAppealTypeList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("bank/mail-type")
        .then(({ data: dataValue }) => {
            data.value = dataValue.content;
            totalElements.value = dataValue.totalElements;
            totalPages.value = dataValue.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    getAppealTypeList();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2>{{ t("menu.AppealTypesList") }}</h2>

            <el-tooltip :content="t('buttons.add')" placement="top">
                <el-button
                    class="add-button"
                    type="primary"
                    @click="router.push({ name: 'AppealTypeFormCreate' })"
                >
                    <el-icon>
                        <IconPlus />
                    </el-icon>
                </el-button>
            </el-tooltip>
        </div>

        <div v-loading="loading">
            <el-table
                :data="data"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column prop="type" :label="t('labels.name')" align="center" />

                <el-table-column
                    prop="order"
                    :label="t('labels.serial_number')"
                    align="center"
                    :width="160"
                />

                <el-table-column :label="t('labels.settings')" align="center" :width="220">
                    <template #default="scope">
                        <el-tooltip :content="t('buttons.edit')" placement="left-start">
                            <el-button
                                type="warning"
                                plain
                                @click="
                                    router.push({
                                        name: 'AppealTypeFormEdit',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEdit05 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content="t('buttons.delete')" placement="right">
                            <el-button
                                type="danger"
                                plain
                                @click.prevent="deleteAppealType(scope.row.id)"
                            >
                                <el-icon>
                                    <IconTrash01 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-4">
                <template v-if="totalElements > 0">
                    <span class="font-semibold">
                        {{ t("labels.total") }}: {{ totalElements }}
                    </span>
                </template>

                <el-pagination
                    :current-page="currentPage"
                    class="pagination"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="totalElements"
                    :page-size="size"
                    :page-count="totalPages"
                    :hide-on-single-page="true"
                    :background="true"
                />
            </div>
        </div>
    </div>
</template>
