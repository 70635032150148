<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`;

const loading = ref(false);
const activeName = ref("uzbek");
const formRef = ref(null);
const id = ref(null);
const form = reactive({
    title: {
        uzLat: null,
        uzCyr: null,
        en: null,
        ru: null,
    },
    description: {
        uzLat: null,
        uzCyr: null,
        en: null,
        ru: null,
    },
    shortDescription: {
        uzLat: null,
        uzCyr: null,
        en: null,
        ru: null,
    },
    image: null,
    categoryId: null,
    link: null,
    versionId: null,
    deviceType: null,
    expression: null,
});
const imageUrl = ref("");

const tabs = ref(["uzbek", "english", "russian", "cyrill"]);
const deviceTypes = ref(["IOS", "ANDROID", "WEB"]);
const categoryTypeList = ref([]);
const versions = reactive({
    content: [],
    loading: false,
    page: 0,
    totalPages: 0,
});
const rule = ref([
    {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
]);

function infinityScroll(event, totalPages, page, loading, callback) {
    const clientH = event.target.clientHeight;
    const scrollTop = event.target.scrollTop;
    const scrollH = event.target.scrollHeight;
    if (Math.ceil(clientH + scrollTop + 1) >= scrollH && page + 1 < totalPages && !loading) {
        callback(page + 1);
    }
}

const uploadFile = (file) => {
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
        const form_data = new FormData();
        form_data.append("file", file.raw);
        console.log(file.raw);
        http.post("file/upload", form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(({ data }) => {
            form.image = data.hashId;
            imageUrl.value = `${baseUrl}/file/download?hashId=${form.image}`;
        });
    } else {
        ElMessage({
            message: t("notifications.only_JPEG_or_PNG_format_image_is_accepted"),
            type: "warning",
        });
    }
};

function getCategoryList() {
    http.get("notification/category").then(({ data }) => {
        categoryTypeList.value = data;
    });
}

function getVersions(page = 0) {
    versions.loading = true;
    http.get("/notification/version", {
        params: {
            deviceType: form.deviceType,
            page,
            size: 8,
        },
    })
        .then(({ data }) => {
            versions.page = page;
            versions.content = [];
            versions.content.push(...data.content);
            versions.totalPages = data.totalPages;
        })
        .finally(() => {
            versions.loading = false;
        });
}

function getVersionsByScroll() {
    document
        .querySelectorAll(".el-select-dropdown .el-select-dropdown__wrap")[3]
        .addEventListener("scroll", (e) => {
            console.log(e);
            const clientH = e.target.clientHeight;
            const scrollTop = e.target.scrollTop;
            const scrollH = e.target.scrollHeight;
            if (
                Math.ceil(clientH + scrollTop) >= scrollH &&
                versions.page >= versions.totalPages &&
                !versions.loading
            ) {
                window.scrollTo({
                    top: 50,
                });
                getVersions(versions.page + 1);
            }
        });
    // infinityScroll(event, versions.totalPages, versions.page, versions.loading, (pageValue) => {
    //     getVersions(pageValue);
    // });
}

function getNotificationById() {
    loading.value = true;
    http.get(`notification/admin/${id.value}`)
        .then(({ data }) => {
            form.title = data.title;
            form.description = data.description;
            form.shortDescription = data.shortDescription;
            form.categoryId = data.categoryId;
            form.image = data.image;
            form.versionId = data.version;
            form.link = data.link;
            form.deviceType = data.deviceType;
            form.expression = data.expression;
            imageUrl.value = `${baseUrl}/file/download?hashId=${form.image}`;
        })
        .finally(() => {
            loading.value = false;
        });
}

function onSubmit(formRef) {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;
            let post;
            if (id.value) {
                post = http.put(`notification/admin/${id.value}`, form);
            } else {
                post = http.post(`notification/admin`, form);
            }
            post.then(() => {
                ElMessage({
                    message: t("notifications.saved_successfully"),
                    type: "success",
                });

                router.go(-1);
            }).finally(() => {
                loading.value = false;
            });
        }
    });
}

onMounted(() => {
    getCategoryList();
    if (route.params.id) {
        id.value = route.params.id;
        getNotificationById();
    }
});
</script>

<template>
    <div class="page-header">
        <h3 class="page-title">
            {{ id ? $t("titles.edit_notification") : $t("titles.add_notification") }}
        </h3>
    </div>
    <div class="main-data" v-loading="loading">
        <el-form ref="formRef" :model="form" label-position="top">
            <el-tabs v-model="activeName" class="demo-tabs">
                <el-tab-pane
                    v-for="(tab, index) in tabs"
                    :label="$t(`langs.${tab}`)"
                    :name="tab"
                    :key="tab"
                >
                    <el-row>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-form-item
                                :rules="rule"
                                :label="$t('labels.name')"
                                :prop="`title.${Object.keys(form.title)[index]}`"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    type="text"
                                    v-model="form.title[Object.keys(form.title)[index]]"
                                    :placeholder="$t('labels.enter_name')"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-form-item
                                :rules="rule"
                                :label="$t('labels.description')"
                                :prop="`description.${Object.keys(form.description)[index]}`"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    type="textarea"
                                    v-model="form.description[Object.keys(form.description)[index]]"
                                    :placeholder="$t('labels.enter_description')"
                                    :rows="3"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-form-item
                                :rules="rule"
                                :label="$t('labels.shortDescription')"
                                :prop="`shortDescription.${
                                    Object.keys(form.shortDescription)[index]
                                }`"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    type="textarea"
                                    v-model="
                                        form.shortDescription[
                                            Object.keys(form.shortDescription)[index]
                                        ]
                                    "
                                    :placeholder="$t('labels.enter_shortDescription')"
                                    :rows="2"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item :rules="rule" :label="$t('labels.category')" prop="categoryId">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>

                        <el-select
                            v-model="form.categoryId"
                            :placeholder="$t('labels.select_category')"
                            size="large"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in categoryTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <template v-if="!id">
                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item :label="$t('labels.deviceType')" prop="deviceType">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>

                            <el-select
                                v-model="form.deviceType"
                                @change="getVersions(0)"
                                :placeholder="$t('labels.select_deviceType')"
                                size="large"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="(item, index) in deviceTypes"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <template v-if="!id">
                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item :label="$t('labels.version')" prop="version">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>
                            <!--                            <el-input-->
                            <!--                                type="text"-->
                            <!--                                v-model="form.version"-->
                            <!--                                :placeholder="$t('labels.version')"-->
                            <!--                                size="large"-->
                            <!--                            />-->

                            <el-select
                                v-model="form.versionId"
                                :loading="versions.loading"
                                :placeholder="$t('labels.version')"
                                size="large"
                                style="width: 100%"
                                @visible-change="getVersionsByScroll"
                            >
                                <el-option
                                    v-for="(item, index) in versions.content"
                                    :key="index"
                                    :label="item.versionName"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>

                <el-col :xs="24" :sm="24" :md="id ? 16 : 12" :lg="id ? 16 : 12" :xl="id ? 16 : 12">
                    <el-form-item :label="$t('labels.link')" prop="link">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.link"
                            :placeholder="$t('labels.link')"
                            size="large"
                        />
                    </el-form-item>
                </el-col>
                <template v-if="!id">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item :label="$t('labels.expression')" prop="">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ $t(error) }}
                                </span>
                            </template>
                            <el-select
                                v-model="form.expression"
                                :placeholder="$t('labels.expression')"
                                size="large"
                                class="w-full"
                            >
                                <el-option
                                    v-for="exp in [
                                        { value: 'EXACT', label: 'EXACT' },
                                        { value: 'LT', label: 'LT' },
                                        { value: 'LTE', label: 'LTE' },
                                        { value: 'GT', label: 'GT' },
                                        { value: 'GTE', label: 'GTE' },
                                    ]"
                                    :value="exp.value"
                                    :label="exp.label"
                                    :key="exp.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <el-col :span="24">
                    <el-form-item :label="$t('labels.image')" prop="">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="uploadFile"
                        >
                            <img v-if="imageUrl" :src="imageUrl" alt="" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item style="margin-top: 10px">
                        <el-button class="custom-btn" @click="() => router.go(-1)">
                            {{ $t("buttons.back") }}
                        </el-button>
                        <el-button class="custom-btn" type="primary" @click="onSubmit(formRef)">
                            {{ $t("buttons.save") }}
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<style scoped>
.avatar-uploader {
    width: 100%;
}

.avatar-uploader:deep(.el-upload) {
    width: 100%;
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader:deep(.el-upload):hover {
    border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

.avatar {
    min-height: 178px;
}
</style>
