<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.typeOperationEdit") }}</h2>

            <el-button @click="router.go(-1)">
                <el-icon class="mr-2">
                    <IconArrowLeft />
                </el-icon>
                {{ t("buttons.back") }}
            </el-button>
        </div>

        <div>
            <el-form ref="formRef" :model="formState" :rules="rules" label-position="top">
                <el-tabs v-model="activeName">
                    <el-tab-pane :label="t('langs.uzbek')" name="uzLat">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <el-form-item :label="t('labels.name')" prop="name.uzLat">
                                    <template #error="{ error }">
                                        <span class="el-form-item__error">
                                            {{ t(error) }}
                                        </span>
                                    </template>
                                    <el-input
                                        v-model="formState.name.uzLat"
                                        type="text"
                                        :placeholder="t('labels.enter_name')"
                                    />
                                </el-form-item>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="t('langs.cyrill')" name="uzCyr">
                        <div>
                            <el-form-item :label="t('labels.name')" prop="name.uzCyr">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="formState.name.uzCyr"
                                    type="text"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="t('langs.english')" name="en">
                        <div>
                            <el-form-item :label="t('labels.name')" prop="name.en">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="formState.name.en"
                                    type="text"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="t('langs.russian')" name="ru">
                        <div>
                            <el-form-item :label="t('labels.name')" prop="name.ru">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="formState.name.ru"
                                    type="text"
                                    :placeholder="t('labels.enter_name')"
                                />
                            </el-form-item>
                        </div>
                    </el-tab-pane>
                </el-tabs>

                <div class="flex items-center justify-end">
                    <el-button type="primary" @click="onSubmit(formRef)">
                        {{ t("buttons.save") }}
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import { ElMessage } from "element-plus";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const formRef = ref();
const formState = ref({
    id: null,
    name: {
        uzLat: null,
        uzCyr: null,
        en: null,
        ru: null,
    },
    active: null,
    tid: null,
});
const rules = ref({
    name: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
        {
            max: 128,
            message: "validations.the_maximum_number_of_characters_is_128",
            trigger: "blur",
        },
    ],
});
const activeName = ref("uzLat");

function getTypeOperation() {
    loading.value = true;
    http.get(`account/type-operations/admin/${route.params.id}`)
        .then(({ data }) => {
            formState.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
}

async function onSubmit(formEl) {
    if (!formEl) return;
    await formEl.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.put(`account/type-operations/admin/${route.params.id}`, {
                ...formState.value,
            })
                .then(({ data }) => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                    getTypeOperation();
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

onMounted(() => {
    getTypeOperation();
});
</script>
