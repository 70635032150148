<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import http from "@/http";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ElMessage } from "element-plus";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const rules = reactive({
    ru: [
        {
            required: true,
            message: "",
            trigger: "change",
        },
    ],
    en: [
        {
            required: true,
            message: "",
            trigger: "change",
        },
    ],
    uzLat: [
        {
            required: true,
            message: "",
            trigger: "change",
        },
    ],
    uzCyr: [
        {
            required: true,
            message: "",
            trigger: "change",
        },
    ],
});

const loading = ref(false);
const items = ref(null);
const item = ref(null);
const id = ref(null);
const formRef = ref();
const formState = reactive({
    text: {
        ru: `Diqqat! Shu bilan siz fuqaro <strong>FULL_NAME</strong>, <strong>PINFL</strong> amaldagi qonunchilikka muvofiq <strong>PERMISSIONS</strong> huquqini berasiz. Shundan so'ng bank ushbu xodim tomonidan bankka yuborilgan to'lov topshiriqnomalarini qabul qiladi, xodim boshqa mijozlarga to'lov talabnomalarini yuboradi, so'rovlar, talabnomalar yuboradi, sizning ruxsatingiz bilan boshqa moddiy boyliklar bilan bog'liq operatsiyalarni hujjat sifatida amalga oshira oladi va operatsiyalarni amalga oshiradi. Eslatib o‘tamiz, Fuqarolik kodeksi va boshqa me’yoriy hujjatlarga muvofiq, yuqorida ko‘rsatilgan tartib-qoidalarning bajarilishi natijasida yuzaga kelishi mumkin bo‘lgan zarar va boshqa huquqbuzarliklar ishonchnoma bergan tashkilot rahbari zimmasiga yuklanadi. Ushbu holatlar bo\`yicha tashkilot rahbarining yoki xodimining noqonuniy harakatiga, harakatsizligi boyicha bank hech qanday moddiy javobgarlikni o'z zimmasiga olmaydi.`,
        en: `Diqqat! Shu bilan siz fuqaro <strong>FULL_NAME</strong>, <strong>PINFL</strong> amaldagi qonunchilikka muvofiq <strong>PERMISSIONS</strong> huquqini berasiz. Shundan so'ng bank ushbu xodim tomonidan bankka yuborilgan to'lov topshiriqnomalarini qabul qiladi, xodim boshqa mijozlarga to'lov talabnomalarini yuboradi, so'rovlar, talabnomalar yuboradi, sizning ruxsatingiz bilan boshqa moddiy boyliklar bilan bog'liq operatsiyalarni hujjat sifatida amalga oshira oladi va operatsiyalarni amalga oshiradi. Eslatib o‘tamiz, Fuqarolik kodeksi va boshqa me’yoriy hujjatlarga muvofiq, yuqorida ko‘rsatilgan tartib-qoidalarning bajarilishi natijasida yuzaga kelishi mumkin bo‘lgan zarar va boshqa huquqbuzarliklar ishonchnoma bergan tashkilot rahbari zimmasiga yuklanadi. Ushbu holatlar bo\`yicha tashkilot rahbarining yoki xodimining noqonuniy harakatiga, harakatsizligi boyicha bank hech qanday moddiy javobgarlikni o'z zimmasiga olmaydi.`,
        uzLat: `Diqqat! Shu bilan siz fuqaro <strong>FULL_NAME</strong>, <strong>PINFL</strong> amaldagi qonunchilikka muvofiq <strong>PERMISSIONS</strong> huquqini berasiz. Shundan so'ng bank ushbu xodim tomonidan bankka yuborilgan to'lov topshiriqnomalarini qabul qiladi, xodim boshqa mijozlarga to'lov talabnomalarini yuboradi, so'rovlar, talabnomalar yuboradi, sizning ruxsatingiz bilan boshqa moddiy boyliklar bilan bog'liq operatsiyalarni hujjat sifatida amalga oshira oladi va operatsiyalarni amalga oshiradi. Eslatib o‘tamiz, Fuqarolik kodeksi va boshqa me’yoriy hujjatlarga muvofiq, yuqorida ko‘rsatilgan tartib-qoidalarning bajarilishi natijasida yuzaga kelishi mumkin bo‘lgan zarar va boshqa huquqbuzarliklar ishonchnoma bergan tashkilot rahbari zimmasiga yuklanadi. Ushbu holatlar bo\`yicha tashkilot rahbarining yoki xodimining noqonuniy harakatiga, harakatsizligi boyicha bank hech qanday moddiy javobgarlikni o'z zimmasiga olmaydi.`,
        uzCyr: `Diqqat! Shu bilan siz fuqaro <strong>FULL_NAME</strong>, <strong>PINFL</strong> amaldagi qonunchilikka muvofiq <strong>PERMISSIONS</strong> huquqini berasiz. Shundan so'ng bank ushbu xodim tomonidan bankka yuborilgan to'lov topshiriqnomalarini qabul qiladi, xodim boshqa mijozlarga to'lov talabnomalarini yuboradi, so'rovlar, talabnomalar yuboradi, sizning ruxsatingiz bilan boshqa moddiy boyliklar bilan bog'liq operatsiyalarni hujjat sifatida amalga oshira oladi va operatsiyalarni amalga oshiradi. Eslatib o‘tamiz, Fuqarolik kodeksi va boshqa me’yoriy hujjatlarga muvofiq, yuqorida ko‘rsatilgan tartib-qoidalarning bajarilishi natijasida yuzaga kelishi mumkin bo‘lgan zarar va boshqa huquqbuzarliklar ishonchnoma bergan tashkilot rahbari zimmasiga yuklanadi. Ushbu holatlar bo\`yicha tashkilot rahbarining yoki xodimining noqonuniy harakatiga, harakatsizligi boyicha bank hech qanday moddiy javobgarlikni o'z zimmasiga olmaydi.`,
    },
    expireTime: 1000000,
});
const editor = ref(ClassicEditor);
const editorConfig = ref({
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "|",
            "|",
            "undo",
            "redo",
        ],
    },
});

const getAllContext = () => {
    loading.value = true;
    http.get("user/power-attorney-template/admin/context")
        .then(({ data }) => {
            items.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const getOne = () => {
    loading.value = true;
    http.get("user/power-attorney-template/admin")
        .then(({ data }) => {
            item.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const submitForm = async (formEl) => {
    await formEl.validate((valid) => {
        if (valid) {
            let request;
            if (id.value) {
                request = http.put(`user/power-attorney-template/admin/${id.value}`, {
                    ...formState,
                });
            } else {
                request = http.post(`user/power-attorney-template/admin`, {
                    ...formState,
                });
            }
            loading.value = true;
            request
                .then(({ data }) => {
                    console.log(data);
                    ElMessage({
                        type: "success",
                        message: t("notifications.saved_successfully"),
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getOne();
    }
    getAllContext();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2>{{ id ? t("titles.editOfferta") : t("titles.addOfferta") }}</h2>

            <el-button @click="router.go(-1)">
                <el-icon class="mr-2">
                    <IconArrowLeft />
                </el-icon>
                {{ t("buttons.back") }}
            </el-button>
        </div>

        <div>
            <el-form :rules="rules" label-position="top" :model="formState" ref="formRef">
                <el-row :gutter="12">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-tabs type="border-card">
                            <el-tab-pane :label="t('langs.uzbek')">
                                <el-form-item props="text.uzLat">
                                    <ckeditor
                                        :editor="editor"
                                        v-model="formState.text.uzLat"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Ўзбек">
                                <el-form-item props="text.uzCyr">
                                    <ckeditor
                                        :editor="editor"
                                        v-model="formState.text.uzCyr"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="English">
                                <el-form-item props="text.en">
                                    <ckeditor
                                        :editor="editor"
                                        v-model="formState.text.en"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Русский">
                                <el-form-item props="text.ru">
                                    <ckeditor
                                        :editor="editor"
                                        v-model="formState.text.ru"
                                        :config="editorConfig"
                                    />
                                </el-form-item>
                            </el-tab-pane>

                            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                <ul class="pl-4">
                                    <template v-for="(item, index) in items" :key="index">
                                        <li class="mb-2">
                                            <span class="font-semibold">
                                                {{ item.context }}: {{ item.name }}
                                            </span>
                                            <p>{{ item.description }}</p>
                                        </li>
                                    </template>
                                </ul>
                            </el-col>

                            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                <div class="flex justify-end">
                                    <el-button
                                        @click="submitForm(formRef)"
                                        type="primary"
                                        size="large"
                                    >
                                        {{ t("buttons.save") }}
                                    </el-button>
                                </div>
                            </el-col>
                        </el-tabs>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>
