<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import http from "@/http";
import { ElMessage, ElMessageBox } from "element-plus";
import { checkPermission } from "@/composables";
import IconFileAttachment04 from "@/components/icons/files/IconFileAttachment04.vue";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const btnApprovedLoading = ref(false);
const btnRejectedLoading = ref(false);
const historyLoading = ref(false);
const id = ref(null);
const oldStatus = ref(null);

const oldAbsStatus = ref(null);
const appealData = ref({
    clientName: null,
    documentType: null,
    payer: {
        account: null,
        mfo: null,
        branchName: null,
        currencyType: null,
        orgName: null,
        uid: null,
        id: null,
    },
    recipient: {
        account: null,
        mfo: null,
        branchName: null,
        currencyType: null,
        orgName: null,
        uid: null,
        id: null,
    },
    purposeCode: null,
    purposeName: null,
    amount: null,
    description: null,
    departureDate: null,
    docNum: null,
    id: null,
    absId: null,
    absStatus: null,
    status: null,
    documentErrorCode: null,
    documentErrorMessage: null,
    controller: null,
});
const appealHistory = ref([]);
const historyActive = ref(false);
const dbusinessStatusList = ["DELETED", "FINISHED", "REJECTED", "ERROR", "ON_APPEAL"];

const absStatusList = ref([]);

const getHistory = () => {
    historyActive.value = !historyActive.value;
    if (historyActive.value) {
        historyLoading.value = true;
        http.get(`account/on-appeal-documents/history/${id.value}`)
            .then((response) => {
                appealHistory.value = response?.data;
            })
            .finally(() => {
                historyLoading.value = false;
            });
    }
};

const getAbsStatusList = () => {
    loading.value = true;
    http.get("account/document/abs-document-status")
        .then(({ data }) => {
            absStatusList.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const getAppealById = (id) => {
    loading.value = true;
    http.get(`account/on-appeal-documents/${id}`)
        .then(({ data }) => {
            appealData.value = data;
            oldStatus.value = data.status;
            oldAbsStatus.value = data.absStatus;
        })
        .finally(() => {
            loading.value = false;
        });
};

const changeDocumentStatusOfDbusiness = (status) => {
    ElMessageBox.confirm(
        t("notifications.do_you_want_to_change_the_status_of_the_document"),
        t("notifications.caution"),
        {
            confirmButtonText: t("buttons.yes"),
            cancelButtonText: t("buttons.no"),
            type: "warning",
        }
    )
        .then(() => {
            loading.value = true;
            let https;
            if (status === "FINISHED") {
                https = http.put(`account/on-appeal-documents/finished/${id.value}`);
            } else if (status === "REJECTED") {
                https = http.put(`account/on-appeal-documents/rejected/${id.value}`);
            } else if (status === "DELETED") {
                https = http.put(`account/on-appeal-documents/deleted/${id.value}`);
            } else if (status === "ERROR") {
                https = http.put(`account/on-appeal-documents/error/${id.value}`);
            }

            if (https) {
                https
                    .then(() => {
                        ElMessage({
                            message: t("notifications.done_successfully"),
                            type: "success",
                        });
                    })
                    .finally(() => {
                        loading.value = false;
                        getAppealById(id.value);
                    });
            }
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.action_canceled"),
                type: "error",
            });
            appealData.value.status = oldStatus.value;
        });
};

const changeDocumentStatusOfAbs = (status) => {
    ElMessageBox.confirm(
        t("notifications.do_you_want_to_change_the_status_of_the_document"),
        t("notifications.caution"),
        {
            confirmButtonText: t("buttons.yes"),
            cancelButtonText: t("buttons.no"),
            type: "warning",
        }
    )
        .then(() => {
            loading.value = false;
            http.put(`account/on-appeal-documents/${id.value}`, {
                status: status,
            })
                .then(() => {
                    ElMessage({
                        message: t("notifications.done_successfully"),
                        type: "success",
                    });
                })
                .finally(() => {
                    loading.value = false;
                    getAppealById(id.value);
                });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.action_canceled"),
                type: "warning",
            });
            appealData.value.absStatus = oldAbsStatus.value;
        });
};

const confirmDocument = () => {
    ElMessageBox.prompt(t("notifications.enter_abs_id"), t("notifications.caution"), {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.no"),
        inputPlaceholder: t("notifications.enter_abs_id"),
        inputValidator: (value) => Boolean(value),
        inputErrorMessage: t("validations.required_field"),
        type: "warning",
    })
        .then(({ value }) => {
            loading.value = true;
            http.put(`account/on-appeal-documents/abs-id/${id.value}`, {
                absId: value,
            })
                .then(() => {
                    ElMessage({
                        message: t("notifications.done_successfully"),
                        type: "success",
                    });
                    if (
                        appealData.value.controller === "MODERATOR" &&
                        checkPermission("DOCUMENT_MODERATOR") &&
                        checkPermission("DOCUMENT_ADMIN")
                    ) {
                        getAppealById(id.value);
                    } else {
                        router.back();
                    }
                })
                .finally(() => {
                    loading.value = false;
                });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.action_canceled"),
                type: "warning",
            });
        });
};

const rejectedDocument = () => {
    ElMessageBox.prompt(
        t("notifications.appeal_reject_reason_message"),
        t("notifications.caution"),
        {
            confirmButtonText: t("buttons.yes"),
            cancelButtonText: t("buttons.no"),
            inputPlaceholder: t("notifications.appeal_reject_reason_message"),
            inputValidator: (value) => {
                if (value) {
                    if (value.length > 5) {
                        return true;
                    }
                }
                return false;
            },
            inputErrorMessage: t("validations.the_reason_should_not_be_less_than_5_characters"),
            type: "warning",
        }
    )
        .then(({ value }) => {
            loading.value = true;
            http.put(`account/on-appeal-documents/error-message/${id.value}`, {
                error: value,
            })
                .then(() => {
                    ElMessage({
                        message: t("notifications.done_successfully"),
                        type: "success",
                    });
                    if (
                        appealData.value.controller === "MODERATOR" &&
                        checkPermission("DOCUMENT_MODERATOR") &&
                        checkPermission("DOCUMENT_ADMIN")
                    ) {
                        getAppealById(id.value);
                    } else {
                        router.back();
                    }
                })
                .finally(() => {
                    loading.value = false;
                });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.action_canceled"),
                type: "warning",
            });
        });
};

const findAbsStatusByKey = (key) => {
    const obj = absStatusList.value.filter((item) => item?.status === key);
    return obj[0]?.localName;
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getAbsStatusList();
        getAppealById(id.value);
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ t("labels.history_of_changes") }}
            </h2>

            <div>
                <el-button @click="() => router.go(-1)">
                    <el-icon>
                        <IconArrowLeft />
                    </el-icon>
                    <span>{{ t("buttons.back") }}</span>
                </el-button>

                <el-popover
                    v-if="checkPermission('DOCUMENT_ADMIN')"
                    placement="bottom-start"
                    :width="500"
                    trigger="click"
                >
                    <template #reference>
                        <el-button type="primary" @click="getHistory">
                            {{ t("buttons.history") }}
                        </el-button>
                    </template>
                    <div v-loading="historyLoading">
                        <ul class="list-style-none flex flex-col gap-y-4">
                            <li
                                v-for="(item, index) in appealHistory"
                                :key="item.id"
                                :class="[
                                    'flex items-center justify-between',
                                    {
                                        'border-b pb-2': index !== appealHistory.length - 1,
                                    },
                                ]"
                            >
                                <div>
                                    <span class="create-date">
                                        {{ dayjs.unix(item?.createdDate).format("DD.MM.YYYY") }}
                                    </span>
                                    <div>
                                        {{ item.user }}
                                    </div>
                                </div>
                                <el-popover placement="left" :width="300" trigger="click">
                                    <template #reference>
                                        <div>
                                            <el-tooltip
                                                effect="light"
                                                :content="t('labels.changes')"
                                                placement="top"
                                            >
                                                <div>
                                                    <el-button link>
                                                        <el-icon size="18px">
                                                            <IconFileAttachment04 />
                                                        </el-icon>
                                                    </el-button>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                    <div class="differences">
                                        <div>
                                            <h5>{{ t("labels.previous") }}:</h5>
                                            {{ item.previousStatus }}
                                        </div>
                                        <div>
                                            <h5>{{ t("labels.current") }}:</h5>
                                            {{ item.currentStatus }}
                                        </div>
                                        <div v-if="item.message">
                                            <h5>{{ t("labels.reason_for_error") }}:</h5>
                                            {{ item.message }}
                                        </div>
                                    </div>
                                </el-popover>
                            </li>
                        </ul>
                    </div>
                </el-popover>
            </div>
        </div>
    </div>

    <div v-loading="loading">
        <el-descriptions
            class="mb-4"
            :title="t('labels.customer_Information')"
            size="default"
            :column="1"
            border
        >
            <el-descriptions-item :label="t('labels.s_n_m')">
                {{ appealData.clientName }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.document_type')">
                {{ appealData.documentType?.typeName }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.document_number')">
                {{ appealData.docNum }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.purpose_code')">
                {{ appealData.purposeCode }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.purpose_name')">
                {{ appealData.purposeName }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.amount')">
                {{ appealData.amount }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.description')">
                {{ appealData.description }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.date')">
                {{ dayjs.unix(appealData.departureDate).format("DD.MM.YYYY") }}
            </el-descriptions-item>

            <el-descriptions-item label="absId" v-if="appealData.absId">
                {{ appealData.absId }}
            </el-descriptions-item>

            <el-descriptions-item
                :label="t('labels.document_status_of_abs')"
                v-if="appealData.absStatus"
            >
                {{ findAbsStatusByKey(appealData.absStatus.status) }}
            </el-descriptions-item>

            <el-descriptions-item :label="t('labels.document_status')" v-if="appealData.status">
                {{ t(`status_list.${appealData.status}`) }}
            </el-descriptions-item>

            <el-descriptions-item
                :label="t('labels.error_code')"
                v-if="appealData.documentErrorCode"
            >
                {{ appealData.documentErrorCode }}
            </el-descriptions-item>

            <el-descriptions-item
                :label="t('labels.reason_for_error')"
                v-if="appealData.documentErrorMessage"
            >
                <div class="truncate">
                    {{ appealData.documentErrorMessage }}
                </div>
            </el-descriptions-item>
        </el-descriptions>

        <el-row :gutter="10" class="mb-4">
            <el-col :span="12">
                <el-descriptions :title="t('labels.sender')" size="small" :column="1" border>
                    <el-descriptions-item>
                        <template #label>
                            <div class="org-name">
                                {{ t("labels.organization_name") }}
                            </div>
                        </template>
                        {{ appealData.payer.orgName }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.account_number')">
                        {{ appealData.payer.account }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.branch_id')">
                        {{ appealData.payer.mfo }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="`${t('labels.i_n_n')}/${t('labels.pinfil')}`">
                        {{ appealData.payer.uid }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.currency_type')">
                        {{ appealData.payer.currencyType }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.branch_name')">
                        {{ appealData.payer.branchName }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>

            <el-col :span="12">
                <el-descriptions :title="t('labels.receiver')" size="small" :column="1" border>
                    <el-descriptions-item>
                        <template #label>
                            <div class="org-name">
                                {{ t("labels.organization_name") }}
                            </div>
                        </template>
                        {{ appealData.recipient.orgName }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.account_number')">
                        {{ appealData.recipient.account }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.branch_id')">
                        {{ appealData.recipient.mfo }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="`${t('labels.i_n_n')}/${t('labels.pinfil')}`">
                        {{ appealData.recipient.uid }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="t('labels.currency_type')">
                        {{ appealData.recipient.currencyType }}
                    </el-descriptions-item>

                    <el-descriptions-item :label="t('labels.branch_name')">
                        {{ appealData.recipient.branchName }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>
        </el-row>

        <el-form label-position="top" v-if="appealData.controller === 'ADMIN'">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item :label="t('labels.document_status')">
                        <el-select
                            v-model="appealData.status"
                            @change="changeDocumentStatusOfDbusiness"
                            class="m-2"
                            :disabled="appealData.status !== 'ON_APPEAL'"
                            :placeholder="t('labels.select_status_type')"
                            size="large"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in dbusinessStatusList"
                                :key="item"
                                :label="t(`status_list.${item}`)"
                                :value="item"
                                :disabled="item === 'ON_APPEAL'"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item :label="t('labels.document_status_of_abs')">
                        <el-select
                            v-model="appealData.absStatus.status"
                            @change="changeDocumentStatusOfAbs"
                            class="m-2"
                            :disabled="appealData.status !== 'ON_APPEAL'"
                            :placeholder="t('labels.select_status_type')"
                            size="large"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in absStatusList"
                                :key="item.status"
                                :label="item.localName"
                                :value="item.status"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="button-group">

            <div
                v-if="
                    checkPermission('DOCUMENT_MODERATOR') && appealData.controller === 'MODERATOR' && appealData?.status==='ON_APPEAL'
                "
            >
                <el-space>
                    <el-button
                        class="confirm_btn"
                        type="danger"
                        @click="rejectedDocument"
                        :loading="btnRejectedLoading"
                        :disabled="btnRejectedLoading || btnApprovedLoading"
                    >
                        <template #default v-if="!btnRejectedLoading">
                            {{ t("buttons.reject") }}
                        </template>
                    </el-button>
                    <el-button
                        class="confirm_btn"
                        type="primary"
                        @click="confirmDocument"
                        :loading="btnApprovedLoading"
                        :disabled="btnRejectedLoading || btnApprovedLoading"
                    >
                        <template #default v-if="!btnApprovedLoading">
                            {{ t("buttons.approve") }}
                        </template>
                    </el-button>
                </el-space>
            </div>
        </div>
    </div>
</template>

<style scoped>
.org-name {
    display: inline-block;
    width: 150px;
}
.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}
.history-title {
    margin-bottom: 4px;
    color: #828582;
}
.history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 0 2px 0 2px;
    border-bottom: 1px solid rgba(163, 166, 173, 0.42);
}
.history-item:hover {
    background-color: #f3ffff;
}
.history-item:nth-child(1) {
    border-top: 1px solid #a3a6ad;
}
.history-btn {
    height: 24px;
    font-size: 20px;
}
.differences {
    min-height: 100px;
}
</style>
