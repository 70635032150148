<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconEye from "@/components/icons/general/IconEye.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";

const { t } = useI18n();
const router = useRouter();

const loading = ref(false);
const loadingRefresh = ref(false);
const currencyData = ref([]);

const refreshCurrencyData = () => {
    loadingRefresh.value = true;
    http.get(`account/currency-type/refresh`)
        .then(() => {
            getCurrencyTypeList();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const getCurrencyTypeList = () => {
    loading.value = true;
    http.get("account/currency-type")
        .then((data) => {
            currencyData.value = data.data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const changeCurrencyTypeStatus = (id) => {
    loading.value = true;
    http.put(`account/currency-type/change-active/${id}`)
        .then(({ data }) => {
            getCurrencyTypeList();
            ElMessage({
                message: t("notifications.currency_changed_to_msg", {
                    msg: t(`currency_status.${data ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_currency_status"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    getCurrencyTypeList();
});
</script>

<template>
    <div>
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ $t("menu.CurrencyTypeList") }}</h2>

            <el-tooltip :content="t('buttons.refresh')" placement="top-start">
                <el-button @click="refreshCurrencyData">
                    <template v-if="loadingRefresh">
                        <el-icon>
                            <IconLoading02 />
                        </el-icon>
                    </template>
                    <template v-else>
                        <el-icon>
                            <IconRefreshCW01 />
                        </el-icon>
                    </template>
                </el-button>
            </el-tooltip>
        </div>

        <div v-loading="loading">
            <el-table
                :data="currencyData"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="$t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" label="№" align="center" />

                <el-table-column prop="name" label="Name" />

                <el-table-column prop="status" :label="$t('labels.status')" align="center">
                    <template #default="scope">
                        <el-space>
                            <el-switch
                                v-model="scope.row.active"
                                @change="changeCurrencyTypeStatus(scope.row.id)"
                                style="
                                    --el-switch-on-color: #13ce66;
                                    --el-switch-off-color: #ff4949;
                                "
                            />
                        </el-space>
                    </template>
                </el-table-column>

                <el-table-column prop="codeNumber" :label="$t('labels.actions')" align="center">
                    <template #default="scope">
                        <el-tooltip :content="t('buttons.edit')" placement="left-start">
                            <el-button
                                type="warning"
                                plain
                                @click="
                                    router.push({
                                        name: 'CurrencyTypeForm',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEdit05 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            :content="t('buttons.view')"
                            placement="right-end"
                            effect="dark"
                        >
                            <el-button
                                type="primary"
                                plain
                                @click="
                                    router.push({
                                        name: 'CurrencyRateView',
                                        params: {
                                            by: 'type',
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEye />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
