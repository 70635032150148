<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import http from "@/http";
import { ElMessage, useId } from "element-plus";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const versionPattern = RegExp(/^V_\d+_\d+_\d+$/);
const loading = ref(false);
const loadingPage = ref(false);
const formRef = ref();
const form = reactive({
    deviceType: null,
    versionName: null,
});
const rules = ref({
    deviceType: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    versionName: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
        {
            validator: (_rule, value, callback) => {
                if (!versionPattern.test(value)) {
                    callback(new Error("validations.invalid_version"));
                } else {
                    callback();
                }
            },
            trigger: "blur",
        },
    ],
});

const options = ref(["IOS", "ANDROID", "WEB"]);

function getVersionById(id) {
    loadingPage.value = true;
    http.get(`/notification/version/${id}`)
        .then(({ data }) => {
            form.deviceType = data.deviceType;
            form.versionName = data.versionName;
        })
        .finally(() => {
            loadingPage.value = false;
        });
}

function submit() {
    formRef.value.validate((valid) => {
        if (valid) {
            let https;
            loading.value = true;
            if (route.params.id) {
                https = http.put(`/notification/version/${route.params.id}`, {
                    ...form,
                });
            } else {
                https = http.post(`/notification/version`, {
                    ...form,
                });
            }
            https
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

onMounted(() => {
    if (route.params.id) {
        getVersionById(route.params.id);
    }
});
</script>

<template>
    <div class="page-header flex justify-between items-center mb-4">
        <h3 class="page-title">
            {{ route.params.id ? $t("titles.edit_version") : $t("titles.add_version") }}
        </h3>
    </div>
    <div v-loading="loadingPage">
        <el-form :model="form" :rules="rules" ref="formRef" label-position="top">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.device_type')" prop="deviceType">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-select
                            v-model="form.deviceType"
                            :placeholder="$t('labels.device_type')"
                            size="large"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="(item, index) in options"
                                :key="index"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('labels.version')" prop="versionName">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            :placeholder="$t('labels.version')"
                            v-model="form.versionName"
                            type="text"
                        />
                    </el-form-item>
                </el-col>
                <el-col>
                    <div class="flex justify-end items-center gap-2">
                        <el-button size="large" @click="router.back()">
                            {{ $t("buttons.back") }}
                        </el-button>
                        <el-button @click="submit" :loading="loading" type="primary" size="large">
                            {{ $t("buttons.save") }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<style scoped></style>
