<script setup>
import { computed, onMounted, ref } from "vue";
import http from "@/http";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const loading = ref(false);
const versionList = ref([]);
const options = ref(["IOS", "ANDROID", "WEB"]);

const size = ref(10);
const page = ref(route.query.page ?? 0);
const totalElements = ref(0);
const totalPages = ref(0);
const filterDeviceType = ref(route.query.deviceType || null);

const currentPage = computed(() => (route?.query?.page ? parseInt(route?.query?.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (route.query.page - 1) + 1;
    } else {
        return index + 1;
    }
};
const handleCurrentChange = (val) => {
    router
        .replace({
            query: { page: val },
        })
        .then(() => {
            getVersions(val - 1);
        });
};

function getVersions(pageValue = 0) {
    loading.value = true;
    http.get("/notification/version", {
        params: {
            deviceType: filterDeviceType.value,
            page: pageValue,
            size: 10,
        },
    })
        .then(({ data }) => {
            versionList.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
            page.value = pageValue;
        })
        .finally(() => {
            loading.value = false;
        });
}

function edit(id) {
    router.push({ name: "VersionFormEdit", params: { id: id } });
}

function deleteVersion(id) {
    loading.value = true;
    http.delete(`/notification/version/${id}`)
        .then(() => {
            getVersions(currentPage.value - 1);
        })
        .finally(() => {
            loading.value = false;
        });
}

function filterVersions() {
    router.push({
        query: {
            deviceType: filterDeviceType.value,
        },
    });
    getVersions(0);
}

onMounted(() => {
    getVersions(currentPage.value - 1);
});
</script>

<template>
    <div class="page-header flex justify-between items-center mb-4">
        <h3 class="page-title">{{ $t("menu.VersionList") }}</h3>
        <div class="actions demo-input-suffix flex justify-end gap-2">
            <el-select
                @change="filterVersions"
                v-model="filterDeviceType"
                :placeholder="$t('labels.device_type')"
                style="width: 100%"
                :clearable="true"
            >
                <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item"
                    :value="item"
                />
            </el-select>
            <el-button
                class="add-button"
                type="primary"
                @click="$router.push({ name: 'VersionFormCreate' })"
            >
                + {{ $t("buttons.add") }}
            </el-button>
        </div>
    </div>
    <div class="main-data" v-loading="loading">
        <el-table
            :data="versionList"
            stripe
            class-name="table-custom-cls"
            style="width: 100%"
            height="calc(100vh - 230px)"
            table-layout="fixed"
            border
        >
            <el-table-column
                type="index"
                label="№"
                :index="indexMethod"
                width="75"
                align="center"
            />
            <el-table-column prop="deviceType" :label="$t('labels.device_type')" />
            <el-table-column prop="versionName" :label="$t('labels.version')" />
            <el-table-column prop="actions" width="125px" :label="$t('labels.actions')">
                <template #default="scope">
                    <div class="flex justify-start !gap-2">
                        <el-button @click="edit(scope.row.id)">
                            <template #icon>
                                <IconEdit05 />
                            </template>
                        </el-button>

                        <el-popconfirm
                            @confirm="deleteVersion(scope.row.id)"
                            :title="$t('notifications.do_you_want_to_delete_the_version')"
                            :cancel-button-text="$t('buttons.no')"
                            :confirm-button-text="$t('buttons.yes')"
                        >
                            <template #reference>
                                <el-button class="!m-0" type="danger">
                                    <template #icon>
                                        <IconTrash01 />
                                    </template>
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-row class="mt-2 items-center">
            <el-col :span="12">
                <span class="font-medium" v-if="totalElements"
                    >{{ $t("labels.total") }}: {{ totalElements }}
                </span>
            </el-col>
            <el-col :span="12">
                <div class="pagination-wrapper">
                    <el-pagination
                        :current-page="currentPage"
                        class="pagination"
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next"
                        :total="totalElements"
                        :page-size="size"
                        :page-count="totalPages"
                        :hide-on-single-page="true"
                        :background="true"
                    >
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>
.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
}

.pagination-wrapper > span {
    color: #0a194e;
    font-family: "Montserrat-Bold", sans-serif;
}
</style>
