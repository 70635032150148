<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconEye from "@/components/icons/general/IconEye.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const loadingRefresh = ref(false);
const debts = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);
const selectType = ref(route.query.type ? route.query.type : "DEBT");

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const getAllDebtPaymentCode = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get(`account/debt-payment-code/admin`, {
        params: {
            size: size.value,
            page: currentPage.value,
            type: selectType.value,
        },
    })
        .then(({ data }) => {
            debts.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

const refreshData = () => {
    loadingRefresh.value = true;
    http.get(`account/debt-payment-code/refresh/?type=${selectType.value}`)
        .then(() => {
            getAllDebtPaymentCode();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const changeDebtStatus = (row) => {
    loading.value = true;
    http.put(`account/debt-payment-code/change-status/${row.id}`)
        .then(({ data }) => {
            getAllDebtPaymentCode();
            ElMessage({
                message: t(
                    `${
                        route.query.type === ""
                            ? "notifications.debt_changed_to_msg"
                            : route.query.type === "PAYMENT"
                              ? "notifications.currency_changed_to_msg"
                              : "notifications.debt_changed_to_msg"
                    }`,
                    {
                        msg: t(`currency_status.${data ? "ACTIVE" : "INACTIVE"}`),
                    }
                ),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_currency_status"),
                type: "warning",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const handleSelectType = () => {
    router.push({
        query: {
            type: selectType.value,
        },
    });
    getAllDebtPaymentCode();
};

watch(
    () => currentPage.value,
    () => {
        getAllDebtPaymentCode();
    }
);

onMounted(() => {
    getAllDebtPaymentCode();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{
                    route.query.type === ""
                        ? t("titles.debtTypes")
                        : route.query.type === "PAYMENT"
                          ? t("titles.paymentTypes")
                          : t("titles.debtTypes")
                }}
            </h2>

            <div class="flex items-center gap-x-4">
                <el-select v-model="selectType" @change="handleSelectType">
                    <el-option
                        v-for="option in [
                            {
                                value: 'DEBT',
                                label: t('titles.debts'),
                            },
                            {
                                value: 'PAYMENT',
                                label: t('titles.payments'),
                            },
                        ]"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    />
                </el-select>

                <el-tooltip :content="t('buttons.refresh')" placement="top">
                    <el-button @click="refreshData" :loading="loadingRefresh">
                        <template v-if="!loadingRefresh">
                            <el-icon>
                                <IconRefreshCW01 />
                            </el-icon>
                        </template>
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <el-table
            :data="debts"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
            border
            v-loading="loading"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" />

            <el-table-column prop="code" :label="t('labels.code')" align="center" :width="160" />

            <el-table-column prop="active" :label="t('labels.active')" align="center" :width="160">
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.active"
                        @change="changeDebtStatus(scope.row)"
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                    />
                </template>
            </el-table-column>

            <el-table-column :label="t('labels.actions')" align="center" :width="160">
                <template #default="scope">
                    <el-tooltip :content="t('buttons.edit')" placement="left-start">
                        <el-button
                            type="warning"
                            plain
                            @click="
                                router.push({
                                    name: 'DebtPaymentCodeEdit',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEdit05 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>

                    <el-tooltip :content="t('buttons.view')" placement="top-start" effect="dark">
                        <el-button
                            type="primary"
                            plain
                            @click="
                                router.push({
                                    name: 'DebtPaymentCodeId',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEye />
                            </el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>
