<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Picture, UploadFilled } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
import http from '@/http'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`

const loading = ref(false)
const activeName = ref('uzbek')
const formRef = ref(null)
const id = ref(null)

const form = reactive({
  name: {
    uzLat: null,
    uzCyr: null,
    en: null,
    ru: null
  },
  description: {
    uzLat: null,
    uzCyr: null,
    en: null,
    ru: null
  },
  image: null,
  key: null
})

const imageUrl = ref('')
const tabs = ref(['uzbek', 'english', 'russian', 'cyrill'])

const rule = ref([
  {
    required: true,
    message: 'validations.required_field',
    trigger: 'blur'
  }
])

const getCategoryBy = () => {
  loading.value = true
  http
    .get(`notification/category/${id.value}`)
    .then(({ data }) => {
      form.name = data.name
      form.description = data.description
      form.image = data.image
      form.key = data.key
    })
    .finally(() => {
      loading.value = false
    })
}
const uploadFile = (file) => {
  if (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png') {
    const form_data = new FormData()
    form_data.append('file', file.raw)
    http
      .post('file/upload', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        imageUrl.value = URL.createObjectURL(file.raw)
        form.image = data.hashId
      })
  } else {
    ElMessage({
      message: t('notifications.only_JPEG_or_PNG_format_image_is_accepted'),
      type: 'warning'
    })
  }
}
const onSubmit = (formRef) => {
  formRef.validate((valid) => {
    if (valid) {
      loading.value = true
      let post
      if (id.value) {
        post = http.put(`notification/category/${id.value}`, form)
      } else {
        post = http.post(`notification/category`, form)
      }
      post
        .then(() => {
          ElMessage({
            message: t('notifications.saved_successfully'),
            type: 'success'
          })

          router.go(-1)
        })
        .finally(() => {
          loading.value = false
        })
    }
  })
}

onMounted(() => {
  if (route.params.id) {
    id.value = route.params.id
    getCategoryBy()
  }
})
</script>

<template>
  <div class="page-header">
    <h3 class="page-title">
      {{ id ? $t('titles.edit_category') : $t('titles.add_category') }}
    </h3>
  </div>
  <div class="main-data" v-loading="loading">
    <el-form ref="formRef" :model="form" label-position="top">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane
          v-for="(tab, index) in tabs"
          :label="$t(`langs.${tab}`)"
          :name="tab"
          :key="tab"
        >
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item
                :rules="rule"
                :label="$t('labels.name')"
                :prop="`name.${Object.keys(form.name)[index]}`"
              >
                <template #error="{ error }">
                  <span class="el-form-item__error">
                    {{ $t(error) }}
                  </span>
                </template>
                <el-input
                  type="text"
                  v-model="form.name[Object.keys(form.name)[index]]"
                  :placeholder="$t('labels.enter_name')"
                  size="large"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item
                :rules="rule"
                :label="$t('labels.description')"
                :prop="`description.${Object.keys(form.description)[index]}`"
              >
                <template #error="{ error }">
                  <span class="el-form-item__error">
                    {{ $t(error) }}
                  </span>
                </template>
                <el-input
                  type="textarea"
                  v-model="
                    form.description[Object.keys(form.description)[index]]
                  "
                  :placeholder="$t('labels.enter_description')"
                  :rows="3"
                  size="large"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <el-row>
        <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
          <el-form-item :rules="rule" :label="$t('labels.key')" prop="key">
            <template #error="{ error }">
              <span class="el-form-item__error">
                {{ $t(error) }}
              </span>
            </template>
            <el-input
              type="text"
              v-model="form.key"
              :placeholder="$t('labels.enter_key')"
              size="large"
              style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item :rules="rule" :label="$t('labels.image')" prop="image">
            <template #error="{ error }">
              <span class="el-form-item__error">
                {{ $t(error) }}
              </span>
            </template>

            <el-upload
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploadFile"
            >
              <el-button class="upload-btn" type="info" plain size="large">
                <el-icon>
                  <UploadFilled />
                </el-icon>
                <span>Click to upload</span>
              </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col v-if="imageUrl || form.image" :span="24">
          <el-image
            :src="imageUrl || `${baseUrl}/file/download?hashId=${form.image}`"
            style="width: 100px; height: 100px"
          >
            <template #error>
              <div class="image-slot">
                <el-icon><Picture /></el-icon>
              </div>
            </template>
          </el-image>
        </el-col>
        <el-col :span="24">
          <el-form-item style="margin-top: 10px">
            <el-button class="custom-btn" @click="() => router.go(-1)">
              {{ $t('buttons.back') }}
            </el-button>
            <el-button
              class="custom-btn"
              type="primary"
              @click="onSubmit(formRef)"
            >
              {{ $t('buttons.save') }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>
.upload-btn {
  background: var(--el-fill-color-light);
  border-color: #e4e7ed;
  color: #6e6e6e;
}
.upload-btn:hover {
  background: var(--el-fill-color-light);
  border-color: #e4e7ed;
  color: #000000;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
</style>
