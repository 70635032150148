<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.editPassword") }}</h2>

            <el-button @click="router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <div>
            <div class="user-info">
                <span class="info-key">{{ t("labels.u_name") }}:</span>
                {{ user?.name }}
            </div>
            <div class="user-info">
                <span class="info-key">{{ t("labels.username") }}:</span>
                {{ user?.username }}
            </div>
            <div class="user-info">
                <span class="info-key">{{ t("labels.role") }}:</span>
                <el-space v-if="user?.role">
                    <el-tag round>{{ user?.role?.name }}</el-tag>
                </el-space>
            </div>

            <el-form :rules="rules" ref="formRef" :model="formState" label-position="top">
                <el-form-item :label="t('labels.newPassword')" prop="password">
                    <el-input
                        size="large"
                        class="form-input"
                        :placeholder="t('labels.enterNewPassword')"
                        v-model="formState.password"
                        type="text"
                    />
                </el-form-item>

                <div class="flex items-center justify-end">
                    <el-button type="primary" @click="onSubmit">
                        {{ t("buttons.save") }}
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const loading = ref(false);
const user = ref();
const formRef = ref();
const formState = ref({
    password: "",
});

const validatePass = (rule, value, callback) => {
    if (!/[A-Z]/.test(value)) {
        callback(new Error(t("labels.atLeast1CapitalLetter")));
    }
    if (value.length < 8) {
        callback(new Error(t("labels.atLeast8Chars")));
    }
    if (!/[0-9]/.test(value)) {
        callback(new Error(t("labels.atLeast1Digit")));
    }
    if (!/[#?!@$%^&*-]/.test(value)) {
        callback(new Error(t("labels.atLeast1AdditionalChar")));
    }
    if (!/[a-z]/.test(value)) {
        callback(new Error(t("labels.atLeast1LowercaseLetter")));
    }
    callback();
};

const rules = ref({
    password: [
        {
            required: true,
            trigger: "change",
            validator: validatePass,
        },
    ],
});

function getUser(id) {
    loading.value = true;
    http.get(`user/admin/${id}`)
        .then(({ data }) => {
            user.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
}

function onSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.put(`user/admin/password/${user.value.id}`, formState.value)
                .then(() => {
                    ElMessage({
                        message: "Muvaffaqiyatli saqlandi",
                        type: "success",
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    errors.value = data.fields;
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

onMounted(() => {
    if (route.params.id) {
        getUser(route.params.id);
    }
});
</script>

<style scoped>
.main-data {
    background-color: #ffffff;
    padding: 20px 20px 0;
    border-radius: 10px;
    height: calc(100vh - 170px);
}

.el-select:deep(.el-input__wrapper) {
    min-height: 40px;
}

.custom-btn {
    height: 40px;
    border-radius: 5px;
    padding: 0 20px;
}

.user-info {
    margin: 10px;
    font-weight: bold;
    display: flex;
}

.info-key {
    color: #888585;
    font-weight: normal;
    margin-right: 10px;
    min-width: 150px;
    display: block;
}
</style>
