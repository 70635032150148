<script setup>
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const {t} = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore()

const loading = ref(false);

const totalElements = computed(()=>store.state.userBranches.length);
const size = ref(15);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const tableData = computed(()=>{
  return store.state.userBranches.slice(size.value * (currentPage.value - 1), size.value * (currentPage.value - 1) + size.value)
})

const selectedBranch = computed(() => {
  const bSession = store.state.bSession
  let value = null
  if (bSession) {
    value = {
      mfo: bSession.mfo,
      name: bSession.name.uzLat,
      head: bSession.head
    }
  }

  return value
})

const indexMethod = (index) => {
  if (totalElements.value / size.value > 1 && currentPage.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

const handleCurrentChange = (value) => {
  router.push({
    query: {page: value},
  });
};

const handleSelectBranch = (row) => {
  loading.value = true
  store.dispatch('selectBranchSession', row.mfo).then(()=>{

    window.location.reload()
  }).finally(()=>loading.value = false)
}

</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("titles.myBranches") }}</h2>
    </div>


    <div v-loading="loading">
      <el-table
          :data="tableData"
          :header-cell-style="{ color: '#111', textAlign: 'center' }"
          :empty-text="t('notifications.information_not_available')"
          border
          height="calc(100vh - 250px)"
      >
        <el-table-column width="80" type="index" :index="indexMethod" label="&#8470;" align="center"/>

        <el-table-column prop="name" :label="t('labels.name')" align="center"/>

        <el-table-column prop="mfo" :label="t('labels.branch_id')" align="center"/>

        <el-table-column :label="t('labels.actions')" align="center" width="120">
          <template #default="scope">
            <el-tag type="success" v-if="selectedBranch?.mfo === scope.row.mfo">
              Tanlangan
            </el-tag>
            <el-button type="primary" @click="handleSelectBranch(scope.row)" size="small" v-else>Tanlash</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex items-center justify-between mt-4">
        <template v-if="totalElements">
          <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
        </template>

        <el-pagination
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="totalElements"
            :page-size="size"
            :hide-on-single-page="true"
            :background="true"
        />
      </div>
    </div>
  </div>
</template>
