<script setup>
import ChatField from "@/views/chat/ChatField.vue";
import ChatList from "@/views/chat/ChatList.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const chatId = computed(() => route.params.chatId || 0);
</script>

<template>
    <div class="chat-wrap">
        <div class="chat-view">
            <chat-field :key="chatId" />
            <chat-list />
        </div>
    </div>
</template>

<style scoped>
.page-title {
    margin-bottom: 0px;
}
.chat-wrap {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 135px);
}
.chat-view {
    display: flex;
    flex-grow: 1;
    height: min-content;
    overflow-y: auto;
}
</style>
