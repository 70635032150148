<script setup>
import {UploadFilled} from "@element-plus/icons-vue";
import http from "@/http";
import {ElMessage} from "element-plus";
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import CloseIcon from "@/assets/images/icon-components/CloseIcon.vue";

const props = defineProps({
  modelValue: {
    required: true
  },
  info: {
    type: Object
  }
})

const {t} = useI18n();
const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`;

const uploadLoading = ref(false);
const fileInfo = ref();

const uploadFile = (file) => {
  console.log(file);
  if (file.size <= 20 * 1024 * 1024) {
    uploadLoading.value = true;
    const form_data = new FormData();
    form_data.append("file", file.raw);
    http.post("file/upload", form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
        .then(({data}) => {
          fileInfo.value = file;
          emit('update:modelValue', data.hashId)
        })
        .finally(() => {
          uploadLoading.value = false;
        });
  } else {
    ElMessage({
      message: t("notifications.fileSize"),
      type: "warning",
    });
  }
};
const closeFile = () => {
  emit('update:modelValue', null)
  fileInfo.value = null;
};

const emit = defineEmits(['update:modelValue'])
</script>

<template>
  <template v-if="modelValue">
    <div class="flex items-center">
      <a
          class="underline mr-2"
          :href="`${baseUrl}/file/download?hashId=${modelValue}`"
          download
          target="_blank"
          rel="opener"
      >
        {{ fileInfo?.name || info?.name }}
      </a>
      <el-button size="small" @click="closeFile">
        <template #icon>
          <close-icon/>
        </template>
      </el-button>
    </div>
  </template>
  <template v-else>
  <el-upload
      action="#"
      :auto-upload="false"
      :show-file-list="false"
      :multiple="false"
      :size="1024"
      :on-change="uploadFile"
      accept=".pdf,.doc,.docx,.xls,.xlsx,.png"
  >
    <el-button
        :loading="uploadLoading"
        class="upload-btn"
        type="info"
        plain
        size="large"
    >
      <el-icon>
        <UploadFilled/>
      </el-icon>
      <span>{{ t("placeholders.input.fileUpload") }}</span>
    </el-button>
  </el-upload>
  </template>
</template>

<style scoped>

</style>