<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconSearchMd from "@/components/icons/general/IconSearchMd.vue";
import { ElMessage } from "element-plus";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const timeout = ref(null);
const loading = ref(false);
const tableData = ref([]);
const selectedStatus = ref(route.query.status);
const searchedValue = ref("");
const statuses = ref([
    {
        label: t("statuses.created"),
        value: "CREATED",
    },
    {
        label: t("statuses.synchronized"),
        value: "SYNCHRONIZED",
    },
    {
        label: t("statuses.error"),
        value: "ERROR",
    },
]);
const totalElements = ref(0);
const totalPages = ref(0);
const page = ref(0);
const size = ref(10);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value, size: size.value },
    });
};

const handleSearch = () => {
    clearTimeout(timeout.value);

    if (searchedValue.value) {
        router.push({
            query: {
                page: 0,
                size: size.value,
                search: searchedValue.value,
            },
        });
    } else {
        router.push({
            query: {
                page: currentPage.value,
                size: size.value,
            },
        });
    }

    timeout.value = setTimeout(() => {
        getAll();
    }, 1000);
};

const handleSelectedStatus = (value) => {
    router.push({
        query: {
            status: value,
            page: 0
        },
    });
    getAll();
};

const getAll = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/admin-client/filters", {
        params: {
            page: page.value,
            size: size.value,
            status: selectedStatus.value,
            search: searchedValue.value,
        },
    })
        .then(({ data }) => {
            tableData.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getAll();
    }
);

onMounted(() => {
    getAll();
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.clients") }}</h2>

            <div class="flex items-center gap-x-2">
                <el-select
                    style="width: 240px"
                    :clearable="true"
                    v-model="selectedStatus"
                    @change="handleSelectedStatus"
                    :placeholder="t('placeholders.select.selectStatus')"
                >
                    <el-option
                        v-for="status in statuses"
                        :label="status.label"
                        :value="status.value"
                        :key="`clientStatus${status.value}`"
                    />
                </el-select>

                <div class="max-w-[240px]">
                    <el-input
                        type="text"
                        @keyup="handleSearch"
                        v-model="searchedValue"
                        :placeholder="t('placeholders.input.searchClients')"
                    >
                        <template #prefix>
                            <el-icon size="18px">
                                <IconSearchMd />
                            </el-icon>
                        </template>
                    </el-input>
                </div>
            </div>
        </div>

        <el-table
            border
            :data="tableData"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('table.name')" align="center" />

            <el-table-column prop="ownerPinfl" :label="t('table.ownerPINFL')" align="center" />

            <el-table-column prop="uid" :label="t('table.userId')" align="center" />

            <el-table-column :label="t('table.status')" align="center">
                <template #default="scope">
                    <el-tag class="text-[11px] p-1">{{ scope.row.status.statusName }}</el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="absUid" :label="t('table.absUserId')" align="center" />

            <el-table-column :label="t('labels.actions')" align="center" width="280">
                <template #default="scope">
                    <div class="flex justify-center items-center gap-x-2">
                        <el-button
                            class="text-xs"
                            size="small"
                            type="primary"
                            @click="
                                router.push({
                                    name: 'ClientBranchesView',
                                    params: {
                                        clientId: scope.row.id,
                                    },
                                })
                            "
                        >
                            {{ t("buttons.branches") }}
                        </el-button>

                        <el-button
                            class="text-xs"
                            size="small"
                            type="primary"
                            @click="
                                router.push({
                                    name: 'ClientAccountsView',
                                    params: {
                                        clientId: scope.row.id,
                                    },
                                })
                            "
                        >
                            {{ t("buttons.accountNumber") }}
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :background="true"
            />
        </div>
    </div>
</template>
