<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const formRef = ref();
const loading = ref(false);
const id = ref(null);
const form = reactive({
    name: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
});

const rules = ref({
    "name.uzLat": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    "name.uzCyr": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    "name.ru": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
    "name.en": {
        required: true,
        message: "validations.required_field",
        trigger: "blur",
    },
});

const getRegionOrDistrictById = (id) => {
    loading.value = true;
    http.get(`account/account-name/${id}`)
        .then(({ data }) => {
            form.name = data?.name;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.put(`account/account-name/${id.value}`, form)
                .then(() => {
                    ElMessage({
                        message: t("notifications.done_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getRegionOrDistrictById(id.value);
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.edit_account") }}</h2>

            <el-button @click="() => router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form
            ref="formRef"
            :model="form"
            :rules="rules"
            label-position="top"
            v-loading="loading"
        >
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.uzbek')})`"
                        prop="name.uzLat"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.name.uzLat"
                            :placeholder="t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.cyrill')})`"
                        prop="name.uzCyr"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.name.uzCyr"
                            :placeholder="t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.russian')})`"
                        prop="name.ru"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.name.ru"
                            :placeholder="t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.english')})`"
                        prop="name.en"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="form.name.en"
                            :placeholder="t('labels.enter_name')"
                            size="large"
                        />
                    </el-form-item>
                </div>
            </div>

            <div class="flex items-center justify-end">
                <el-button type="primary" @click="onSubmit(formRef)">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
