import UserList from "@/views/user/UserList";
import UserForm from "@/views/user/UserForm";
import UpdateUserPermissions from "@/views/user/permission/UpdateUserPermissions";
import ChangeUserPassword from "@/views/user/ChangeUserPassword";
import UserOrganizations from "@/views/user/organizations/UserOrganizations";
import UserOrganizationForm from "@/views/user/organizations/UserOrganizationForm";
import BankFormAdd from "@/views/banks/BankFormAdd";
import PurposeCode from "@/views/banks/purposeCode/PurposeCodeList.vue";
import PurposeCodeForm from "@/views/banks/purposeCode/PurposeCodeForm.vue";
import BranchList from "@/views/banks/branches/BranchList";
import BranchForm from "@/views/banks/branches/BranchForm";
import FaqList from "@/views/help/FaqList.vue";
import FaqCategoryList from "@/views/help/category/FaqCategoryList.vue";
import FaqCategoryForm from "@/views/help/category/FaqCategoryForm.vue";
import FaqForm from "@/views/help/FaqForm.vue";
import ChatView from "@/views/chat/ChatView.vue";
import CatalogMain from "@/views/banks/catalog/CatalogMain.vue";
import PermissionList from "@/views/banks/catalog/Permissions/PermissionList";
import PermissionGroup from "@/views/banks/catalog/PermissionGroup/PermissionGroup";
import RoleList from "@/views/banks/catalog/Role/RoleList";
import PermissionFormMain from "@/views/banks/catalog/Permissions/PermissionFormMain.vue";
import PermissionGroupForm from "@/views/banks/catalog/PermissionGroup/PermissionGroupForm.vue";
import RoleFormMain from "@/views/banks/catalog/Role/RoleFormMain.vue";
import CurrencyMain from "@/views/banks/currency/CurrencyMain.vue";
import CurrencyCodeList from "@/views/banks/currency/currencyCode/CurrencyCodeList.vue";
import CurrencyCodeForm from "@/views/banks/currency/currencyCode/CurrencyCodeForm.vue";
import CurrencyTypeList from "@/views/banks/currency/currencyType/CurrencyTypeList.vue";
import CurrencyRateList from "@/views/banks/currency/CurrencyRate/CurrencyRateList.vue";
import CurrencyTypeForm from "@/views/banks/currency/currencyType/CurrencyTypeForm.vue";
import CurrencyRateView from "@/views/banks/currency/CurrencyRate/CurrencyRateView.vue";
import UzbBankList from "@/views/banks/uzbBank/UzbBankList.vue";
import UzbBankBranches from "@/views/banks/uzbBank/UzbBankBranches/UzbBankBranchList.vue";
import UzbBankEditForm from "@/views/banks/uzbBank/UzbBankEditForm.vue";
import UzbBankBranchEditForm from "@/views/banks/uzbBank/UzbBankBranches/UzbBankBranchEditForm.vue";
import OrganizationEmployeeList from "@/views/user/organizations/OrganizationEmployees/OrganizationEmployeeList.vue";
import CombineOrganizationToUser from "@/views/user/organizations/OrganizationEmployees/CombineOrganizationToUser.vue";
import RegionsList from "@/views/banks/catalog/regions/RegionsList.vue";
import DistrictsList from "@/views/banks/catalog/regions/DistrictsList.vue";
import RegionsAndDistrictEditForm from "@/views/banks/catalog/regions/RegionsAndDistrictEditForm.vue";
import MainAccount from "@/views/account/MainAccount.vue";
import AccountsList from "@/views/account/AccountName/AccountsList.vue";
import AccountNameForm from "@/views/account/AccountName/AccountNameForm.vue";
import AccountCreate from "@/views/account/AccountName/AccountCreate.vue";
import OnAppealList from "@/views/appeals/OnAppeal/OnAppealList.vue";
import OnAppealForm from "@/views/appeals/OnAppeal/OnAppealForm.vue";
import AppealTypesList from "@/views/banks/catalog/AppealTypes/AppealTypesList.vue";
import AppealTypeForm from "@/views/banks/catalog/AppealTypes/AppealTypeForm.vue";
import NotificationsList from "@/views/notifications/NotificationsList.vue";
import NotificationForm from "@/views/notifications/NotificationForm.vue";
import CategoryList from "@/views/notifications/category/CategoryList.vue";
import CategoryForm from "@/views/notifications/category/CategoryForm.vue";
import DebtPaymentCodeAll from "@/views/account/debt-payment-code/all/DebtPaymentCodeAll.vue";
import DebtPaymentCodeId from "@/views/account/debt-payment-code/[id]/DebtPaymentCodeId.vue";
import DebtPaymentCodeEdit from "@/views/account/debt-payment-code/edit/DebtPaymentCodeEdit.vue";
import OffertaFormView from "@/views/user/offerta/OffertaFormView.vue";
import OffertaView from "@/views/user/offerta/OffertaView.vue";
import AbsClients from "@/views/appeals/absClients/AbsClients.vue";
import AbsClient from "@/views/appeals/absClients/AbsClient.vue";

// SWIFT
import SwiftView from "@/views/account/swift/SwiftView.vue";
import CommissionsView from "@/views/account/swift/commissions/CommissionsView.vue";
import TypeOperationsView from "@/views/account/swift/type-operations/TypeOperationsView.vue";
import TypeOperationEditView from "@/views/account/swift/type-operations/TypeOperationEditView.vue";
import PaymentTermsView from "@/views/account/swift/payment-terms/paymentTermsView.vue";
import PaymentTermsFormView from "@/views/account/swift/payment-terms/PaymentTermsFormView.vue";
import CountryCodesView from "@/views/account/swift/country-codes/CountryCodesView.vue";
import CountryCodesFormView from "@/views/account/swift/country-codes/CountryCodesFormView.vue";

// CLIENTS
import ClientsView from "@/views/account/clients/ClientsView.vue";
import ClientBranchesView from "@/views/account/clients/ClientBranchesView.vue";
import ClientAccountsView from "@/views/account/clients/ClientAccountsView.vue";
import PRTFileList from "@/views/account/swift/prt-files/PRTFileList.vue";
import PRTFileForm from "@/views/account/swift/prt-files/PRTFileForm.vue";
import PRTFileView from "@/views/account/swift/prt-files/PRTFileView.vue";
import InstructionListPage from "@/views/banks/catalog/instruction/InstructionListPage.vue";
import InstructionFormPage from "@/views/banks/catalog/instruction/InstructionFormPage.vue";
import CurrencySalesPurposeCodeList from "@/views/banks/currency/currencySalesPurposeCode/CurrencySalesPurposeCodeList.vue";
import CurrencySalesFundSourceList from "@/views/banks/currency/currencySalesFundSource/CurrencySalesFundSourceList.vue";
import VersionList from "@/views/banks/catalog/version/VersionList.vue";
import VersionForm from "@/views/banks/catalog/version/VersionForm.vue";
import CurrencyRateMinMax from "@/views/banks/currency/CurrencyRateMinMax/CurrencyRateMinMax.vue";
import AdminBranchList from "@/views/user/user-branches/AdminBranchList.vue";
import AdminBranchForm from "@/views/user/user-branches/AdminBranchForm.vue";
import BranchAttachForm from "@/views/user/user-branches/BranchAttachForm.vue";
import MyBranchList from "@/views/user/user-branches/MyBranchList.vue";
import CreditList from "@/views/banks/catalog/Credit/CreditList.vue";
import CreditForm from "@/views/banks/catalog/Credit/CreditForm.vue";
import CreditAppeals from "@/views/appeals/creaditAppeals/CreditAppeals.vue";
import CashAppeals from "@/views/appeals/cashAppeals/CashAppeals.vue";
import SecondaryAccountList from "@/views/appeals/SecondaryAccountAppeals/SecondaryAccountList.vue";
import SecondaryAccountForm from "@/views/appeals/SecondaryAccountAppeals/SecondaryAccountForm.vue";
import AccountNameList from "@/views/banks/catalog/AccountName/AccountNameList.vue";
import AccountNameCreate from "@/views/banks/catalog/AccountName/AccountNameCreate.vue";
import SecondaryAccount from "@/views/appeals/SecondaryAccountAppeals/SecondaryAccount.vue";

const Navigations = [
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/users.svg"),
        path: "users",
        name: "UserList",
        component: UserList,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/add",
        name: "UserFormAdd",
        component: UserForm,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/edit/:id",
        name: "UserEdit",
        component: UserForm,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/attach-branch/:userId",
        name: "BranchAttach",
        component: BranchAttachForm,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/change-password/:id",
        name: "ChangeUserPassword",
        component: ChangeUserPassword,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },

    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/bank.svg"),
        path: "admin/branch/list",
        name: "AdminBranchList",
        component: AdminBranchList,
        meta: { permission: ["USER_BRANCH"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "admin/branch/my-branches",
        name: "MyBranchList",
        component: MyBranchList,
        meta: { permission: [], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "admin/branch/add",
        name: "AdminBranchAdd",
        component: AdminBranchForm,
        meta: { permission: ["USER_BRANCH"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "admin/branch/:id",
        name: "AdminBranchEdit",
        component: AdminBranchForm,
        meta: { permission: ["USER_BRANCH"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/users.svg"),
        path: "clients",
        name: "ClientsView",
        component: ClientsView,
        meta: { permission: ["CLIENT_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        imgUrl: null,
        path: "client/branches/:clientId",
        name: "ClientBranchesView",
        component: ClientBranchesView,
        meta: { permission: ["CLIENT_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        imgUrl: null,
        path: "client/branches/accounts/:clientId",
        name: "ClientAccountsView",
        component: ClientAccountsView,
        meta: { permission: ["CLIENT_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/permission-update/:id",
        name: "UpdateUserPermissions",
        component: UpdateUserPermissions,
        meta: { permission: ["UPDATE_USER_PERMISSIONS"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/building.svg"),
        path: "user/organizations",
        name: "UserOrganizations",
        component: UserOrganizations,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/organization/add",
        name: "UserOrganizationFormAdd",
        component: UserOrganizationForm,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/organization/edit/:id",
        name: "UserOrganizationFormEdit",
        component: UserOrganizationForm,
        meta: { permission: ["USER_CREATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/organization/employees/:id",
        name: "OrganizationEmployeeList",
        component: OrganizationEmployeeList,
        meta: { permission: ["EMPLOYEE_CREATE_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/organization/:type/employee/:id",
        name: "CombineOrganizationToUser",
        component: CombineOrganizationToUser,
        meta: { permission: ["EMPLOYEE_CREATE_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/offerta/add",
        name: "OffertaFormCreateView",
        component: OffertaFormView,
        meta: { permission: ["POWER_ATTORNEY_TEMPLATE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "user/offerta/edit/:id",
        name: "OffertaFormEditView",
        component: OffertaFormView,
        meta: { permission: ["POWER_ATTORNEY_TEMPLATE"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/bank.svg"),
        path: "bank/uzb-bank/list",
        name: "UzbBankList",
        component: UzbBankList,
        meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/add",
        name: "bankFormAdd",
        component: BankFormAdd,
        meta: { permission: ["BANK_FORM"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/edit/:id",
        name: "bankFormEdit",
        component: BankFormAdd,
        meta: { permission: ["BANK_FORM"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        imgUrl: require("../assets/images/bank.svg"),
        path: "bank/uzb-bank/edit/:id",
        name: "UzbBankEditForm",
        component: UzbBankEditForm,
        meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        imgUrl: require("../assets/images/bank.svg"),
        path: "bank/uzb-bank/branch/list/:id",
        name: "UzbBankBranches",
        component: UzbBankBranches,
        meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        imgUrl: require("../assets/images/bank.svg"),
        path: "bank/uzb-bank/branch/edit/:id",
        name: "UzbBankBranchEditForm",
        component: UzbBankBranchEditForm,
        meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/id.svg"),
        path: "bank/purpose",
        name: "PurposeCode",
        component: PurposeCode,
        meta: { permission: ["PURPOSE_CODE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/purpose/add",
        name: "PurposeCodeAdd",
        component: PurposeCodeForm,
        meta: { permission: ["PURPOSE_CODE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/purpose/edit/:id",
        name: "PurposeCodeEdit",
        component: PurposeCodeForm,
        meta: { permission: ["PURPOSE_CODE"], authorize: ["DEV"] },
    },

    // SWIFT ROUTES
    {
        main: true,
        isSubmenu: true,
        imgUrl: require("../assets/images/globe.svg"),
        path: "swift",
        name: "Swift",
        title: "Swift",
        component: SwiftView,
        children: [
            {
                main: true,
                isSubmenu: false,
                path: "commissions",
                name: "Commissions",
                component: CommissionsView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: true,
                isSubmenu: false,
                path: "type-operations",
                name: "TypeOperations",
                component: TypeOperationsView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: true,
                isSubmenu: false,
                path: "payment-terms",
                name: "PaymentTerms",
                component: PaymentTermsView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: false,
                isSubmenu: false,
                path: "payment-terms/edit/:id",
                name: "PaymentTermsFormView",
                component: PaymentTermsFormView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: true,
                isSubmenu: false,
                path: "country-codes",
                name: "CountryCodesView",
                component: CountryCodesView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: false,
                isSubmenu: false,
                path: "country-codes/edit/:id",
                name: "CountryCodesEdit",
                component: CountryCodesFormView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: false,
                isSubmenu: false,
                path: "country-codes/add",
                name: "CountryCodesAdd",
                component: CountryCodesFormView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: true,
                isSubmenu: false,
                path: "prt-files",
                name: "PRTFileList",
                component: PRTFileList,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: false,
                isSubmenu: false,
                path: "prt-files/add",
                name: "PRTFileAdd",
                component: PRTFileForm,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: false,
                isSubmenu: false,
                path: "prt-files/view/:id",
                name: "PRTFileView",
                component: PRTFileView,
                meta: {
                    permission: ["SWIFT_ADMIN"],
                    authorize: ["DEV"],
                },
            },
            {
                main: true,
                isSubmenu: false,
                path: "bank/currency/sales-purpose-codes",
                name: "CurrencySalesPurposeCodeList",
                component: CurrencySalesPurposeCodeList,
                meta: { permission: ["SWIFT_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                path: "bank/currency/sales-fund-source",
                name: "CurrencySalesFundSourceList",
                component: CurrencySalesFundSourceList,
                meta: { permission: ["SWIFT_ADMIN"], authorize: ["DEV"] },
            },
        ],
        meta: {
            permission: ["SWIFT_ADMIN"],
            authorize: ["DEV"],
        },
    },
    {
        main: true,
        isSubmenu: true,
        imgUrl: require("../assets/images/accounts.svg"),
        path: "bank/catalog",
        name: "Accounts",
        title: "Accounts",
        component: MainAccount,
        children: [
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/accounts-edit.svg"),
                path: "account/list",
                name: "AccountsList",
                component: AccountsList,
                meta: { permission: ["ACCOUNT_NAME"], authorize: ["DEV"] },
            },
        ],
        meta: {
            permission: ["ACCOUNT_NAME", "TRANSFER_VALIDATION"],
            authorize: ["DEV"],
        },
    },
    {
        main: false,
        isSubmenu: false,
        path: "account/edit/:id",
        name: "AccountNameForm",
        component: AccountNameForm,
        meta: { permission: ["ACCOUNT_NAME"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "account/create",
        name: "AccountCreate",
        component: AccountCreate,
        meta: { permission: ["ACCOUNT_NAME"], authorize: ["DEV"] },
    },

    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/coins-hand.svg"),
        path: "account/debt-payment-code",
        name: "DebtPaymentCodeAll",
        title: "Debt payment all",
        component: DebtPaymentCodeAll,
        meta: { permission: ["DEBT_PAYMENT_CODE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "account/debt-payment-code/:id",
        name: "DebtPaymentCodeId",
        title: "Debt payment by id",
        component: DebtPaymentCodeId,
        meta: { permission: ["DEBT_PAYMENT_CODE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "account/debt-payment-code/edit/:id",
        name: "DebtPaymentCodeEdit",
        title: "Debt payment edit",
        component: DebtPaymentCodeEdit,
        meta: { permission: ["DEBT_PAYMENT_CODE"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        imgUrl: null,
        path: "account/type-operations/edit/:id",
        name: "TypeOperationEditView",
        component: TypeOperationEditView,
        meta: {
            permission: ["SWIFT_ADMIN"],
            authorize: ["DEV"],
        },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/branch.svg"),
        path: "bank/branches",
        name: "Branches",
        component: BranchList,
        meta: { permission: ["BRANCH"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: true,
        imgUrl: require("../assets/images/catalog.svg"),
        path: "bank/catalog",
        name: "Catalog",
        title: "Katolog",
        component: CatalogMain,
        children: [
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/permission.svg"),
                path: "bank/permission",
                name: "Permissions",
                component: PermissionList,
                meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/permissionGroup.svg"),
                path: "bank/permission-group",
                name: "PermissionsGroup",
                component: PermissionGroup,
                meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/roles.svg"),
                path: "bank/role",
                name: "Role",
                component: RoleList,
                meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/territories.svg"),
                path: "bank/regions",
                name: "RegionsList",
                component: RegionsList,
                meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/file-question-02.svg"),
                path: "appeal/type",
                name: "AppealTypesList",
                component: AppealTypesList,
                meta: { permission: ["MAIL_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/notification.svg"),
                path: "notifications/category",
                name: "NotificationCategoryList",
                component: CategoryList,
                meta: { permission: ["NOTIFICATION_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/offerta.svg"),
                path: "user/offerta",
                name: "OffertaView",
                component: OffertaView,
                meta: { permission: ["POWER_ATTORNEY_TEMPLATE"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/territories.svg"),
                path: "instructions",
                name: "InstructionListPage",
                component: InstructionListPage,
                meta: { permission: ["INSTRUCTION_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/dataflow-02.svg"),
                path: "version",
                name: "VersionList",
                component: VersionList,
                meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/offerta.svg"),
                path: "bank/credit",
                name: "Credit",
                component: CreditList,
                meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/offerta.svg"),
                path: "account-name",
                name: "AccountName",
                component: AccountNameList,
                meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
            },
            {
                main: false,
                isSubmenu: false,
                path: "account-name/add",
                name: "AccountNameAddForm",
                component: AccountNameCreate,
                meta: { permission: ["APPEAL_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: false,
                isSubmenu: false,
                path: "account-name/edit/:id",
                name: "AccountNameEditForm",
                component: AccountNameCreate,
                meta: { permission: ["APPEAL_ADMIN"], authorize: ["DEV"] },
            },
        ],
        meta: {
            permission: ["AUTH_CATALOG", "UZB_BANK", "MAIL_ADMIN"],
            authorize: ["DEV"],
        },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/credit/add",
        name: "CreditAdd",
        component: CreditForm,
        meta: { permission: ["CREDIT_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/credit/edit/:id",
        name: "CreditEdit",
        component: CreditForm,
        meta: { permission: ["CREDIT_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/permission/add",
        name: "PermissionFormAdd",
        component: PermissionFormMain,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/permission/edit/:id",
        name: "PermissionFormEdit",
        component: PermissionFormMain,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "/version/edit/:id",
        name: "VersionFormEdit",
        component: VersionForm,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "/version/create",
        name: "VersionFormCreate",
        component: VersionForm,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/permission-group/add",
        name: "PermissionGroupForm",
        component: PermissionGroupForm,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/permission-group/edit/:id",
        name: "PermissionGroupEdit",
        component: PermissionGroupForm,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/role/add",
        name: "RoleForm",
        component: RoleFormMain,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/role/edit/:id",
        name: "RoleFormEdit",
        component: RoleFormMain,
        meta: { permission: ["AUTH_CATALOG"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/regions/:id",
        name: "DistrictsList",
        component: DistrictsList,
        meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/:type/edit/:id",
        name: "RegionsAndDistrictEditForm",
        component: RegionsAndDistrictEditForm,
        meta: { permission: ["UZB_BANK"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "instruction/create",
        name: "InstructionCreateFormPage",
        component: InstructionFormPage,
        meta: { permission: ["INSTRUCTION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "instruction/edit/:id",
        name: "InstructionEditFormPage",
        component: InstructionFormPage,
        meta: { permission: ["INSTRUCTION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "appeals/type/edit/:id",
        name: "AppealTypeFormEdit",
        component: AppealTypeForm,
        meta: { permission: ["MAIL_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "appeals/type/create",
        name: "AppealTypeFormCreate",
        component: AppealTypeForm,
        meta: { permission: ["MAIL_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: true,
        imgUrl: require("../assets/images/bank-note-05.svg"),
        path: "bank/currency",
        name: "Currency",
        title: "Currency",
        component: CurrencyMain,
        children: [
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/coin-number.svg"),
                path: "bank/currency/code",
                name: "CurrencyCodeList",
                component: CurrencyCodeList,
                meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/bank-note-02.svg"),
                path: "bank/currency/type",
                name: "CurrencyTypeList",
                component: CurrencyTypeList,
                meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/coins-rotate.svg"),
                path: "bank/currency/rates",
                name: "CurrencyRateList",
                component: CurrencyRateList,
                meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/min-max.svg"),
                path: "bank/currency/rate/min-max",
                name: "CurrencyRateMinMax",
                component: CurrencyRateMinMax,
                meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
            },
        ],
        meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/currency/:by/rates/:id",
        name: "CurrencyRateView",
        component: CurrencyRateView,
        meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/currency/code/edit/:id",
        name: "CurrencyCodeForm",
        component: CurrencyCodeForm,
        meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/currency/type/edit/:id",
        name: "CurrencyTypeForm",
        component: CurrencyTypeForm,
        meta: { permission: ["CURRENCY"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: true,
        imgUrl: require("../assets/images/file-attach-01.svg"),
        name: "Appeals",
        title: "AppealsMain",
        meta: {
            permission: ["APPEAL_ADMIN", "DOCUMENT_ADMIN", "DOCUMENT_MODERATOR"],
            authorize: ["DEV"],
        },
        children: [
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/list.svg"),
                path: "appeals",
                name: "AbsClients",
                component: AbsClients,
                meta: { permission: ["APPEAL_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/offerta.svg"),
                path: "credit",
                name: "CreditAppeal",
                component: CreditAppeals,
                meta: { permission: ["APPEAL_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/offerta.svg"),
                path: "secondary-account",
                name: "SecondaryAccountAppeals",
                component: SecondaryAccountList,
                meta: { permission: ["APPEAL_ADMIN","SECONDARY_ACCOUNT_APPLICATION_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: false,
                isSubmenu: false,
                imgUrl: require("../assets/images/offerta.svg"),
                path: "secondary-account/:id",
                name: "SecondaryAccount",
                component: SecondaryAccount,
                meta: { permission: ["APPEAL_ADMIN","SECONDARY_ACCOUNT_APPLICATION_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/coins-hand.svg"),
                path: "cash",
                name: "CashAppeal",
                component: CashAppeals,
                meta: { permission: ["APPEAL_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: false,
                isSubmenu: false,
                path: "appeals/:id",
                title: "AbsClient",
                name: "AbsClient",
                component: AbsClient,
                meta: { permission: ["APPEAL_ADMIN"], authorize: ["DEV"] },
            },
            {
                main: true,
                isSubmenu: false,
                imgUrl: require("../assets/images/file-x.svg"),
                path: "appeals/document/list",
                name: "OnAppealList",
                component: OnAppealList,
                meta: {
                    permission: ["DOCUMENT_ADMIN", "DOCUMENT_MODERATOR"],
                    authorize: ["DEV"],
                },
            },
            {
                main: false,
                isSubmenu: false,
                path: "appeals/document/edit/:id",
                name: "OnAppealForm",
                component: OnAppealForm,
                meta: {
                    permission: ["DOCUMENT_ADMIN", "DOCUMENT_MODERATOR"],
                    authorize: ["DEV"],
                },
            },
        ],
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/branch/add",
        name: "BranchAdd",
        component: BranchForm,
        meta: { permission: ["BRANCH"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "bank/branch/edit/:id",
        name: "BranchEdit",
        component: BranchForm,
        meta: { permission: ["BRANCH"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/notification.svg"),
        path: "notifications",
        name: "NotificationsList",
        component: NotificationsList,
        meta: { permission: ["NOTIFICATION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "notifications/create",
        name: "NotificationCreate",
        component: NotificationForm,
        meta: { permission: ["NOTIFICATION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "notifications/edit/:id",
        name: "NotificationEdit",
        component: NotificationForm,
        meta: { permission: ["NOTIFICATION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "notifications/category/create",
        name: "NotificationCategoryCreate",
        component: CategoryForm,
        meta: { permission: ["NOTIFICATION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: false,
        isSubmenu: false,
        path: "notifications/category/edit/:id",
        name: "NotificationCategoryEdit",
        component: CategoryForm,
        meta: { permission: ["NOTIFICATION_ADMIN"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/lifebuoy.svg"),
        path: "faq-category",
        name: "Help",
        component: FaqCategoryList,
        meta: { permission: ["QUESTION"], authorize: ["DEV"] },
    },
    {
        main: false,
        path: "faq-category/add",
        name: "FaqCategoryAdd",
        component: FaqCategoryForm,
        meta: { permission: ["QUESTION"], authorize: ["DEV"] },
    },
    {
        main: false,
        path: "faq-category/edit/:id",
        name: "FaqCategoryEdit",
        component: FaqCategoryForm,
        meta: { permission: ["QUESTION"], authorize: ["DEV"] },
    },
    {
        main: false,
        path: "faq-list/:categoryId",
        name: "FaqList",
        component: FaqList,
        meta: { permission: ["QUESTION"], authorize: ["DEV"] },
    },
    {
        main: false,
        path: "faq-add/:categoryId",
        name: "FaqAdd",
        component: FaqForm,
        meta: { permission: ["QUESTION"], authorize: ["DEV"] },
    },
    {
        main: false,
        path: "faq-edit/:id",
        name: "FaqEdit",
        component: FaqForm,
        meta: { permission: ["QUESTION"], authorize: ["DEV"] },
    },
    {
        main: true,
        isSubmenu: false,
        imgUrl: require("../assets/images/chat.svg"),
        path: "chat",
        name: "ChatList",
        component: ChatView,
        meta: { permission: ["SUPPORT_CHAT"], authorize: ["DEV"] },
    },
    {
        main: false,
        path: "chat/:chatId",
        name: "ChatView",
        component: ChatView,
        meta: { permission: ["SUPPORT_CHAT"], authorize: ["DEV"] },
    },
];

export default Navigations;
