<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const loadingRefresh = ref(false);
const currencyData = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(8);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
  if (totalPages.value > 1 && page.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

const handleCurrentChange = (value) => {
  router.push({
    query: { page: value },
  });
};

const refreshCurrencyData = () => {
  loadingRefresh.value = true;
  http.get(`account/currency-rate/min-max`)
      .then(() => {
        getCurrencyRateList();
        ElMessage({
          message: t("notifications.successfully_updated"),
          type: "success",
        });
      })
      .finally(() => {
        loadingRefresh.value = false;
      });
};

const getCurrencyRateList = () => {
  loading.value = true;
  page.value = route.query.page ? route.query.page - 1 : 0;
  http.get("account/currency-rate/admin/all/min-max", {
    params: {
      size: size.value,
      page: currentPage.value - 1,
    },
  })
      .then(({ data }) => {
        currencyData.value = data.content;
        totalElements.value = data.totalElements;
        totalPages.value = data.totalPages;
      })
      .catch(({ response: { data } }) => {
        ElMessage({
          message: data.message || t("notifications.error_loading_data"),
          type: "error",
        });
      })
      .finally(() => {
        loading.value = false;
      });
};

watch(
    () => currentPage.value,
    () => {
      getCurrencyRateList();
    }
);

onMounted(() => {
  getCurrencyRateList();
});
</script>

<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("menu.CurrencyRateMinMax") }}</h2>

      <el-tooltip :content="t('buttons.refresh')" placement="top-start">
        <el-button @click="refreshCurrencyData">
          <template v-if="loadingRefresh">
            <el-icon>
              <IconLoading02 />
            </el-icon>
          </template>
          <template v-else>
            <el-icon>
              <IconRefreshCW01 />
            </el-icon>
          </template>
        </el-button>
      </el-tooltip>
    </div>

    <div v-loading="loading">
      <el-table
          :data="currencyData"
          :header-cell-style="{ color: '#111', textAlign: 'center' }"
          :empty-text="t('notifications.information_not_available')"
          border
      >
        <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

        <el-table-column prop="currency.name.uzLat" :label="t('labels.name')" align="center" />

        <el-table-column
            prop="currency.codeAlfa"
            :label="t('labels.ico_code')"
            width="90"
            align="center"
        />
        <el-table-column
            prop="cbOrder"
            :label="t('labels.cbOrder')"
            width="100"
            align="center"
        />
        <el-table-column
            prop="cbCurrency"
            :label="t('labels.cbCurrency')"
            width="100"
            align="center"
        >
          <template #default="scope">
            {{scope.row.cbCurrency.toLocaleString('ru')}}
          </template>
        </el-table-column>
        <el-table-column
            prop="min"
            :label="t('labels.min')"
            width="100"
            align="center"
        >
          <template #default="scope">
            {{scope.row.min.toLocaleString('ru')}}
          </template>
        </el-table-column>
        <el-table-column
            prop="max"
            :label="t('labels.max')"
            width="100"
            align="center"
        >
          <template #default="scope">
            {{scope.row.max.toLocaleString('ru')}}
          </template>
        </el-table-column>
      </el-table>

      <div class="flex items-center justify-between mt-2">
                <span v-if="totalElements" class="font-semibold">
                    {{ t("labels.total") }}: {{ totalElements }}
                </span>

        <el-pagination
            :current-page="currentPage"
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="totalElements"
            :page-size="size"
            :page-count="totalPages"
            :hide-on-single-page="true"
            :background="true"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn_refresh {
  padding: 8px;
}
.btn_refresh:deep(.el-icon) {
  font-size: 18px;
}
.main-data {
  background-color: #ffffff;
  padding-top: 20px;
  border-radius: 10px;
}
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.pagination-wrapper > span {
  color: #0a194e;
  font-family: "Montserrat-Bold", sans-serif;
}
</style>
