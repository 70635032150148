<template>
    <div class="main-data" v-loading="loading">
        User info
        <div class="user-info"><span class="info-key">Name:</span> {{ user?.name }}</div>
        <div class="user-info"><span class="info-key">Username:</span> {{ user?.username }}</div>
        <div class="user-info">
            <span class="info-key">Role:</span>
            <el-space v-if="user?.role">
                <el-tag type="success" round>{{ user?.role?.name }}</el-tag>
            </el-space>
        </div>
        <el-row>
            <el-col :xs="24" :sm="20" :lg="16" :xl="14">
                <el-form ref="form" :model="form" :rules="rules" label-position="top">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="16" :lg="14" :xl="12">
                            <el-form-item label="Foydalanuvchi huqulari" prop="selectedPermissions">
                                <el-select
                                    style="width: 100%"
                                    class="form-select"
                                    v-model="form.selectedPermissions"
                                    multiple
                                    placeholder="Select"
                                    value-key="id"
                                >
                                    <el-option
                                        v-for="item in filteredPermissions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button class="custom-btn" @click="() => this.$router.go(-1)"
                                    >Orqaga</el-button
                                >
                                <el-button class="custom-btn" type="primary" @click="onSubmit"
                                    >Saqlash</el-button
                                >
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
    name: "UpdateUserPermissions",
    data() {
        return {
            loading: false,
            user: null,
            form: {
                selectedPermissions: [],
            },
            roles: [],
            permissions: [],
            rules: {
                selectedPermissions: [
                    { required: false, message: "Majburiy maydon", trigger: "change" },
                ],
            },
        };
    },
    computed: {
        filteredPermissions() {
            return this.user
                ? this.permissions.filter((item) =>
                      item.role.includes(this.user.role.name.toUpperCase())
                  )
                : [];
        },
    },
    methods: {
        getUser(id) {
            this.loading = true;
            this.$http
                .get(`user/${id}`)
                .then(({ data }) => {
                    this.user = data;
                    if (data.permissions.length) {
                        this.form.selectedPermissions = data.permissions.map((item) => item.id);
                    }
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Ma'lumot yuklashda xatolik",
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPermissions() {
            this.loading = true;
            this.$http
                .get(`user/permission`)
                .then(({ data }) => {
                    this.permissions = data;
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Ma'lumot yuklashda xatolik",
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .put(`user/permission/${this.user.id}`, this.form.selectedPermissions)
                        .then(() => {
                            ElMessage({
                                message: "Muvaffaqiyatli saqlandi",
                                type: "success",
                            });
                            this.$router.go(-1);
                        })
                        .catch(({ response: { data } }) => {
                            ElMessage({
                                message: data.message || "Ma'lumot saqlashda xatolik",
                                type: "error",
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.getUser(this.$route.params.id);
        }
        this.getPermissions();
    },
};
</script>

<style scoped>
.main-data {
    background-color: #ffffff;
    padding: 20px 20px 0;
    border-radius: 10px;
    height: calc(100vh - 170px);
}

.el-select:deep(.el-input__wrapper) {
    min-height: 40px;
}

.custom-btn {
    height: 40px;
    border-radius: 5px;
    padding: 0 20px;
}

.user-info {
    margin: 10px;
    font-weight: bold;
    display: flex;
}

.info-key {
    color: #888585;
    font-weight: normal;
    margin-right: 10px;
    min-width: 150px;
    display: block;
}
</style>
