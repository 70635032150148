<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {ElMessage} from "element-plus";
import store from "@/store";
import http from "@/http";
import {checkPermission} from "@/composables";
import IconUsers01 from "@/components/icons/users/IconUsers01.vue";
import IconSearchMd from "@/components/icons/general/IconSearchMd.vue";

const {t} = useI18n();
const route = useRoute();
const router = useRouter();

const orgTypes = ref(["YATT", "YUR"]);

const dataTable = ref([]);
const loading = ref(false);
const timeout = ref(null);
const search = ref(route.query.search);
const type = ref(route.query.type);
const selectedStatus = ref("");
const statusList = ref([
  {
    label: t("organization_status.CREATED"),
    value: "CREATED",
  },
  {
    label: t("organization_status.ACTIVE"),
    value: "ACTIVE",
  },
  {
    label: t("organization_status.INACTIVE"),
    value: "INACTIVE",
  },
  {
    label: t("organization_status.REJECTED"),
    value: "REJECTED",
  },
]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
  if (totalPages.value > 1 && page.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

const handleCurrentChange = (value) => {
  router.push({
    query: {page: value},
  });
};

function filterOrganizationsByType(value) {
  router.push({
    query: {
      page: currentPage.value,
      type: value,
      search: search.value,
      status: selectedStatus.value,
    },
  });
  getOrganizations();
}

function filterOrganizationsByStatus(value) {
  router.push({
    query: {
      page: currentPage.value,
      type: type.value,
      search: search.value,
      status: value,
    },
  });
  getOrganizations();
}

function searchOrganizations() {
  clearTimeout(timeout.value);
  router.push({
    query: {
      page: currentPage.value,
      type: type.value,
      search: search.value,
      status: selectedStatus.value,
    },
  });
  timeout.value = setTimeout(() => {
    getOrganizations();
  }, 500);
}

const getOrganizations = () => {
  loading.value = true;
  page.value = route.query.page ? route.query.page - 1 : 0;
  http.get("user/organization/admin", {
    params: {
      page: page.value,
      type: type.value,
      search: search.value,
      status: selectedStatus.value,
    },
  })
      .then(({data}) => {
        dataTable.value = data.content;
        totalElements.value = data.totalElements;
        totalPages.value = data.totalPages;
      })
      .catch(({response: {data}}) => {
        ElMessage({
          message: data.message || "Ma'lumot yuklashda xatolik",
          type: "error",
        });
      })
      .finally(() => {
        loading.value = false;
      });
};

watch(
    () => currentPage.value,
    () => {
      getOrganizations();
    }
);

onMounted(() => {
  getOrganizations();
});
</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("menu.UserOrganizations") }}</h2>

      <div class="flex items-center gap-x-2">
        <el-select
            v-model="type"
            :style="{ width: '100%' }"
            :placeholder="t('labels.select_organization')"
            @change="filterOrganizationsByType"
            :clearable="true"
        >
          <el-option
              v-for="item in orgTypes"
              :key="item"
              :label="t(`organization_type.${item}`)"
              :value="item"
          />
        </el-select>

        <el-select
            v-model="selectedStatus"
            :style="{ width: '100%' }"
            :placeholder="t('labels.select_status_type')"
            @change="filterOrganizationsByStatus"
            :clearable="true"
        >
          <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>

        <el-input
            type="text"
            v-model="search"
            :placeholder="t('labels.search')"
            @keyup="searchOrganizations"
        >
          <template #prefix>
            <el-icon size="18px">
              <IconSearchMd/>
            </el-icon>
          </template>
        </el-input>
      </div>
    </div>

    <div v-loading="loading">
      <el-table
          :data="dataTable"
          :header-cell-style="{ color: '#111', textAlign: 'center' }"
          :empty-text="t('notifications.information_not_available')"
          border
      >
        <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center"/>

        <el-table-column prop="ownerFullName" :label="t('labels.s_n_m')" align="center"/>

        <el-table-column prop="uid" :label="t('labels.i_n_n')" align="center"/>

        <el-table-column
            prop="type"
            :label="t('labels.organization_type')"
            align="center"
        />

        <el-table-column prop="status" :label="t('labels.status')" align="center">
          <template v-slot="scope">
            <el-tag
                round
                :type="
                                scope.row.status === 'ACTIVE'
                                    ? 'success'
                                    : scope.row.status === 'CREATED'
                                      ? 'info'
                                      : scope.row.status === 'INACTIVE'
                                        ? 'danger'
                                        : scope.row.status === 'REJECTED'
                                          ? 'danger'
                                          : ''
                            "
            >
              {{ t(`organization_status.${scope.row.status}`) }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
            prop="name"
            :label="t('labels.organization_name')"
            align="center"
        />

        <el-table-column prop="ownerPinfl" :label="t('labels.pinfil')" align="center"/>

        <el-table-column :label="t('labels.actions')" align="center">
          <template v-slot="scope">
            <el-space>
              <el-tooltip
                  effect="dark"
                  placement="top"
                  :content="t('labels.employees_to_org')"
              >
                <el-button
                    v-if="checkPermission('EMPLOYEE_CREATE_ADMIN')"
                    type="primary"
                    plain
                    @click="
                                        () => {
                                            store.commit('updatePageTitle', scope.row.name);
                                            router.push({
                                                name: 'OrganizationEmployeeList',
                                                params: { id: scope.row.id },
                                            });
                                        }
                                    "
                >
                  <el-icon>
                    <IconUsers01/>
                  </el-icon>
                </el-button>
              </el-tooltip>
            </el-space>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex items-center justify-between mt-4">
        <template v-if="totalElements">
          <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
        </template>

        <el-pagination
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="totalElements"
            :page-size="size"
            :page-count="totalPages"
            :hide-on-single-page="true"
            :background="true"
        />
      </div>
    </div>
  </div>
</template>
