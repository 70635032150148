<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessageBox, ElMessage } from "element-plus";
import YandexMap from "@/views/banks/branches/components/YandexMap";
import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";

const { t } = useI18n();
const router = useRouter();

const dataTable = ref([]);
const loading = ref(false);
const mapDialog = ref(false);
const selectedLatLong = ref([]);
const options = ref([]);
const selectedBranchType = ref(null);

const closeMap = () => {
    mapDialog.value = false;
};

const openMap = (lat, long) => {
    mapDialog.value = true;
    selectedLatLong.value = [lat, long];
};

const onCLickDelete = (id) => {
    ElMessageBox.confirm(t("notifications.delete_data"), t("notifications.attention"), {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.cancellation"),
        type: "warning",
    }).then(() => {
        http.delete(`bank/branch/${id}`)
            .then(() => {
                ElMessage({
                    message: t("notifications.deleted_successfully"),
                    type: "success",
                });
                getBranchList();
            })
            .catch(({ response: { data } }) => {
                ElMessage({
                    message: data.message || t("notifications.error_occurred_while_deleting_data"),
                    type: "error",
                });
            });
    });
};

const getBranchList = () => {
    loading.value = true;
    http.get("bank/branch")
        .then(({ data }) => {
            dataTable.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Branch yuklashda xatolik!",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const getBranchTypeList = () => {
    http("/bank/branch/type")
        .then(({ data }) => {
            options.value = data.map((item) => {
                return {
                    label: item,
                    value: item,
                };
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Branch turini yuklashda xatolik!",
                type: "error",
            });
        });
};

const getFilterBranchList = () => {
    loading.value = true;
    http.get("/bank/branch", {
        params: {
            type: selectedBranchType.value,
        },
    })
        .then(({ data }) => {
            dataTable.value = data;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Branch yuklashda xatolik!",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(selectedBranchType, () => {
    getFilterBranchList();
});

onMounted(() => {
    getBranchList();
    getBranchTypeList();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("menu.Branches") }}</h2>

            <div class="flex items-center gap-x-4">
                <el-select
                    v-model="selectedBranchType"
                    :clearable="true"
                    :placeholder="t('labels.branch_type')"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>

                <el-tooltip :content="t('buttons.add')" placement="top">
                    <el-button
                        class="add-button"
                        type="primary"
                        @click="router.push({ name: 'BranchAdd' })"
                    >
                        <el-icon>
                            <IconPlus />
                        </el-icon>
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <el-table
            :data="dataTable"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
            v-loading="loading"
            border
        >
            <el-table-column type="index" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" align="center">
                <template v-slot="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>

            <el-table-column prop="address" :label="t('labels.address')" align="center" />

            <el-table-column prop="type" :label="t('labels.type')" align="center">
                <template v-slot="scope">
                    <el-tag type="info">
                        {{ scope.row.type }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="phoneNumber" :label="t('labels.telephone')" align="center" />

            <el-table-column prop="lat" :label="t('labels.location')" align="center">
                <template v-slot="scope">
                    <el-button @click="openMap(scope.row.lat, scope.row.long)" link>
                        <img src="@/assets/images/map.svg" />
                    </el-button>
                </template>
            </el-table-column>

            <el-table-column :label="t('labels.settings')" align="center" :width="220">
                <template v-slot="scope">
                    <el-tooltip :content="t('buttons.edit')" placement="left-start">
                        <el-button
                            type="warning"
                            plain
                            @click="
                                router.push({
                                    name: 'BranchEdit',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEdit05 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>

                    <el-tooltip :content="t('buttons.delete')" placement="right-start">
                        <el-button type="danger" plain @click="onCLickDelete(scope.row.id)">
                            <el-icon>
                                <IconTrash01 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog v-model="mapDialog" destroy-on-close>
            <YandexMap :coordinates="selectedLatLong" @onClose="closeMap" :is-show-mode="true" />
        </el-dialog>
    </div>
</template>
