<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 22" fill="none">
    <path d="M1.59998 14.2197L1.59998 17.9256C1.59998 18.4873 1.82123 19.0259 2.21505 19.423C2.60888 19.8202 3.14302 20.0433 3.69998 20.0433H16.3C16.8569 20.0433 17.3911 19.8202 17.7849 19.423C18.1787 19.0259 18.4 18.4872 18.4 17.9256V14.2197M10.0011 1.95654V13.9565M10.0011 13.9565L14.8011 9.37139M10.0011 13.9565L5.20115 9.37139" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>