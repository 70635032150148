<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {ElMessage} from "element-plus";
import http from "@/http";
import LinkedButton from "@/components/buttons/LinkedButton.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";

const {t} = useI18n();
const route = useRoute();
const router = useRouter();

const dataTable = ref([]);
const loading = ref(false);
const loadingRefresh = ref(false);


const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(15);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
  if (totalPages.value > 1 && page.value) {
    return index + size.value * (currentPage.value - 1) + 1;
  } else {
    return index + 1;
  }
};

const handleCurrentChange = (value) => {
  router.push({
    query: {page: value},
  });
};

const getOrganizations = () => {
  loading.value = true;
  page.value = route.query.page ? route.query.page - 1 : 0;
  http.get("user/branch/admin", {
    params: {
      page: page.value,
      size: size.value
    },
  })
      .then(({data}) => {
        dataTable.value = data.content;
        totalElements.value = data.totalElements;
        totalPages.value = data.totalPages;
      })
      .catch(({response: {data}}) => {
        ElMessage({
          message: data.message || "Ma'lumot yuklashda xatolik",
          type: "error",
        });
      })
      .finally(() => {
        loading.value = false;
      });
};

const refreshData = () => {
  loadingRefresh.value = true;
  http.get("user/branch/admin/refresh")
      .then(() => {
        getOrganizations();
        ElMessage({
          message: t("notifications.successfully_updated"),
          type: "success",
        });
      })
      .finally(() => {
        loadingRefresh.value = false;
      });
};

watch(
    () => currentPage.value,
    () => {
      getOrganizations();
    }
);

onMounted(() => {
  getOrganizations();
});
</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ t("menu.AdminBranchList") }}</h2>
      <div class="flex gap-2">
        <el-tooltip :content="t('buttons.refresh')" placement="top">
          <el-button @click="refreshData">
            <template v-if="loadingRefresh">
              <el-icon>
                <IconLoading02 />
              </el-icon>
            </template>
            <template v-else>
              <el-icon>
                <IconRefreshCW01 />
              </el-icon>
            </template>
          </el-button>
        </el-tooltip>
        <linked-button route="AdminBranchAdd">{{t("buttons.add")}}</linked-button>
      </div>

    </div>


    <div v-loading="loading">
      <el-table
          :data="dataTable"
          :header-cell-style="{ color: '#111', textAlign: 'center' }"
          :empty-text="t('notifications.information_not_available')"
          border
          height="calc(100vh - 240px)"
      >
        <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center"/>

        <el-table-column prop="name" :label="t('labels.name')" align="center"/>

        <el-table-column prop="mfo" :label="t('labels.branch_id')" align="center"/>

        <el-table-column :label="t('labels.actions')" align="center" width="120">
          <template v-slot="scope">
            <el-space>
              <LinkedButton route="AdminBranchEdit" :params="{id: scope.row.id}"><IconEdit05/></LinkedButton>
            </el-space>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex items-center justify-between mt-4">
        <template v-if="totalElements">
          <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
        </template>

        <el-pagination
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="totalElements"
            :page-size="size"
            :page-count="totalPages"
            :hide-on-single-page="true"
            :background="true"
        />
      </div>
    </div>
  </div>
</template>
