<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const activeName = ref("uzLat");
const loading = ref(false);
const form = ref({
    id: null,
    name: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
    key: null,
    description: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
});
const formRef = ref(null);
const rules = ref({
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "description.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    key: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});

const getPermission = (id) => {
    loading.value = true;
    http.get(`user/permission/admin/${id}`)
        .then(({ data }) => {
            form.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSubmit = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true;
            let https;

            if (form.value.id) {
                https = http.put(`user/permission/admin/${form.value.id}`, form.value);
            } else {
                https = http.post("user/permission/admin", {
                    name: form.value.name,
                    description: form.value.description,
                    key: form.value.key,
                });
            }
            https
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

onMounted(() => {
    if (route.params.id) {
        getPermission(route.params.id);
    }
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ !route.params.id ? t("titles.add_permission") : t("titles.edit_permission") }}
            </h2>

            <el-button @click="() => router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span> {{ t("buttons.back") }}</span>
            </el-button>
        </div>

        <el-form
            ref="formRef"
            :model="form"
            :rules="rules"
            label-position="top"
            v-loading="loading"
        >
            <el-tabs v-model="activeName" class="demo-tabs">
                <el-tab-pane :label="t('langs.uzbek')" name="uzLat">
                    <div class="grid grid-cols-2 gap-4">
                        <el-form-item :label="t('labels.name')" prop="name.uzLat">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                :placeholder="t('labels.name')"
                                v-model="form.name.uzLat"
                                type="text"
                            />
                        </el-form-item>

                        <el-form-item :label="t('labels.enter_comment')" prop="description.uzLat">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.enter_comment')"
                                v-model="form.description.uzLat"
                                type="text"
                            />
                        </el-form-item>
                    </div>
                </el-tab-pane>

                <el-tab-pane :label="t('langs.english')" name="en">
                    <div class="grid grid-cols-2 gap-4">
                        <el-form-item :label="t('labels.name')" prop="name.en">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.name')"
                                v-model="form.name.en"
                                type="text"
                            />
                        </el-form-item>

                        <el-form-item :label="t('labels.enter_comment')" prop="description.en">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.enter_comment')"
                                v-model="form.description.en"
                                type="text"
                            />
                        </el-form-item>
                    </div>
                </el-tab-pane>

                <el-tab-pane :label="t('langs.russian')" name="ru">
                    <div class="grid grid-cols-2 gap-4">
                        <el-form-item :label="t('labels.name')" prop="name.ru">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.name')"
                                v-model="form.name.ru"
                                type="text"
                            />
                        </el-form-item>

                        <el-form-item :label="t('labels.enter_comment')" prop="description.ru">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.enter_comment')"
                                v-model="form.description.ru"
                                type="text"
                            />
                        </el-form-item>
                    </div>
                </el-tab-pane>

                <el-tab-pane :label="t('langs.cyrill')" name="uzCyr">
                    <div class="grid grid-cols-2 gap-4">
                        <el-form-item :label="t('labels.name')" prop="name.uzCyr">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.name')"
                                v-model="form.name.uzCyr"
                                type="text"
                            />
                        </el-form-item>

                        <el-form-item :label="t('labels.enter_comment')" prop="description.uzCyr">
                            <template #error="{ error }">
                                <span class="el-form-item__error">
                                    {{ t(error) }}
                                </span>
                            </template>
                            <el-input
                                size="large"
                                class="form-input"
                                :placeholder="t('labels.enter_comment')"
                                v-model="form.description.uzCyr"
                                type="text"
                            />
                        </el-form-item>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <div>
                <el-form-item :label="t('labels.enter_key')" prop="key">
                    <template #error="{ error }">
                        <span class="el-form-item__error">
                            {{ t(error) }}
                        </span>
                    </template>
                    <el-input
                        size="large"
                        :placeholder="t('labels.enter_key')"
                        v-model="form.key"
                        type="text"
                    />
                </el-form-item>
            </div>

            <div class="flex items-center justify-end">
                <el-button type="primary" @click="onSubmit">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
