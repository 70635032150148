<script setup>
import { computed, onMounted, ref } from "vue";
import http from "@/http";
import { ElMessage } from "element-plus";
import LinkedButton from "@/components/buttons/LinkedButton.vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
const loading = ref(false);
const data = ref([]);
const route = useRoute();
const router = useRouter();

const { t } = useI18n();

const currentPage = computed(() => (route.query.page ? parseInt(route?.query?.page, 10) : 1));
let totalElements = ref(0);
let totalPages = ref(null);
const page = ref(0);
const size = ref(10);

const handleCurrentChange = (val) => {
    router
        .replace({
            query: { page: val },
        })
        .then(() => {
            getRoles();
        });
};

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (route.query.page - 1) + 1;
    } else {
        return index + 1;
    }
};
const deleteRole = (id) => {
    loading.value = true;
    http.delete(`user/role/admin/${id}`)
        .then(() => {
            ElMessage({
                message: t("notifications.deleted_successfully"),
                type: "success",
            });
            getRoles();
        })
        .finally(() => {
            loading.value = false;
        });
};

const getRoles = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("user/role/admin", {
        params: {
            page: page.value,
            size: size.value,
        },
    })
        .then(({ data: dataValue }) => {
            data.value = dataValue.content;
            totalElements.value = dataValue?.totalElements;
            totalPages.value = dataValue?.totalPages;
            size.value = dataValue?.size;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || "Ma'lumot yuklashda xatolik",
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    getRoles();
});
</script>

<template>
    <div class="page-header">
        <h3 class="page-title">{{ $t("menu.Role") }}</h3>
        <div class="actions demo-input-suffix">
            <el-button class="add-button" type="primary" @click="$router.push({ name: 'RoleForm' })"
                >+ {{ $t("buttons.add") }}</el-button
            >
        </div>
    </div>
    <div class="main-data" v-loading="loading">
        <el-table
            class-name="table-custom-cls"
            :data="data"
            stripe
            style="width: 100%"
            height="calc(100vh - 190px)"
            table-layout="fixed"
            border
        >
            <el-table-column
                type="index"
                label="№"
                :index="indexMethod"
                width="75"
                align="center"
            />
            <el-table-column prop="name" :label="$t('labels.name')" />
            <el-table-column prop="description" :label="$t('labels.description')" />
            <el-table-column prop="key" :label="$t('labels.key')" />
            <!--      <el-table-column prop="roleIds" label="Rollar">-->
            <!--        <template v-slot="scope">-->
            <!--          <el-space>-->
            <!--            <el-tag v-for="(role,index) in scope.row['role']" round :key="index">{{ role }}</el-tag>-->
            <!--          </el-space>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
            <el-table-column width="250" :label="$t('labels.settings')" align="center">
                <template #default="scope">
                    <el-button type="danger" size="small" @click.prevent="deleteRole(scope.row.id)">
                        {{ $t("buttons.delete") }}
                    </el-button>
                    <LinkedButton
                        class="ml-2"
                        route="RoleFormEdit"
                        :params="{ id: scope.row.id }"
                        size="small"
                        >{{ $t("buttons.edit") }}</LinkedButton
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination-wrapper">
                    <span v-if="totalElements">{{ $t("labels.total") }}: {{ totalElements }} </span>
                    <el-pagination
                        :current-page="currentPage"
                        class="pagination"
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next"
                        :total="totalElements"
                        :page-size="size"
                        :page-count="totalPages"
                        :hide-on-single-page="true"
                        :background="true"
                    >
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-title {
    border: none !important;
    margin: 0;
    padding-bottom: 0;
}

.main-data {
    background-color: #ffffff;
    padding-top: 20px;
    border-radius: 10px;
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 0 0;
    /*height: 40px;*/
}

.pagination-wrapper > span {
    color: #0a194e;
    font-family: "Montserrat-Bold", sans-serif;
}

.actions {
    display: flex;
    gap: 10px;
}

:deep(.el-dialog__body) {
    padding: 5px;
}

:deep(.el-dialog__header) {
    display: none;
}
</style>
