<script setup>
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import {onMounted, reactive, ref} from "vue";
import http from "@/http";
import {ElMessage} from "element-plus";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";


const { t } = useI18n();
const loading=ref(false)
const form = reactive({
  uzLat: null,
  uzCyr: null,
  ru: null,
  en: null,
  formalizationType:null,
  provision:null,
  paymentMethods:null,
  minAmount:null,
  maxAmount:null,
  interestRate:null,
  maxDelay:null,
  minTerm:null,
  maxTerm:null,
  reviewTerm:null,
  initialAmount:null,
})
const formRef=ref()
const route = useRoute();
const router=useRouter()

const rules= reactive({
  uzLat: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
    {
      max: 128,
      message: "validations.the_maximum_number_of_characters_is_128",
      trigger: 'change',
    },
  ],
  uzCyr: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
    {
      max: 128,
      message: "validations.the_maximum_number_of_characters_is_128",
      trigger: 'change',
    },
  ],
  ru: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
    {
      max: 128,
      message: "validations.the_maximum_number_of_characters_is_128",
      trigger: 'change',
    },
  ],
  en: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
    {
      max: 128,
      message: "validations.the_maximum_number_of_characters_is_128",
      trigger: 'change',
    },
  ],
  provision: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
    {
      max: 255,
      message: "validations.the_maximum_number_of_characters_is_128",
      trigger: 'change',
    },
  ],
  formalizationType: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    }
  ],
  paymentMethods: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    }
  ],
  minAmount: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    }
  ],
  maxAmount: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    }
  ],
  interestRate: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
  maxDelay: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
  minTerm: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
  maxTerm: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
  reviewTerm: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
  initialAmount: [
    {
      required: true,
      message: "validations.required_field",
      trigger: 'change',
    },
  ],
})

const types = [
  {
    name: "ONLINE",
  },
  {
    name: "OFFLINE",
  }
]
const paymentMethods = [
  {
    name: "ANNUITY",
  },
  {
    name: "DIFFERENTIAL",
  }
]

const changeAmount = (evt) => {
  if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
  ) {
    evt.preventDefault()
  }
}

const onSubmit = async (formEl) => {
  await formEl.validate((valid, fields) => {
    if (valid) {
      loading.value=true
      const data={
        formalizationType:form.formalizationType,
        name:{
          uzLat:form.uzLat,
          uzCyr:form.uzCyr,
          ru:form.ru,
          en:form.en,
        },
        provision:form.provision,
        paymentMethods:form.paymentMethods,
        initialAmount:Number(form.initialAmount),
        interestRate:Number(form.interestRate),
        maxAmount:Number(form.maxAmount),
        maxDelay:Number(form.maxDelay),
        maxTerm:Number(form.maxTerm),
        minAmount:Number(form.minAmount),
        minTerm:Number(form.minTerm),
        reviewTerm:Number(form.reviewTerm),
      }
      let request;
      if (route.params.id) {
        request = http.put(`bank/credit/admin/${route.params.id}`, data);
      } else {
        http.post(`bank/credit/admin`,data)
      }
       request
          .then(() => {
            ElMessage({
              message: t("notifications.saved_successfully"),
              type: "success",
            });
            router.go(-1)
          })
          .catch(({ response: { data } }) => {
            ElMessage({
              message: data.message || "Ma'lumot yuklashda xatolik",
              type: "error",
            });
          })
          .finally(() => {
            loading.value = false;
          });
    } else {
      console.log('error submit!', fields)
    }
  })
}

const getCredit = (id) => {
  http(`/bank/credit/admin/${id}`)
      .then(({ data }) => {
        form.uzLat = data.name.uzLat;
        form.uzCyr = data.name.uzCyr;
        form.ru = data.name.ru;
        form.en = data.name.en;
        form.formalizationType = data?.formalizationType;
        form.provision = data?.provision;
        form.minAmount = data?.minAmount;
        form.maxAmount = data?.maxAmount;
        form.initialAmount = data?.initialAmount;
        form.reviewTerm = data?.reviewTerm;
        form.interestRate = data?.interestRate;
        form.minTerm = data?.minTerm;
        form.maxTerm = data?.maxTerm;
        form.maxDelay = data?.maxDelay;
        form.paymentMethods=data?.paymentMethods?.map((item)=>item.key)
      })
      .catch(({ response: { data } }) => {
        ElMessage({
          message: data.message || "Branch yuklashda xatolik!",
          type: "error",
        });
      });
};

onMounted(()=>{
  if (route.params.id) {
    // title.value = "titles.edit_branch";
    getCredit(route.params.id);
  }
})
</script>

<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl">{{ $t("titles.creditAdd") }}</h2>

      <el-button @click="() => this.$router.go(-1)">
        <el-icon>
          <IconArrowLeft/>
        </el-icon>
        <span>{{ $t("buttons.back") }}</span>
      </el-button>
    </div>
    <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
      <el-row :gutter="24">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.name') + ' (uzLat)'" prop="name.uzLat">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.name') + ' (uzLat)'"
                size="large"
                class="form-input"
                v-model="form.uzLat"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.name') + ' (uzCyr)'" prop="name.uzCyr">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.name') + ' (uzCyr)'"
                size="large"
                class="form-input"
                v-model="form.uzCyr"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.name') + ' (ru)'" prop="name.ru">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.name') + ' (ru)'"
                size="large"
                class="form-input"
                v-model="form.ru"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.name') + ' (en)'" prop="name.en">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.name') + ' (en)'"
                size="large"
                class="form-input"
                v-model="form.en"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.formalization')" prop="formalizationType">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-select
                style="width: 100%"
                class="form-select"
                size="large"
                v-model="form.formalizationType"
                :placeholder="$t('titles.formalization')"
                value-key="id"
            >
              <el-option
                  v-for="item in types"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.provision')" prop="provision">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('titles.provision')"
                size="large"
                :max="255"
                class="form-input"
                v-model="form.provision"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.paymentMethods')" prop="paymentMethods">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-select
                :multiple="true"
                style="width: 100%"
                class="form-select"
                size="large"
                v-model="form.paymentMethods"
                :placeholder="$t('titles.paymentMethods')"
                value-key="id"
            >
              <el-option
                  v-for="item in paymentMethods"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.minAmount')" prop="minAmount">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('titles.minAmount')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.minAmount"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.maxAmount')" prop="maxAmount">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('titles.maxAmount')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.maxAmount"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.interestRate')" prop="interestRate">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.interestRate')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.interestRate"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.maxDelay')" prop="maxDelay">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('titles.maxDelay')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.maxDelay"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.minTerm')" prop="minTerm">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('titles.minTerm')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.minTerm"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('titles.maxTerm')" prop="maxTerm">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('titles.maxTerm')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.maxTerm"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.reviewTerm')" prop="reviewTerm">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.reviewTerm')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.reviewTerm"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('labels.initalAmount')" prop="initialAmount">
            <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ $t(error) }}
                            </span>
            </template>
            <el-input
                :placeholder="$t('labels.initalAmount')"
                size="large"
                @keypress="(e) => changeAmount(e)"
                class="form-input"
                v-model="form.initialAmount"
                type="text"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="flex justify-end">
            <el-form-item >
              <el-button :loading="loading" type="primary" @click="onSubmit(formRef)">
                {{ $t("buttons.save") }}
              </el-button>
            </el-form-item>
          </div>

        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<style scoped>

</style>
