<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage, ElMessageBox } from "element-plus";
import { CircleCheck } from "@element-plus/icons-vue";

import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconLockUnlocked01 from "@/components/icons/security/IconLockUnlocked01.vue";
import IconSearchMd from "@/components/icons/general/IconSearchMd.vue";
import LinkedButton from "@/components/buttons/LinkedButton.vue";
import DataFlowIcon from "@/assets/images/icon-components/DataFlowIcon.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const users = ref([]);
const roleList = ref([]);
const userStatusList = ref(["ACTIVE", "INACTIVE", "BLOCKED"]);
const timeout = ref(null);
const sortPermissionGroup = ref(null);
const search = ref(route.query.search);
const sortRole = ref(route.query?.role ? parseInt(route.query?.role, 10) : null);
const sortStatus = ref(route.query.status);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const changeUserStatus = (id, status) => {
    if (users.value.find((item) => item.id === id).status !== status) {
        ElMessageBox.confirm(
            t("notifications.do_you_want_to_change_user_status"),
            t("notifications.caution"),
            {
                confirmButtonText: t("buttons.yes"),
                cancelButtonText: t("buttons.no"),
                type: "warning",
            }
        ).then(() => {
            loading.value = true;
            http.put(`user/admin/change-status/${id}`, {
                status: status,
            })
                .then(() => {
                    ElMessage({
                        message: t("notifications.user_changed_to", {
                            msg: t(`user_status.${status}`),
                        }),
                        type: "success",
                    });

                    users.value = users.value.map((item) => {
                        if (item.id === id) {
                            item.status = status;
                        }
                        return item;
                    });
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        title: t("notifications.caution"),
                        message: data.message || "Ma'lumot o'zgartirishda xatolik",
                        type: "error",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        });
    }
};

const searchUser = () => {
    clearTimeout(timeout.value);

    router.push({
        query: {
            page: currentPage.value,
            search: search.value,
            status: sortStatus.value,
            role: sortRole.value,
        },
    });
    timeout.value = setTimeout(() => {
        getUsers();
    }, 500);
};

const sortUserByStatus = (value) => {
    router.push({
        query: {
            page: currentPage.value,
            search: search.value,
            status: value,
            role: sortRole.value,
        },
    });
    getUsers();
};

const sortUserByRole = (value) => {
    router.push({
        query: {
            page: currentPage.value,
            search: search.value,
            status: sortStatus.value,
            role: value,
        },
    });
    getUsers();
};

const getRoleList = async () => {
    await http.get("user/role/admin").then(({ data }) => {
        roleList.value = data.content;
    });
};

const getUsers = async () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    await http
        .get("user/admin/find-all", {
            params: {
                page: page.value,
                size: size.value,
                search: search.value,
                status: sortStatus.value,
                roleId: sortRole.value,
                permissionGroupId: sortPermissionGroup.value,
            },
        })
        .then(({ data }) => {
            users.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
        getRoleList();
        getUsers();
    }
);

onMounted(() => {
    getRoleList();
    getUsers();
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("menu.UserList") }}</h2>

            <div class="flex items-center gap-x-2">
                <el-input
                    style="width: 170px"
                    type="text"
                    v-model="search"
                    :placeholder="t('labels.search')"
                    @keyup="searchUser"
                >
                    <template #prefix>
                        <el-icon size="18px">
                            <IconSearchMd />
                        </el-icon>
                    </template>
                </el-input>

                <el-select
                    v-model="sortStatus"
                    style="width: 170px"
                    :placeholder="t('labels.status')"
                    :clearable="true"
                    @change="sortUserByStatus"
                >
                    <el-option
                        v-for="item in userStatusList"
                        :label="t(`user_status.${item}`)"
                        :value="item"
                        :key="item"
                    />
                </el-select>

                <el-select
                    v-model="sortRole"
                    style="width: 170px"
                    :placeholder="t('labels.rol')"
                    :clearable="true"
                    @change="sortUserByRole"
                >
                    <el-option
                        v-for="item in roleList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-select>

                <el-tooltip :content="t('buttons.add')" placement="top">
                    <el-button type="primary" @click="router.push({ name: 'UserFormAdd' })">
                        <el-icon>
                            <IconPlus />
                        </el-icon>
                    </el-button>
                </el-tooltip>
            </div>
        </div>

        <el-table
            border
            :data="users"
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
            :empty-text="t('notifications.information_not_available')"
        >
            <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

            <el-table-column prop="name" :label="t('labels.name')" align="center" />

            <el-table-column
                prop="username"
                :label="t('labels.username')"
                align="center"
                width="180"
            />

            <el-table-column prop="active" :label="t('labels.status')" align="center">
                <template v-slot="scope">
                    <div>
                        <el-dropdown trigger="click">
                            <el-tag
                                :type="
                                    scope.row.status === 'ACTIVE'
                                        ? 'success'
                                        : scope.row.status === 'INACTIVE'
                                          ? 'warning'
                                          : scope.row.status === 'BLOCKED'
                                            ? 'danger'
                                            : ''
                                "
                                class="cursor-pointer select-none transition-colors hover:bg-[#d9ecff]"
                                round
                            >
                                {{ t(`user_status.${scope.row.status}`) }}
                            </el-tag>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        v-for="item in userStatusList"
                                        :key="item"
                                        :class="
                                            scope.row.status === item
                                                ? 'text-blue-500 font-medium'
                                                : ''
                                        "
                                        @click="changeUserStatus(scope.row.id, item)"
                                    >
                                        <el-icon style="width: 14px">
                                            <CircleCheck v-if="scope.row.status === item" />
                                        </el-icon>
                                        {{ t(`user_status.${item}`) }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="role" :label="t('labels.role')" align="center">
                <template v-slot="scope">
                    <el-space>
                        <el-tag round>{{ scope.row.role.name }}</el-tag>
                    </el-space>
                </template>
            </el-table-column>

            <el-table-column
                prop="permissionGroup"
                :label="t('labels.permission_group')"
                align="center"
            >
                <template v-slot="scope">
                    <el-tag class="whitespace-normal inline-block h-auto leading-normal">
                        {{ scope.row.permissionGroup.name }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column :label="t('labels.actions')" align="center">
                <template #default="scope">
                    <el-tooltip :content="t('buttons.editPassword')" placement="top">
                        <el-button
                            type="primary"
                            plain
                            @click="
                                router.push({
                                    name: 'ChangeUserPassword',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconLockUnlocked01 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>

                    <el-tooltip :content="t('buttons.edit')" placement="top" effect="dark">
                        <el-button
                            type="warning"
                            plain
                            @click="
                                router.push({
                                    name: 'UserEdit',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            <el-icon>
                                <IconEdit05 />
                            </el-icon>
                        </el-button>
                    </el-tooltip>

                    <el-tooltip v-if="scope.row.role.scopes?.findIndex((item)=>item.key === 'ADMIN') > -1" :content="t('titles.branchAttach')" placement="top" effect="dark">
                      <linked-button class="ml-2"  plain route="BranchAttach" :params="{userId: scope.row.id}" :query="{name: scope.row.name}">
                        <DataFlowIcon/>
                      </linked-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-4">
            <template v-if="totalElements">
                <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
            </template>

            <el-pagination
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="totalElements"
                :page-size="size"
                :page-count="totalPages"
                :hide-on-single-page="true"
                :background="true"
            />
        </div>
    </div>
</template>
