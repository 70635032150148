<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import http from "@/http";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { Plus } from "@element-plus/icons-vue";
import store from "@/store";

const { t } = useI18n();
const loading = ref(false);
const formRef = ref();
const id = ref(null);
const router = useRouter();
const route = useRoute();

const form = ref({
    name: {
        uzLat: null,
        uzCyr: null,
        ru: null,
        en: null,
    },
    image: null,
});

const rules = ref({
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "codeAlfa.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "codeAlfa.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "codeAlfa.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "codeAlfa.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    image: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});
const imageUrl = ref("");
const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`;
const uploadHeaders = {
    Authorization: `Bearer ${store.state.accessToken}`,
};

const handleAvatarSuccess = (response, uploadFile) => {
    imageUrl.value = URL.createObjectURL(uploadFile.raw);
    form.value.image = response?.hashId;
};
const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.put(`account/currency-code/${id.value}`, form.value)
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });

                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || t("notifications.error_occurred_while_saving"),
                        type: "error",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};
const getCurrencyCode = () => {
    loading.value = true;
    http.get(`account/currency-code/${id.value}`)
        .then(({ data }) => {
            form.value = data;
            imageUrl.value = data.hashId ? `${baseUrl}/file/download?hashId=${data?.hashId}` : "";
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};
onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getCurrencyCode();
    }
});
</script>

<template>
    <div class="main-data" v-loading="loading">
        <div class="page-header">
            <h3>{{ $t("titles.edit_currency_code") }}</h3>
        </div>
        <el-divider border-style="double" style="margin-top: 10px" />
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.uzbek')})`"
                                prop="name.uzLat"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="form.name.uzLat"
                                    type="text"
                                    :placeholder="$t('labels.enter_name')"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.cyrill')})`"
                                prop="name.uzCyr"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="form.name.uzCyr"
                                    type="text"
                                    :placeholder="$t('labels.enter_name')"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.russian')})`"
                                prop="name.ru"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="form.name.ru"
                                    type="text"
                                    :placeholder="$t('labels.enter_name')"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item
                                :label="`${$t('labels.name')}(${$t('langs.english')})`"
                                prop="name.en"
                            >
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-input
                                    v-model="form.name.en"
                                    type="text"
                                    :placeholder="$t('labels.enter_name')"
                                    size="large"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item :label="$t('labels.image')" prop="image">
                                <template #error="{ error }">
                                    <span class="el-form-item__error">
                                        {{ $t(error) }}
                                    </span>
                                </template>
                                <el-upload
                                    class="avatar-uploader"
                                    :action="baseUrl + '/file/upload'"
                                    :headers="uploadHeaders"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                >
                                    <img v-if="imageUrl" :src="imageUrl" class="image" alt="" />
                                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                                </el-upload>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item>
                                <el-button class="custom-btn" @click="() => $router.go(-1)">{{
                                    $t("buttons.back")
                                }}</el-button>
                                <el-button
                                    class="custom-btn"
                                    type="primary"
                                    @click="onSubmit(formRef)"
                                    >{{ $t("buttons.save") }}</el-button
                                >
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.avatar-uploader .image {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
