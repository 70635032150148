export const dateFormat = (millisecond) => {
    const date = new Date();
    date.setTime(millisecond);
    return `${date.toLocaleString("en-GB").slice(0, 2)}.${date
        .toLocaleString("en-GB")
        .slice(3, 5)}.${date.toLocaleString("en-GB").slice(6, 10)}`;
};

export const formatTime = (millisecond) => {
    const hours = Math.floor(millisecond / 60 / 60 / 1000);
    const seconds = Math.round(((millisecond / 60 / 60 / 1000) % 1) * 60);
    return `${hours ? (hours < 10 ? `0${hours}` : hours) : "00"}:${
        hours ? (seconds < 10 ? `0${seconds}` : seconds) : "00"
    }`;
};
