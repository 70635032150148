import { ElMessage } from "element-plus";
import { messages, selectedLocale } from "@/i18n/i18n";

export const errorMessage = (error) => {
    const status = error?.response?.status;
    try {
        if (status === 400) {
            ElMessage({
                message:
                    error?.response?.data?.error_description ||
                    error?.response?.data?.message ||
                    messages[selectedLocale.value]["notifications"]["bad_request"],
                type: "error",
            });
            return;
        } else if (status === 403) {
            ElMessage({
                message:
                    error?.response?.data?.error_description ||
                    error?.response?.data?.message ||
                    messages[selectedLocale.value]["notifications"]["forbidden"],
                type: "error",
            });
            return;
        } else if (status === 404) {
            ElMessage({
                message:
                    error?.response?.data?.error_description ||
                    error?.response?.data?.message ||
                    messages[selectedLocale.value]["notifications"]["data_not_found"],
                type: "error",
            });
            return;
        } else if (status === 500) {
            ElMessage({
                message: messages[selectedLocale.value]["notifications"]["internal_server_error"],
                type: "error",
            });
            return;
        } else if (status === 503) {
            ElMessage({
                message: messages[selectedLocale.value]["notifications"]["internal_server_error"],
                type: "error",
            });
            return;
        }

        ElMessage({
            message: error?.response?.data?.error_description || error?.response?.data?.message,
            type: "error",
        });
    } catch (error) {
        console.error(error);
    }
};
