<script setup>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const formRef = ref();
const loading = ref(false);
const id = ref(null);

const form = reactive({
    name: {
        en: null,
        ru: null,
        uzCyr: null,
        uzLat: null,
    },
});

const onSubmit = (formRef) => {
    formRef.validate((valid) => {
        loading.value = true;
        if (valid) {
            http.put(`account/uzb-bank/${id.value}`, form)
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: "Muvaffaqiyatli saqlandi!",
                    });
                    router.go(-1);
                })
                .catch(({ response: { data } }) => {
                    ElMessage({
                        message: data.message || "Ma'lumot saqlashda xatolik",
                        type: "error",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
};

const getUzbBank = () => {
    loading.value = true;
    http(`account/uzb-bank/${id.value}`)
        .then(({ data }) => {
            form.name = data.name;
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    if (route.params.id) {
        id.value = route.params.id;
        getUzbBank();
    }
});

const rules = reactive({
    "name.en": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.ru": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzCyr": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    "name.uzLat": [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
    mfo: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "blur",
        },
    ],
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ route.params.id ? t("titles.edit_bank") : t("titles.add_new_bank") }}
            </h2>

            <el-button @click="() => $router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>
                    {{ t("buttons.back") }}
                </span>
            </el-button>
        </div>

        <el-form
            ref="formRef"
            :model="form"
            :rules="rules"
            label-position="top"
            v-loading="loading"
        >
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.english')})`"
                        prop="name.en"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.en"
                            type="text"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.russian')})`"
                        prop="name.ru"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.ru"
                            type="text"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.cyrill')})`"
                        prop="name.uzCyr"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.uzCyr"
                            type="text"
                        />
                    </el-form-item>
                </div>

                <div>
                    <el-form-item
                        :label="`${t('labels.name')}(${t('langs.uzbek')})`"
                        prop="name.uzLat"
                    >
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            size="large"
                            class="form-input"
                            v-model="form.name.uzLat"
                            type="text"
                        />
                    </el-form-item>
                </div>
            </div>

            <div class="flex items-center justify-end mt-2">
                <el-button type="primary" @click="onSubmit(formRef)">
                    {{ t("buttons.save") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
