<script setup>
import DownloadIcon from "@/assets/images/icon-components/DownloadIcon.vue";
import FileIcon from "@/assets/images/icon-components/FileIcon.vue";
import http from "@/http";
import { ElMessage } from "element-plus";
import { ref } from "vue";

const props = defineProps({
    fileInfo: {
        required: true,
        type: Object,
    },
});

const loading = ref(false);
const downloadFile = () => {
    if (!loading.value) {
        loading.value = true;
        http.get(`file/download`, {
            params: {
                hashId: props.fileInfo.hashId,
            },
            responseType: "blob",
        })
            .then((response) => {
                const url = URL.createObjectURL(
                    new Blob([response.data], {
                        type: response.headers["content-type"],
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", props.fileInfo.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(({ response: { data } }) => {
                ElMessage({
                    message: data.message || "Ma'lumot yuklashda xatolik",
                    type: "error",
                });
            })
            .finally(() => {
                loading.value = false;
            });
    }
};

const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KiB", "MiB", "GiB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
</script>

<template>
    <div
        class="file-message"
        @click="downloadFile"
        v-loading.fullScreen="loading"
        element-loading-background="rgba(134, 134, 134, 0.47)"
    >
        <div class="file-icon">
            <FileIcon class="icon-file" />
            <DownloadIcon class="icon-download" />
        </div>
        <div style="flex-grow: 1; overflow: hidden">
            <div class="file-name">{{ fileInfo.name }}</div>
            <div class="file-size">{{ formatBytes(fileInfo.size) }}</div>
        </div>
    </div>
</template>

<style scoped>
.file-message {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;
    padding: 5px;
    width: 100%;
}

.file-icon {
    height: 38px !important;
    width: 38px !important;
    flex-basis: 38px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-file {
        display: block;
    }

    .icon-download {
        display: none;
    }
}

.file-message:hover .file-name {
    text-decoration: underline;
}

.file-message:hover .icon-file {
    display: none;
}

.file-message:hover .icon-download {
    display: block;
}

.file-name {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.file-size {
    color: #dcdbdb;
    text-decoration: none !important;
    font-size: 12px;
}
</style>
