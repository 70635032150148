import i18n, { selectedLocale } from "@/i18n/i18n";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import http from "@/http";

const store = createStore({
    state: {
        user: {},
        collapsed: false,
        headerTitle: "Bosh sahifa",
        pageTitle: "",
        locale: selectedLocale,
        userBranches: [],
        bSession: null,

    },
    mutations: {
        setUniversal: (state, action) => {
            const { key = "data", payload } = action;
            return (state[key] = payload);
        },

        authenticate(state, tokenResponse) {
            let { access_token, refresh_token } = tokenResponse;
            localStorage.setItem("accessToken", access_token);
            localStorage.setItem("refreshToken", refresh_token);
        },
        signOut(state) {
            state.user = {};
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/";
        },
        userData(state, userData) {
            state.user = userData;
        },
        updateLocale(state, newLocale) {
            state.locale = newLocale;
        },
        updateCollapsed(state, newCollapse) {
            state.collapsed = newCollapse;
        },
        updateHeaderTitle(state, title) {
            state.headerTitle = title;
        },
        updatePageTitle(state, name) {
            state.pageTitle = name;
        },
        updateUserBranches(state, branches){
            state.userBranches = branches
        },
        updateBranchSession(state, bSession){
            state.bSession = bSession
        }
    },
    actions: {
        changeLocale(ctx, newLocale) {
            i18n.global.locale = newLocale;
            ctx.commit("updateLocale", newLocale);
        },
        getUserBranches(ctx){
            return new Promise((resolve, reject) => {
                http.get("user/branch").then(({data})=>{
                    ctx.commit('updateUserBranches', data)
                    if (!ctx.state.bSession && data.length){
                        ctx.dispatch('selectBranchSession', data[0].mfo)
                    }
                    resolve(data)
                }).catch((err)=>reject(err))
            })
        },
        selectBranchSession(ctx, mfo){
            return new Promise((resolve, reject) => {
                http.get(`auth/b-session/${mfo}`).then(({data})=>{
                    ctx.commit('updateBranchSession', data)
                    resolve(data)
                }).catch((err)=>reject(err))
            })
        },
        GetBSessionInfo(ctx){
            return new Promise((resolve, reject) => {
                http.get(`auth/b-session/info`).then(({data})=>{
                    console.log(data)
                    resolve(data)
                }).catch((err)=>reject(err))
            })
        }
    },
    plugins: [
        createPersistedState({
            key: "console-davr-business",
            paths: [
                "collapsed",
                "headerTitle",
                "locale",
                "user",
                "bSession"
            ],
        }),
    ],
});

export default store;
