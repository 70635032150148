<script setup>
import http from "@/http";
import { onMounted, reactive, ref, toRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { ElNotification } from "element-plus";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const loading = ref(false);
const fileInfo = ref(null);
const formRef = ref();
const error = ref(null);
const form = reactive({
    absId: null,
});
const showForm = ref();

const rule = [
    {
        required: true,
        message: t("validations.required_field"),
        trigger: "change",
    },
];
const getPrtFileInfo = () => {
    loading.value = true;
    http.get(`account/swift/admin/files/${route.params.id}`)
        .then(({ data }) => {
            fileInfo.value = data;
        })
        .catch(({ response }) => {
            if (response && response.data?.code === 599) showForm.value = true;
        })
        .finally(() => {
            loading.value = false;
        });
};

const submitAbsId = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.post("account/swift/link-prt-file", toRaw(form))
                .then(() => {
                    ElNotification.success({ message: "ABS-ID muvaffaqiyatli bog'landi!" });
                    getPrtFileInfo();
                    showForm.value = false;
                })
                .catch(() => {
                    error.value =
                        "ABS-ID bog'lash jarayoni amalga oshmadi. Iltimos qayta urinib ko'ring";
                })
                .finally(() => (loading.value = false));
        }
    });
};

onMounted(() => {
    getPrtFileInfo();
});
</script>

<template>
    <div v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">
                {{ t("titles.prtFIleInfo") }}
            </h2>

            <el-button @click="router.go(-1)">
                <el-icon>
                    <IconArrowLeft />
                </el-icon>
                <span>{{ t("buttons.back") }}</span>
            </el-button>
        </div>
        <el-descriptions direction="vertical" :column="4" size="large" v-if="fileInfo" border>
            <el-descriptions-item :label="$t('labels.amount')">
                {{ fileInfo.amount.toLocaleString("ru") }} {{ fileInfo.codeAlfa }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('labels.date_of_dispatch')">
                {{ dayjs(fileInfo.departureDate).format("DD.MM.YYYY") }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('labels.conductor')">
                {{ fileInfo.bicFrom || "-" }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('labels.receiver')">
                {{ fileInfo.bicTo || "-" }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('reference')">
                {{ fileInfo.reference || "-" }}
            </el-descriptions-item>
        </el-descriptions>

        <div v-if="showForm">
            <el-alert class="mt-5" v-if="error" type="error" :title="error"></el-alert>
            <el-alert
                v-else
                :closable="false"
                type="warning"
                :title="
                    $t(
                        'notifications.there_is_no_ABS_ID_associated_with_this_file_enter_your_ABS_ID_in_the_field_below_to_link'
                    )
                "
            ></el-alert>
            <el-form :model="form" ref="formRef" class="mt-5" label-position="top">
                <el-form-item label="ABS-ID" :rules="rule">
                    <el-input
                        v-model="form.absId"
                        class="max-w-60"
                        :placeholder="$t('placeholders.input.enter_ABS_ID')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button @click="submitAbsId" type="primary"
                        >{{ $t("buttons.save") }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<style scoped></style>
