<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";
import IconEye from "@/components/icons/general/IconEye.vue";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";
import { Search } from "@element-plus/icons-vue";
import { debounce } from "@/helpers";

const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`;

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const loadingRefresh = ref(false);
const currencyData = ref([]);
const searchInput = ref(route.query?.search || null);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const getCurrencyCodeList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("account/currency-code", {
        params: {
            size: size.value,
            page: currentPage.value - 1,
            search: route.query?.search,
        },
    })
        .then(({ data }) => {
            currencyData.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const changeCurrencyCodeStatus = (id) => {
    loading.value = true;
    http.put(`account/currency-code/change-active/${id}`)
        .then(({ data }) => {
            getCurrencyCodeList();
            ElMessage({
                message: t("notifications.currency_changed_to_msg", {
                    msg: t(`currency_status.${data ? "ACTIVE" : "INACTIVE"}`),
                }),
                type: "success",
            });
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message:
                    data.message ||
                    t("notifications.error_occurred_while_changing_the_currency_status"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const refreshCurrencyData = () => {
    loadingRefresh.value = true;
    http.get(`account/currency-code/refresh`)
        .then(() => {
            getCurrencyCodeList();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};
const onSearch = () => {
    loading.value = true;
    router.push({ query: { page: 1, search: searchInput.value } });
    http.get("account/currency-code", {
        params: {
            search: searchInput.value,
            size:10,
            page:0
        },
    })
        .then(({ data }) => {
            currencyData.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};
watch(
    () => currentPage.value,
    () => {
        getCurrencyCodeList();
    }
);

onMounted(() => {
    getCurrencyCodeList();
});
</script>

<template>
    <div>
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("menu.CurrencyCodeList") }}</h2>
            <div class="flex gap-2">
                <div>
                    <el-input v-model="searchInput" :placeholder="t('labels.search')"
                              :prefix-icon="Search"
                              @input="debounce('search code', onSearch)" />
                </div>
                <el-tooltip :content="t('buttons.refresh')" placement="top-start">
                    <el-button @click="refreshCurrencyData">
                        <template v-if="loadingRefresh">
                            <el-icon>
                                <IconLoading02 />
                            </el-icon>
                        </template>
                        <template v-else>
                            <el-icon>
                                <IconRefreshCW01 />
                            </el-icon>
                        </template>
                    </el-button>
                </el-tooltip>
            </div>

        </div>

        <div v-loading="loading">
            <el-table
                :data="currencyData"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column prop="name" :label="t('labels.name')" align="center" />

                <el-table-column prop="image" :label="t('labels.image')" align="center">
                    <template #default="scope">
                        <div class="w-[32px] h-[32px] mx-auto">
                            <img
                                v-if="scope.row.hashId"
                                :src="`${baseUrl}/file/download?hashId=${scope.row.hashId}`"
                            />
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="codeNumber" :label="t('labels.code')" align="center" />

                <el-table-column prop="codeAlfa" :label="t('labels.ico_code')" align="center" />

                <el-table-column prop="status" :label="t('labels.status')" align="center">
                    <template #default="scope">
                        <el-space>
                            <el-switch
                                v-model="scope.row.active"
                                @change="changeCurrencyCodeStatus(scope.row.id)"
                                style="
                                    --el-switch-on-color: #13ce66;
                                    --el-switch-off-color: #ff4949;
                                "
                            />
                        </el-space>
                    </template>
                </el-table-column>

                <el-table-column prop="codeNumber" :label="t('labels.actions')" align="center">
                    <template #default="scope">
                        <el-tooltip :content="t('buttons.edit')" placement="left-start">
                            <el-button
                                type="warning"
                                plain
                                @click="
                                    router.push({
                                        name: 'CurrencyCodeForm',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEdit05 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            :content="t('buttons.view')"
                            placement="top-start"
                            effect="dark"
                        >
                            <el-button
                                type="primary"
                                plain
                                @click="
                                    router.push({
                                        name: 'CurrencyRateView',
                                        params: {
                                            by: 'code',
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEye />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-2">
                <template v-if="totalElements">
                    <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>
                </template>

                <el-pagination
                    :current-page="currentPage"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="totalElements"
                    :page-size="size"
                    :page-count="totalPages"
                    :hide-on-single-page="true"
                    :background="true"
                />
            </div>
        </div>
    </div>
</template>
