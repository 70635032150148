<template>
    <el-menu
        :default-active="$route.path"
        class="bg-[#f2f3f580] max-h-[calc(100vh-190px)] pb-12 overflow-y-auto overflow-x-hidden rounded-s-xl border-none"
        router
        :collapse="isCollapsed"
        :collapse-transition="false"
        v-if="roles.length"
    >
        <template v-for="(item, index) in nav">
            <el-menu-item
                class="gap-x-2 border-b"
                :key="index"
                v-if="isShowMenu(item)"
                :index="`/app/${item.path}`"
            >
                <img :src="item.imgUrl" :alt="item.title" />
                <span class="font-medium">{{ $t(`menu.${item.name}`) }}</span>
            </el-menu-item>

            <el-sub-menu
                class="border-b"
                :key="item.path"
                v-if="isShowSubMenu(item)"
                :index="index.toString()"
            >
                <template #title>
                    <div class="flex items-center gap-x-2">
                        <img :src="item.imgUrl" :alt="item?.title" />
                        <span class="font-medium">{{ $t(`menu.${item?.name}`) }}</span>
                    </div>
                </template>
                <template v-for="menu in item.children" :key="menu?.title">
                    <el-menu-item
                        class="flex items-center gap-x-2 bg-[#f2f3f580]"
                        v-if="isShowMenuItem(item, menu)"
                        :key="menu.path"
                        :index="`/app/${menu.path}`"
                    >
                        <img :src="menu.imgUrl" :alt="menu.title" />
                        <span class="font-medium">{{ $t(`menu.${menu.name}`) }}</span>
                    </el-menu-item>
                </template>
            </el-sub-menu>
        </template>
    </el-menu>
</template>

<script setup>
import { computed } from "vue";
import store from "@/store";
import Navigations from "../router/navigation";
import { checkPermission } from "@/composables";

const isCollapsed = computed(() => store.state.collapsed);
const nav = computed(() => Navigations);

const roles = computed(() =>
    Object.keys(store.state.user).length ? store.state.user.role.key.toUpperCase() : []
);

const isShowMenu = (route) => {
    let authorize = route.meta.authorize.includes(store.state.user.role.key);
    let list = [];

    route.meta.permission.forEach((item) => {
        list.push(checkPermission(item));
    });
    let permission = list.includes(true);
    return (
        !route.isSubmenu && route.main && (permission || authorize || !route.meta.permission.length)
    );
};

const isShowSubMenu = (route) => {
    let authorize = route.meta.authorize.includes(store.state.user.role.key);
    let list = [];

    route.meta.permission.forEach((item) => {
        list.push(checkPermission(item));
    });
    let permission = list.includes(true);
    return route.isSubmenu && (permission || authorize || !route.meta.permission.length);
};

const isShowMenuItem = (route, menu) => {
    let authorize = menu.meta.authorize.includes(store.state.user.role.key);
    let list = [];

    menu.meta.permission.forEach((item) => {
        list.push(checkPermission(item));
    });
    let permission = list.includes(true);
    return menu.main && (permission || authorize || !route.meta.permission.length);
};
</script>
