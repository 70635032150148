<script setup>
import { onMounted, ref } from 'vue'
import LinkedButton from '@/components/buttons/LinkedButton.vue'
import http from '@/http'

const loading = ref(false)
const regionsList = ref([])

const getRegionsList = () => {
  loading.value = true
  http
    .get('account/uzb-bank/regions')
    .then(({ data }) => {
      regionsList.value = data.content
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  getRegionsList()
})
</script>

<template>
  <div class="page-header">
    <h3>{{ $t('titles.territories') }}</h3>
  </div>
  <div class="main-data" v-loading="loading">
    <el-table
      class-name="table-custom-cls"
      :data="regionsList"
      stripe
      style="width: 100%"
      height="calc(100vh - 180px)"
      :empty-text="$t('notifications.information_not_available')"
      table-layout="auto"
      border
    >
      <el-table-column type="index" label="№" />
      <el-table-column prop="name" :label="$t('labels.name')" />
      <el-table-column prop="status" :label="$t('labels.actions')" width="100">
        <template #default="scope">
          <el-space>
            <linked-button
              route="DistrictsList"
              size="small"
              :params="{ id: scope.row.id }"
            >
              {{ $t('buttons.districts') }}
            </linked-button>
            <linked-button
              route="RegionsAndDistrictEditForm"
              size="small"
              :params="{ type: 'region', id: scope.row.id }"
            >
              {{ $t('buttons.edit') }}
            </linked-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped>
.main-data {
  background-color: #ffffff;
  padding-top: 20px;
}
</style>
