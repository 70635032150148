<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessageBox, ElMessage } from "element-plus";
import IconPlus from "@/components/icons/general/IconPlus.vue";
import IconTrash01 from "@/components/icons/general/IconTrash01.vue";
import IconEdit05 from "@/components/icons/general/IconEdit05.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const baseUrl = `${process.env.VUE_APP_BASE_API_URL}${process.env.VUE_APP_BASE_API_V}`;

const instruction = ref([]);
const loading = ref(false);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(8);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const getInstructions = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("bank/instruction", {
        params: {
            page: page.value,
            size: size.value,
        },
    })
        .then(({ data }) => {
            instruction.value = data?.content;
            totalElements.value = data?.totalElements;
            totalPages.value = data?.totalPages;
        })
        .finally(() => {
            loading.value = false;
        });
};

const deleteInstruction = (id) => {
    ElMessageBox.confirm(t("notifications.delete_data"), t("notifications.attention"), {
        confirmButtonText: t("buttons.yes"),
        cancelButtonText: t("buttons.cancellation"),
        type: "warning",
    }).then(() => {
        loading.value = true;
        http.delete(`bank/instruction/${id}`)
            .then(() => {
                ElMessage({
                    message: t("notifications.deleted_successfully"),
                    type: "success",
                });
                getInstructions();
            })
            .finally(() => {
                loading.value = false;
            });
    });
};

// watch(
//     () => currentPage.value,
//     () => {
//         getInstructions();
//     }
// );

onMounted(() => {
    getInstructions();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("menu.InstructionListPage") }}</h2>

            <el-tooltip :content="t('buttons.add')" placement="top">
                <el-button
                    type="primary"
                    @click="router.push({ name: 'InstructionCreateFormPage' })"
                >
                    {{ t("buttons.add") }}
                    <el-icon>
                        <IconPlus />
                    </el-icon>
                </el-button>
            </el-tooltip>
        </div>

        <div v-loading="loading">
            <el-table
                :data="instruction"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column prop="name" :label="t('labels.instruction_name')" align="center" />
                <el-table-column prop="name" width="250" :label="t('labels.content')" align="center">
                    <template #default="scope">
                        <a
                            v-if="scope.row.type === 'FILE'"
                            class="underline"
                            :href="`${baseUrl}/file/download?hashId=${scope.row.file?.hashId}`"
                            download
                            target="_blank"
                            rel="opener"
                            >{{ scope.row.file?.name }}</a
                        >
                      <a v-else-if="scope.row.type === 'LINK'" :href="scope.row.text" target="_blank">
                        {{scope.row.text}}
                      </a>
                      <span v-else>
                        {{scope.row.text}}
                      </span>
                    </template>
                </el-table-column>

                <el-table-column prop="key" :label="t('labels.key')" align="center" />
                <el-table-column prop="type" :label="t('labels.type')" align="center" />

                <el-table-column :label="t('labels.settings')" align="center">
                    <template #default="scope">
                        <el-tooltip :content="t('buttons.edit')" placement="left-start">
                            <el-button
                                type="warning"
                                plain
                                @click="
                                    router.push({
                                        name: 'InstructionEditFormPage',
                                        params: {
                                            id: scope.row.id,
                                        },
                                    })
                                "
                            >
                                <el-icon>
                                    <IconEdit05 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            :content="t('buttons.delete')"
                            placement="right-start"
                            effect="dark"
                        >
                            <el-button
                                type="danger"
                                plain
                                @click.prevent="deleteInstruction(scope.row.id)"
                            >
                                <el-icon>
                                    <IconTrash01 />
                                </el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <div class="flex items-center justify-between mt-4">
                <!--        <template v-if="totalElements">-->
                <!--          <p class="font-semibold">{{ t("labels.total") }}: {{ totalElements }}</p>-->
                <!--        </template>-->

                <!--        <el-pagination-->
                <!--            :current-page="currentPage"-->
                <!--            @current-change="handleCurrentChange"-->
                <!--            layout="prev, pager, next"-->
                <!--            :total="totalElements"-->
                <!--            :page-size="size"-->
                <!--            :page-count="totalPages"-->
                <!--            :hide-on-single-page="true"-->
                <!--            :background="true"-->
                <!--        />-->
            </div>
        </div>
    </div>
</template>
