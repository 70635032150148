<script setup>

</script>

<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.59998 15.2197L3.59998 18.9256C3.59998 19.4873 3.82123 20.0259 4.21505 20.423C4.60888 20.8202 5.14302 21.0433 5.69998 21.0433H18.3C18.8569 21.0433 19.3911 20.8202 19.7849 20.423C20.1787 20.0259 20.4 19.4872 20.4 18.9256V15.2197M12.0011 2.95654V14.9565M12.0011 14.9565L16.8011 10.3714M12.0011 14.9565L7.20115 10.3714" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>