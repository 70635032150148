<template>
    <div class="p-2" v-loading="loading">
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("titles.paymentTermsEdit") }}</h2>

            <el-button @click="router.go(-1)">
                <el-icon class="mr-2">
                    <IconArrowLeft />
                </el-icon>
                {{ t("buttons.back") }}
            </el-button>
        </div>

        <div>
            <el-form ref="formRef" :model="formState" :rules="rules" label-position="top">
                <div class="grid grid-cols-4 gap-4">
                    <el-form-item :label="t('labels.names.uz_lat')" prop="label.uzLat">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="formState.label.uzLat"
                            :placeholder="t('labels.enter_name')"
                        />
                    </el-form-item>

                    <el-form-item :label="t('labels.names.uz_cyr')" prop="label.uzCyr">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="formState.label.uzCyr"
                            :placeholder="t('labels.enter_name')"
                        />
                    </el-form-item>

                    <el-form-item :label="t('labels.names.ru')" prop="label.ru">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="formState.label.ru"
                            :placeholder="t('labels.enter_name')"
                        />
                    </el-form-item>

                    <el-form-item :label="t('labels.names.en')" prop="label.en">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="formState.label.en"
                            :placeholder="t('labels.enter_name')"
                        />
                    </el-form-item>

                    <el-form-item :label="t('labels.enter_description')" prop="latinLabel">
                        <template #error="{ error }">
                            <span class="el-form-item__error">
                                {{ t(error) }}
                            </span>
                        </template>
                        <el-input
                            type="text"
                            v-model="formState.latinLabel"
                            :placeholder="t('labels.enter_description')"
                        />
                    </el-form-item>
                </div>

                <div class="flex items-center justify-end">
                    <el-button type="primary" @click="onSubmit(formRef)">
                        {{ t("buttons.save") }}
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import IconArrowLeft from "@/components/icons/arrows/IconArrowLeft.vue";
import { ElMessage } from "element-plus";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const loading = ref(false);
const formRef = ref();
const formState = ref({
    label: {
        uzLat: null,
        uzCyr: null,
        en: null,
        ru: null,
    },
    latinLabel: null,
});
const rules = ref({
    label: {
        uzLat: [
            {
                required: true,
                message: "validations.required_field",
                trigger: "change",
            },
            {
                max: 128,
                message: "validations.the_maximum_number_of_characters_is_128",
                trigger: "change",
            },
        ],
        uzCyr: [
            {
                required: true,
                message: "validations.required_field",
                trigger: "change",
            },
            {
                max: 128,
                message: "validations.the_maximum_number_of_characters_is_128",
                trigger: "change",
            },
        ],
        ru: [
            {
                required: true,
                message: "validations.required_field",
                trigger: "change",
            },
            {
                max: 128,
                message: "validations.the_maximum_number_of_characters_is_128",
                trigger: "change",
            },
        ],
        en: [
            {
                required: true,
                message: "validations.required_field",
                trigger: "change",
            },
            {
                max: 128,
                message: "validations.the_maximum_number_of_characters_is_128",
                trigger: "change",
            },
        ],
    },
    latinLabel: [
        {
            required: true,
            message: "validations.required_field",
            trigger: "change",
        },
    ],
});

function getPaymentTerm() {
    loading.value = true;
    http.get(`account/payment-terms-admin/${route.params.id}`)
        .then(({ data }) => {
            formState.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
}

async function onSubmit(formEl) {
    if (!formEl) return;
    await formEl.validate((valid) => {
        if (valid) {
            loading.value = true;
            http.put(`account/payment-terms-admin/${route.params.id}`, {
                ...formState.value,
            })
                .then(() => {
                    ElMessage({
                        message: t("notifications.saved_successfully"),
                        type: "success",
                    });
                    router.go(-1);
                    getPaymentTerm();
                })
                .finally(() => {
                    loading.value = false;
                });
        }
    });
}

onMounted(() => {
    getPaymentTerm();
});
</script>
