<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import http from "@/http";
import { useI18n } from "vue-i18n";
import IconPlus from "@/components/icons/general/IconPlus.vue";

const router = useRouter();
const { t } = useI18n();

const loading = ref(false);
const item = ref(null);

const getOne = () => {
    loading.value = true;
    http.get("user/power-attorney-template/admin")
        .then(({ data }) => {
            item.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    getOne();
});
</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between mb-4">
            <h2>{{ t("titles.offerta") }}</h2>

            <template v-if="!item && !loading">
                <el-button
                    @click="
                        router.push({
                            name: 'OffertaFormCreateView',
                        })
                    "
                    type="primary"
                >
                    <el-icon size="18px">
                        <IconPlus />
                    </el-icon>
                    {{ t("buttons.add") }}
                </el-button>
            </template>
        </div>

        <div class="main-data" v-loading="loading">
            <el-table
                :data="[item]"
                stripe
                table-layout="auto"
                border
                :empty-text="t('notifications.information_not_available')"
                :header-cell-style="{
                    color: '#111',
                    textAlign: 'center',
                }"
            >
                <el-table-column type="index" label="#" align="center" />

                <el-table-column prop="text.uzLat" :label="t('langs.uzbek')" align="center">
                    <template #default="scope">
                        <div
                            :style="{
                                width: '160px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }"
                        >
                            {{ scope.row.text.uzLat }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="text.cyrill" :label="t('langs.cyrill')" align="center">
                    <template #default="scope">
                        <div
                            :style="{
                                width: '160px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }"
                        >
                            {{ scope.row.text.uzCyr }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="text.en" :label="t('langs.english')" align="center">
                    <template #default="scope">
                        <div
                            :style="{
                                width: '160px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }"
                        >
                            {{ scope.row.text.en }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="text.ru" :label="t('langs.russian')" align="center">
                    <template #default="scope">
                        <div
                            :style="{
                                width: '160px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }"
                        >
                            {{ scope.row.text.ru }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="actions" :label="t('labels.actions')" align="center">
                    <template #default="scope">
                        <el-button
                            type="primary"
                            size="small"
                            @click="
                                router.push({
                                    name: 'OffertaFormEditView',
                                    params: {
                                        id: scope.row.id,
                                    },
                                })
                            "
                        >
                            {{ t("buttons.edit") }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
