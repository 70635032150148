<template>
  <header class="bg-white" v-loading.fullscreen="loading">
    <div class="px-4">
      <div class="flex items-center justify-between py-4 gap-4 flex-wrap">
        <div class="flex items-center gap-x-2">
          <img src="../assets/images/short-logo.svg" alt="Davr Bank logo"/>
          <span
              class="font-semibold text-[#313131] text-xl uppercase hidden md:inline-block"
          >
                        Davr-Business
                    </span>
        </div>

        <div class="flex items-center gap-x-4">
          <bank-open-days-component/>
          <el-select
              style="width: 240px"
              v-model="selectedLocale"
              @change="handleLangChange"
              class="lang-select"
          >
            <el-option
                v-for="item in languages"
                :key="item.value"
                :value="item.value"
                :label="item.label"
            />
          </el-select>

          <el-popover placement="bottom" :width="400" trigger="click">
            <template #reference>
              <div
                  style="width: 300px; height: 40px"
                  class="flex items-center justify-between px-2 bg-slate-50 cursor-pointer rounded border-neutral-300 border"
              >
                <div>
                  <div class="text-xs py-0.5 text-blue-950 font-bold">{{ adminName }}</div>
                  <div class="line-clamp-1 text-sm text-stone-600">{{ selectedBranch?.name }}</div>
                </div>
                <div class="ml-1">
                  <IconUsers01 cls="text-xl text-[#0D6992]"/>
                </div>
              </div>
            </template>
            <el-table size="small"
                      ref="branchTableRef"
                      :data="dropdownBranches"
                      @current-change="handleSelectBranch"
                      highlight-current-row
                      row-class-name="cursor-pointer select-none">
              <el-table-column property="name" :label="$t('titles.myBranches')">
                <template #default="scope">
                  <div class="flex items-center gap-2">
                    <IconCheckSquare class="text-xl" v-if="selectedBranch?.mfo === scope.row.mfo"/>
                    <div class="line-clamp-1">{{ scope.row.name }}</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="flex justify-between items-center mt-2">
             <span>
                {{$t("labels.total")}}: <span class="font-bold">{{store.state.userBranches.length}}</span>
             </span>

              <linked-button link route="MyBranchList">{{$t("buttons.viewAll")}}</linked-button>
            </div>


            <el-button
                class="mt-5"
                @click="onClickLogOutBtn"
            >
              <span>{{ $t("buttons.logout") }}</span>
              <IconLogOut cls="text-xl text-[#C05E5E]"/>
            </el-button>
          </el-popover>

        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import {computed, onMounted, ref, toRef, watch} from "vue";
import {useI18n} from "vue-i18n";
import router from "@/router";
import {languages, selectedLocale} from "@/i18n/i18n";
import {ElMessage, ElMessageBox} from "element-plus";
import IconUsers01 from "./icons/users/IconUsers01.vue";
import BankOpenDaysComponent from "@/components/bank-open-days/BankOpenDaysComponent.vue";
import {useStore} from "vuex";
import IconLogOut from "@/components/icons/general/IconLogOut.vue";
import IconCheckSquare from "@/components/icons/general/IconCheckSquare.vue";
import LinkedButton from "@/components/buttons/LinkedButton.vue";

const store = useStore()
const {t} = useI18n();

const branchTableRef = ref()
const loading = ref(false)

const dropdownBranches = computed(() => {
  let value = []
  if (store.state.userBranches?.length > 5){
    value.push(...store.state.userBranches.slice(0, 5))
  } else value = store.state.userBranches
  if (selectedBranch.value){
    const index = value.findIndex(item => item.mfo === selectedBranch.value.mfo)
    if (index === -1)  value.push(selectedBranch.value)
  }

  return value
})

const selectedBranch = computed(() => {
  const bSession = store.state.bSession
  let value = null
  if (bSession) {
    value = {
      mfo: bSession.mfo,
      name: bSession.name.uzLat,
      head: bSession.head
    }
  }

  return value
})

const adminName = computed(() => store.state.user.name);

const handleSelectBranch = (row) => {
  loading.value = true
  store.dispatch('selectBranchSession', row.mfo)
      .then(()=>window.location.reload())
      .finally(()=> loading.value = false)

}

const handleLangChange = () => {
  window.location.replace(window.location.href);
};

const onClickLogOutBtn = () => {
  ElMessageBox.confirm(t("notifications.do_you_want_to_log_out"), t("notifications.caution"), {
    confirmButtonText: t("buttons.yes"),
    cancelButtonText: t("buttons.no"),
    type: "warning",
  })
      .then(() => {
        store.commit("signOut");
        router.push("/auth");
      })
      .catch(() => {
        ElMessage({
          showClose: true,
          message: t("notifications.thanks_for_staying_tuned"),
        });
      });
};

onMounted(() => {
  store.dispatch('getUserBranches')
})
</script>

<style scoped>
.lang-select:deep(.el-input__wrapper) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(242, 243, 245, 0.5);
  border-radius: 8px;
  height: 70%;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}
</style>
