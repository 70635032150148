<script setup>
import {computed, onMounted, ref, watch} from "vue";
import LinkedButton from "@/components/buttons/LinkedButton.vue";

import http from "@/http";
import { useRoute, useRouter } from "vue-router";
import { dateFormat } from "@/composables";

const route = useRoute();
const router = useRouter();


const loading = ref(false)
const notificationsList = ref([])
const totalPages = ref(null)
const totalElements = ref(0)
const page = ref(0)
const size = ref(10)
const categoryType = ref(route.query?.category)
const categoryList = ref([])


const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));
const indexMethod = (index) => {
    if (totalPages.value > 1 && currentPage.value) {
        return index + size.value * (route.query.page - 1) + 1;
    } else {
        return index + 1;
    }
};

watch(currentPage, ()=>{
  getNotifications()
})
const handleCurrentChange = (val) => {
    router.push({
        query: {
            page: val,
            category: categoryType.value,
        },
    });
};

function handleChangeSelect() {
    router.push({
        query: {
            page: currentPage.value,
            category: categoryType.value,
        },
    });
    getNotifications();
}

function getCategories() {
    loading.value = true;
    http.get("notification/category")
        .then(({ data }) => {
            categoryList.value = data;
        })
        .finally(() => {
            loading.value = false;
        });
}

function getNotifications() {
    loading.value = true;
    http.get("notification/admin", {
        params: {
            categoryType: categoryType.value,
            size: size.value,
            page: currentPage.value - 1,
        },
    })
        .then(({ data }) => {
            notificationsList.value = data.content;
            totalPages.value = data.totalPages;
            totalElements.value = data.totalElements;
        })
        .finally(() => {
            loading.value = false;
        });
}

onMounted(() => {
    getCategories();
    getNotifications();
});
</script>

<template>
    <div class="page-header">
        <h3>{{ $t("menu.NotificationsList") }}</h3>
        <div>
            <el-select
                v-model="categoryType"
                :placeholder="$t('labels.select_category')"
                size="large"
                style="width: 240px"
                :clearable="true"
                @change="handleChangeSelect"
            >
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.key"
                />
            </el-select>
            <linked-button size="large" route="NotificationCreate" class="add-btn">
                {{ $t("buttons.add") }}
            </linked-button>
        </div>
    </div>
    <div class="main-data" v-loading="loading">
        <el-table
            class-name="table-custom-cls"
            :data="notificationsList"
            stripe
            style="width: 100%"
            height="calc(100vh - 230px)"
            :empty-text="$t('notifications.information_not_available')"
            table-layout="auto"
            border
            :header-cell-style="{ color: '#111', textAlign: 'center' }"
        >
            <el-table-column type="index" :index="indexMethod" label="№" align="center" />

            <el-table-column
                width="100"
                prop="createdDate"
                :label="$t('labels.date')"
                align="center"
            >
                <template #default="scope">
                    {{ dateFormat(scope.row.createdDate) }}
                </template>
            </el-table-column>

            <el-table-column prop="title" :label="$t('labels.title')" align="center" />

            <el-table-column
                prop="deviceType"
                :label="$t('labels.device_type')"
                width="120"
                align="center"
            />

            <el-table-column prop="description" :label="$t('labels.description')" align="center">
                <template #default="scope">
                    <div
                        :style="{
                            width: '160px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }"
                    >
                        {{ scope.row.description }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                prop="shortDescription"
                :label="$t('labels.short_description')"
                align="center"
            />

            <el-table-column prop="id" :label="$t('labels.actions')" width="90" align="center">
                <template #default="scope">
                    <el-space>
                        <linked-button
                            route="NotificationEdit"
                            size="small"
                            :params="{ id: scope.row.id }"
                            class="btn_icon"
                            type="primary"
                        >
                            {{ $t("buttons.edit") }}
                        </linked-button>
                        <el-button type="danger" size="small" class="btn_icon">
                            {{ $t("buttons.delete") }}
                        </el-button>
                    </el-space>
                </template>
            </el-table-column>
        </el-table>
                <div class="flex justify-between mt-0.5">
                    <span v-if="totalElements">{{ $t("labels.total") }}: {{ totalElements }} </span>
                    <el-pagination
                        :current-page="currentPage"
                        class="pagination"
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next"
                        :total="totalElements"
                        :page-size="size"
                        :page-count="totalPages"
                        :hide-on-single-page="true"
                        :background="true"
                    >
                    </el-pagination>
                </div>
    </div>
</template>

<style scoped>
.page-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.add-btn {
    margin-left: 10px;
}
</style>
