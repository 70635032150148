<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import http from "@/http";
import { ElMessage } from "element-plus";
import IconLoading02 from "@/components/icons/general/IconLoading02.vue";
import IconRefreshCW01 from "@/components/icons/arrows/IconRefreshCW01.vue";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
const loadingRefresh = ref(false);
const currencyData = ref([]);

const totalElements = ref(0);
const totalPages = ref(0);
const size = ref(10);
const page = ref(0);

const currentPage = computed(() => (route.query.page ? parseInt(route.query.page, 10) : 1));

const indexMethod = (index) => {
    if (totalPages.value > 1 && page.value) {
        return index + size.value * (currentPage.value - 1) + 1;
    } else {
        return index + 1;
    }
};

const handleCurrentChange = (value) => {
    router.push({
        query: { page: value },
    });
};

const refreshCurrencyData = () => {
    loadingRefresh.value = true;
    http.get(`appeal/currency-sales-purpose-code/refresh`)
        .then(() => {
          getCurrencySalesPurposeList();
            ElMessage({
                message: t("notifications.successfully_updated"),
                type: "success",
            });
        })
        .finally(() => {
            loadingRefresh.value = false;
        });
};

const getCurrencySalesPurposeList = () => {
    loading.value = true;
    page.value = route.query.page ? route.query.page - 1 : 0;
    http.get("appeal/currency-sales-purpose-code", {
        params: {
            size: size.value,
            page: currentPage.value - 1,
        },
    })
        .then(({ data }) => {
            currencyData.value = data.content;
            totalElements.value = data.totalElements;
            totalPages.value = data.totalPages;
        })
        .catch(({ response: { data } }) => {
            ElMessage({
                message: data.message || t("notifications.error_loading_data"),
                type: "error",
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(
    () => currentPage.value,
    () => {
      getCurrencySalesPurposeList();
    }
);

onMounted(() => {
  getCurrencySalesPurposeList();
});
</script>

<template>
    <div>
        <div class="flex items-center justify-between mb-4">
            <h2 class="text-xl">{{ t("menu.CurrencySalesPurposeCodeList") }}</h2>

            <el-tooltip :content="t('buttons.refresh')" placement="top-start">
                <el-button @click="refreshCurrencyData">
                    <template v-if="loadingRefresh">
                        <el-icon>
                            <IconLoading02 />
                        </el-icon>
                    </template>
                    <template v-else>
                        <el-icon>
                            <IconRefreshCW01 />
                        </el-icon>
                    </template>
                </el-button>
            </el-tooltip>
        </div>

        <div v-loading="loading">
            <el-table
                :data="currencyData"
                :header-cell-style="{ color: '#111', textAlign: 'center' }"
                :empty-text="t('notifications.information_not_available')"
                border
            >
                <el-table-column type="index" :index="indexMethod" label="&#8470;" align="center" />

                <el-table-column prop="name" :label="t('labels.name')" align="center" />

                <el-table-column
                    prop="code"
                    :label="t('labels.code')"
                    width="120"
                    align="center"
                />
            </el-table>

            <div class="flex items-center justify-between mt-2">
                <span v-if="totalElements" class="font-semibold">
                    {{ t("labels.total") }}: {{ totalElements }}
                </span>

                <el-pagination
                    :current-page="currentPage"
                    class="pagination"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="totalElements"
                    :page-size="size"
                    :page-count="totalPages"
                    :hide-on-single-page="true"
                    :background="true"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

.pagination-wrapper > span {
    color: #0a194e;
    font-family: "Montserrat-Bold", sans-serif;
}
</style>
